import React, {useState} from 'react';
import {IClinic} from "../../types/add-clinic";
import {Form, Row, Col,  Button} from "react-bootstrap";
import {Slide} from "@material-ui/core";
import _ from 'lodash';
import {Textbox} from "../shared/Inputcomponents/Textbox";
import {Plain_column} from "../../style-components/Divs";
import {Typeahead} from "react-bootstrap-typeahead";
import {addClinicPage} from "../../statemanager/managehospitals";
import {Label_form} from "../../style-components/Labels";
import {Checkbox} from "../shared/Inputcomponents/Checkbox";

export default function Basicdetails(props: {clinic: IClinic}) {
  const {clinic} = props;
  const [localClinic, setlocalClinic] = useState(_.cloneDeep(clinic) as any);
  const [openddType, setopenddType] = useState(false);
  const [specializationdd, setSpecializationdd] = useState(false);
  const [departmentsDD, setdepartmentsDD] = useState(false);
  const [districtDD, setDistrictDD] = useState(false);
  const {name, gst, phone, emailId, specialization, type, website, location, departments, autogenerateUhid, laborderFromVisit, laborderFromBill} = localClinic;


  const updateForm = (value: any, property: any, parentproperty?: any) => {
    if (parentproperty) {
      localClinic[parentproperty][property] = value;
      setlocalClinic(Object.assign({}, localClinic));
      return;
    }

    localClinic[property] = value;
    setlocalClinic(Object.assign({}, localClinic));
  }


  return <div className="add-clinic-container">


    <Form onSubmit={(e: any) => e.preventDefault()}>
      <>
      <Slide in={true} direction={'down'}>

        <div className={'patient-group'}>
          <h5 className="clinic-info">Register hospital</h5>
          <Form.Row>
            <Col>
              <Textbox
                // width={"320px"}
                label={"Hospital Name"}
                property={"name"}
                placeHolder={"Hospital name"}
                value={name}
                updateCb={updateForm}
                autoFocus={true}
                required={true}
              />
            </Col>
            <Col>
              <Label_form>Center type</Label_form>
              <Typeahead
                defaultInputValue={type.name}
                open={openddType}
                clearButton={true}
                options={addClinicPage.constants.centerType.map((type: any)=> type.name)}
                placeholder={"Center type"}
                onInputChange={(value) => {
                  setopenddType(true);
                }}
                onChange={(value) => {
                  const index = addClinicPage.constants.centerType.findIndex((type: any) => type.name === (value[0] ? value[0] : value));
                  updateForm(addClinicPage.constants.centerType[index], "type");
                  setopenddType(false);
                }}
              />

            </Col>
            <Col>
              <Label_form>Auto Generate UHID ?</Label_form>
              <br/>
              <Checkbox
                key={'auto-gen-uhid'}
                updateCb={(value: string) => {
                  updateForm(!autogenerateUhid, 'autogenerateUhid');
                }}
                option={'Auto generate uhid'}
                checked={autogenerateUhid}
              />

            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Textbox
                // width={"320px"}
                label={"Mobile number"}
                property={"phone"}
                placeHolder={"Phone Number"}
                value={phone}
                updateCb={updateForm}
              />
            </Col>
            <Col>
              <Textbox
                // width={"320px"}
                label={"Email Id"}
                property={"emailId"}
                placeHolder={"Email id"}
                value={emailId}
                updateCb={updateForm}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Label_form>Specialization</Label_form>
              <Typeahead

                defaultInputValue={specialization.name}
                open={specializationdd}
                clearButton={true}
                options={addClinicPage.constants.specializations.map((type: any)=> type.name)}
                placeholder={"Specialization"}
                onInputChange={(value) => {
                  setSpecializationdd(true);
                }}
                onChange={(value) => {
                  const index = addClinicPage.constants.specializations.findIndex((type: any) => type.name === (value[0] ? value[0] : value));
                  updateForm(addClinicPage.constants.specializations[index], "specialization");
                  setSpecializationdd(false);
                }}
              />

            </Col>
            <Col>
              <Label_form>Departments</Label_form>
              <br/>
              {(addClinicPage.constants.departments || []).map((cbItem, index) => {
                const matchIndex =  departments ? departments.findIndex((department: any) => department === cbItem.id.toLowerCase() || department === cbItem.name.toLowerCase()) : -1;
                return <Checkbox
                  key={index}
                  updateCb={(value: any) => {
                      if (matchIndex > -1) {
                        localClinic.departments.splice(matchIndex, 1);
                      } else {
                        if (value.id) {
                          localClinic.departments.push(value.id);
                        } else {
                          localClinic.departments.push(value);
                        }

                      }

                      setlocalClinic(Object.assign({}, localClinic, {departments: [...localClinic.departments]}));
                  }}
                  option={cbItem.id}
                  checked={matchIndex > -1}
                />
              })}

            </Col>

          </Form.Row>
          <Form.Row>
            <Col>
              <Textbox
                // width={"320px"}
                label={"Website"}
                property={"website"}
                placeHolder={"Website"}
                value={website}
                updateCb={updateForm}
              />
            </Col>
            <Col>
              <Textbox
                // width={"320px"}
                label={"GST number"}
                property={"gst"}
                placeHolder={"GST"}
                value={gst}
                updateCb={updateForm}
              />
            </Col>
          </Form.Row>
          <Form.Row>
          <Col>
              <Label_form>Generate lab orders from ?</Label_form>
              <br/>
              <Checkbox
                key={'gen-lab-orders-lab'}
                updateCb={(value: string) => {
                  updateForm(!laborderFromVisit, 'laborderFromVisit');
                }}
                option={'Visits'}
                checked={laborderFromVisit}
              />
               <Checkbox
                key={'gen-lab-orders-billing'}
                updateCb={(value: string) => {
                  updateForm(!laborderFromBill, 'laborderFromBill');
                }}
                option={'Billing (Make sure tariffs are added)'}
                checked={laborderFromBill}
              />

            </Col>
          </Form.Row>
        </div>
      </Slide>
        <br/>
      <Slide in={true} direction={'down'}>
        <div className={'patient-group'}>
          <h5 className="clinic-info">Location</h5>
          <><Form.Row>
                <Col xs={12}>
                  <Textbox
                    // width={"320px"}
                    label={"Address"}
                    property={"address"}
                    placeHolder={"Address"}
                    value={location?.address}
                    updateCb={(value: string, property: string) => {
                      updateForm(value, property, 'location');
                    }}
                  />
                </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Label_form>City</Label_form>
                <Typeahead
                  defaultInputValue={location?.district}
                  open={districtDD}
                  clearButton={true}
                  options={addClinicPage.constants.tnDistricts}
                  placeholder={"City"}
                  onInputChange={(value) => {
                    setDistrictDD(true);
                  }}
                  onChange={(value) => {
                    updateForm(value[0] ? value[0] : value, 'city', 'location');
                    setDistrictDD(false);
                  }}
                />
              </Col>
                <Col>
                  <Textbox
                    // width={"320px"}
                    label={"Country"}
                    property={"country"}
                    placeHolder={"Country"}
                    value={location?.country}
                    updateCb={(value: string, property: string) => {
                      updateForm(value, property, 'location');
                    }}
                  />
                </Col>
              <Col>
                  <Textbox
                    // width={"320px"}
                    label={"State"}
                    property={"state"}
                    placeHolder={"State"}
                    value={location?.state}
                    updateCb={(value: string, property: string) => {
                      updateForm(value, property, 'location');
                    }}
                  />
              </Col>
              </Form.Row>
            <Form.Row>
              <Col xs={4}>
                <Textbox
                  // width={"320px"}
                  label={"Pin code"}
                  property={"areaCode"}
                  placeHolder={"Pin code"}
                  value={location?.areaCode}
                  updateCb={(value: string, property: string) => {
                      updateForm(value, property, 'location');
                  }}
                />
              </Col>
            </Form.Row>
            </>


        </div>
      </Slide>
      <Form.Row>
        <Col>
          <Button
            style={{ color: "#000" }}
            variant="outline-dark"
            onClick={() => {
              addClinicPage.reducerFn(addClinicPage.constants.events.GO_BACK_TO_LIST);
            }}
          >
            Cancel
          </Button>
        </Col>

        <Col>
          <Button
            variant="dark"
            onClick={() => {

              addClinicPage.reducerFn(addClinicPage.constants.events.ADD_NEW_CLINIC, {clinic: localClinic});
            }
            }
          >
            {clinic.medicalCenterId ? 'Update' : 'Create'} clinic
          </Button>
        </Col>
      </Form.Row>
      </>

    </Form>
  </div>
}
