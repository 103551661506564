import * as React from "react";
import { useEffect, useState } from "react";
import { stateManagers } from "../statemanager";
import { withRouter } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  Navbar,
  Jumbotron,
} from "react-bootstrap";

import "../css/addclinic.scss";
import {Save_btn_md} from "../style-components/StyledButtons";
import Listhospitals from "../components/Hospitals/Listhospitals";
import Addhospital from "../components/Hospitals/Addhospital";
import {hospitalBasicDetails} from "../constants/add-clinic";

export const Managehospitals: React.FunctionComponent = (
  { match, location }: any,
  props
) => {
  const { addClinicPage } = stateManagers;

  const {
    constants,
    reducerFn,
    getStateObservable,
    updateState,
  } = addClinicPage;
  const { events, errors } = constants;
  const [state, setState] = useState(addClinicPage.initialState as any);
  const [pagePassword, setPagepassword] = useState('');

  const {authenticated, listOfHospitals, loading, clinic, addclinic} = state;


  useEffect(() => {
    reducerFn(events.GET_ALL_HOSPITALS);
    const subscription = getStateObservable().subscribe((updatedState) => {
      setState(updatedState);
    });

    return () => subscription.unsubscribe();
  }, [getStateObservable, match, updateState]);


  const loaderClass = loading ? 'app-loading' : 'placeholder-class'

  if (!authenticated) {
    return  <div className={
      `container ${loaderClass}`
    } style={{marginTop: '40vh', textAlign: 'center'}}><div className={'row'}>
              <div className={'col-12'}>
                <Form.Control type={'password'} placeholder={'Enter password to access the page'} autoFocus={true} style={{width: '100%'}} value={pagePassword} onChange={(e: any)=> setPagepassword(e.target.value) }/>
              </div>
              <br/>
              <br/>
              <div className={'col'}>
                  <Save_btn_md style={{color: '#FFF' }} onClick={() => reducerFn(events.VERIFY_PASSWORD, {password: pagePassword})}>
                    {loading ? <><span className="spinner-border spinner-border-sm"></span>Authenticating</> : 'Authenticate'}
                  </Save_btn_md>
              </div>
              </div>
            </div>
  }

  if (addclinic) {
    return <div className={loaderClass}><Addhospital clinic={clinic}/></div>
  }


  return (
    <div className={'container'}>
      <div style={{marginTop: '50px'}}>
        <Button onClick={()=> updateState(Object.assign({}, state, {addclinic: true}))}>Add hospital</Button>
      </div>
      <div style={{marginTop: '50px'}}>
        <Listhospitals hospitalList={listOfHospitals} goToHospital={(clinic: any) => updateState(Object.assign({}, state, {addclinic: true, clinic})) }/>
      </div>

    </div>
  );
};

export default withRouter(Managehospitals);
