import * as React from "react";
import {useEffect, useState} from "react";
import { Col, Form, Row } from "react-bootstrap";
import addVisit from "../../../images/patient/visit-add-icon.svg";
import {Cancel_btn_sm, Save_btn_sm} from "../../../style-components/StyledButtons";
import '../../../css/patients/visits.scss';
import '../../../css/patients/visits/prescriptions-edit.scss';
import {Textbox} from "../../shared/Inputcomponents/Textbox";
import {API} from "../../../utils/Api";
import {SpinnerLoader} from "../../shared/SpinnerLoader";
import {Auth} from "aws-amplify";
import ReactDOM from 'react-dom';
import {Typeahead} from "react-bootstrap-typeahead";

export const Managepharmacy = (props: any) => {
  const { clinic } = props;
  const [loading, setLoading] = useState(true);
  const [pharmacy, setPharmacy] = useState(null as any);
//   const [permissionDD, setPermission] = useState(false);
//   const permissions = ['admin', 'registration', 'lab-admin', 'radiology-admin', 'nurse', 'duty-doctor','pharma-admin', 'pharma-staff'];


  useEffect(()=>{
    (async () => {
        const response = await API.get(`/pharmacy?medicalCenterId=${clinic.medicalCenterId}`);
        if (!response.data.data.length) {
            const res = window.confirm('Looks like not pharmacy is attached to the medical center, you want to add ?');
            if (res) {
                const res = window.confirm('Are you sure, Make sure hospital is correct');
                if (res) {
                    try {
                        const addPharmacy =  await API.post(`pharmacy`, {medicalCenterId: clinic.medicalCenterId});
                        alert('Pharmacy Added');
                    } catch(e) {
                        alert('Error occured');
                    }
                
                }
                setLoading(false);
            }
      } else {
        setPharmacy(response.data.data[0]);
        setLoading(false);
      }
    })();
  }, [])

  if (loading) {
    return <SpinnerLoader/>
  }

  return (
    <div style={{background: '#FFF', padding: '10px'}}>
      <Row className={"prescription-edit-header text-center-align"} >
        <Col>Pharmacy ID</Col>
        <Col>Pharmacy Code</Col>
      
      </Row>
      <Row className={"prescription-edit-header text-center-align"} >
        <Col>{pharmacy.id}</Col>
        <Col>{pharmacy.pharmacyCode}</Col>
      </Row>
     
      <br/>
      {/* <Row>
        <Col className={"visits-record-footer"}>
          &nbsp;
          <Cancel_btn_sm
            className={
              "btn btn-outline-dark btn-sm"

            }
            onClick={() => console.log('Cancel')}
          >
            {" "}
            Cancel{" "}
          </Cancel_btn_sm>
          <Save_btn_sm
            className={
              "btn btn-dark btn-sm"
            }
            onClick={() =>
              console.log()
              // saveTariffs()
              //  addClinicPage.reducerFn(addClinicPage.constants.events.UPDATE_CLINIC, {
              //    clinic: Object.assign({}, clinic, {tariffs: [...tariffs]})}
              //    )
            }
          >
            Save
          </Save_btn_sm>
        </Col>
      </Row> */}
    </div>
  );
}

export default Managepharmacy;
