export const Login = {
  context: "login",
  labels: {
    title: "Login",
    subtitle: "Enter your user name and password",
  },
  forgotPasswordLabels: {
    title: "Forgot Password",
    subtitle: "Enter your user name get verification code to set new password",
  },
  events: {
    GO_TO_LOGIN: "go_to_login",
    LOGIN_USER: "login_user",
    NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
    SET_PASSWORD: "set_password",
    PASSWORD_VERIFIER: "PASSWORD_VERIFIER",
    SHOW_FORGOT_PASSWORD_BUTTON: "show_forgot_password_btn",
    RESET_PASSWORD: "reset_password",
    RESEND_OTP: "resend_otp",
    VERIFY_OTP: "verify_otp",
  },
  errors: {
    INVALID_USER_NAME_PASSWORD: {
      display: "Invalid user name or password",
      error: "UsernameExistsException",
    },
    INVALID_USER: {
      display: "User Name is not valid",
      error: "UserNotFoundException",
    },
    PASSWORD_NOT_MATCHING: {
      display: "Password and Confirm password do not match",
      error: "Passwords do not match",
    },
    PASSWORD_INVALID: {
      display: "Password should be at-least 6 characters long",
      error: "InvalidPasswordLength",
    },
    UNABLE_UPDATE_PASSWORD: {
      display: "Unable to set password try again!",
      error: "UnableToSetPassword",
    },
    FORCE_RESET_PASSWORD: {
      display: '',
      error: 'PasswordResetRequiredException'
    },
    INVALID_OTP: { display: "Invalid otp!", error: "Enter valid otp" },
  },
};
