import styled from "styled-components";

export const Main_menu = styled.div`
  top: 0;
  height: 40px;
  width: 100%;
  background: #20253a;
  color: #cadee6;
  font-size: 18px;
  position: fixed;
  z-index: 1001;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Bewell_logo = styled.div`
  width: 120px;
  color: #cadee6;
  //float: left;
  height: 100%;
  font-size: 18px;
  margin-left: 40px;
  font-weight: 600;
`;

export const Menu_items = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  //width: 70%;
  //float: left;
  //column-gap: 5%;
  height: 100%;
`;


export const Menu_item = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-left: 25px;
  //gap: 10px;
  color: #91919c;
  font-size: 16px;
  font-weight: normal;
`;

export const VERSION_DISPLAY = styled.span`
  font-size: 10px;
`;

export const LOGOUT_CONTAINER = styled.div`
  //width: 30px
`;

export const LOGOUT_BUTTON = styled.span`
  font-size: 12px;
  color: #CCC;
  text-decoration: underline;
  margin-right: 10px;
`;
