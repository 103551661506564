import styled from "styled-components";

export const Save_btn_sm = styled.button`
  width: ${(props: { width?: string }) =>
    props.width ? props.width : "150px"};
  height: 30px;
  font-weight: bold;
`;

export const Cancel_btn_sm = styled.button`
  width: ${(props: { width?: string }) =>
    props.width ? props.width : "150px"};
  color: "#000";
  height: 30px;
  font-weight: 500;
`;

export const Add_btn_sm = styled.button`
  width: ${(props: { width?: string }) =>
    props.width ? props.width : "150px"};
  background: inherit;
  border: 1px solid #282d30;
  color: #282d30;
  font-size: 14px;
  box-shadow: 4px 0px 4px #2b2d3703 !important;
  height: 35px;
  font-weight: 500;
`;

export const Save_btn_md = styled.button`
  width: ${(props: { width?: string }) =>
    props.width ? props.width : "150px"};
  height: 40px;
  font-weight: 500;
  border-radius: 5px;
  font-size: 14px;
  background: #20253a;
`;

export const Cancel_btn_md = styled.button`
  width: ${(props: { width?: string }) =>
    props.width ? props.width : "150px;"};
  color: #000;
  height: 40px;
  font-weight: 500;
  border-radius: 5px;
  font-size: 14px;
`;
