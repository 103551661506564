import * as React from "react";
import { Button, Form, Container } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import "../css/patients.scss";
import { stateManagers } from "../statemanager";
import { Mainmenu } from "../components/Mainmenu";

export const Settings: React.FunctionComponent = ({ match }: any, props) => {
  const { params } = match;
  const centerId = params.id;
  const { PatientPage } = stateManagers;
  const { constants } = PatientPage;
  const [state, setState] = useState(PatientPage.initialState as any);
  const { loading } = state;

  const loaderClass = loading ? "app-loading" : "placeholder-class";

  return (
    <Container
      fluid
      style={{ padding: "0px" }}
      className={`container-style ${loaderClass}`}
    >
      <Mainmenu menuItem={"settings"} centerId={centerId} menuIndex={1} />
      <div className="d-flex" id="wrapper">
        <div className="border-right" id="sidebar-wrapper">
          <div className="sidebar-heading">
            <div className="width-50 fl">Settings</div>
          </div>
          <div className="list-group list-group-flush">
            <div className="row"></div>
            <div className="row left-panel-home"></div>
          </div>
        </div>

        <div id="page-content-wrapper">
          <div className="container-fluid"></div>
        </div>
      </div>
    </Container>
  );
};

export default Settings;
