import * as React from "react";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import { useRef, useState } from "react";
import { API } from "../../../utils/Api";

const Inputsearchbox = (props: {
  placeholder: string;
  slug: string /*Make sure / is added before slug*/;
  updateCb: Function;
  menuId: any;
  useCache?: boolean;
  minLength?: number;
  paginate?: boolean;
  autoFocus?: boolean;
  clearInput?: boolean;
  selectedValue?: string;
  searchUrl?: string;
}) => {
  const {
    placeholder,
    slug,
    minLength = 3,
    menuId,
    useCache = true,
    paginate = true,
    updateCb,
    autoFocus = false,
    clearInput = true,
    selectedValue = "",
    searchUrl = "",
  } = props;

  const PER_PAGE = 50;
  const [isLoading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const ref = useRef(null as any);
  const [open, setOpen] = useState(false);

  const clearInputField = () => {
    setOpen(false);
    if (clearInput) {
      ref.current.clear();
    }
  };

  return (
    <div>
      <AsyncTypeahead
        
        open={open}
        defaultInputValue={selectedValue}
        id={menuId}
        delay={500}
        ref={ref}
        autoFocus={autoFocus}
        menuId={menuId}
        maxResults={PER_PAGE - 1}
        minLength={minLength - 1}
        paginate={paginate}
        placeholder={placeholder}
        clearButton={clearInput}
        allowNew={true}
        newSelectionPrefix={'Enter to add:  '}
        onBlur={(e:any)=> {
          setOpen(false);
          updateCb(e.target.value);
        }}
        renderMenuItemChildren={(option: any, props: any, index: any) => (
          <div
            key={index}
            onClick={() => {

              // Why timeout ? because there is a delay from selecting the text and it populating the text box
              setTimeout(() => clearInputField(), 20);
              updateCb(option);
            }}
          >
            <Highlighter search={props.text}>{option}</Highlighter>
          </div>
        )}
        useCache={useCache}
        onInputChange={(value) => {
          if (!value && !clearInput) {
            setOpen(false);
            updateCb(value);
          }
        }}
        onKeyDown={(e: any) => {
          setOpen(true);
          const value = e.target.value;
          if (
            e.keyCode === 13 &&
            updateCb
            //  && e.target.value.length >= minLength
          ) {
            clearInputField();
            updateCb(value);
          }
        }}

        multiple={false}
        isLoading={isLoading}
        ignoreDiacritics={true}
        onSearch={(query) => {
          setLoading(true);
          API.get( searchUrl ? `${searchUrl}/${query}` : `/autoSuggest/search${slug}/${query}`)
            .then((response: any) => {
              const results = response.data.Items.map((suggestion: any) =>
                suggestion.suggeestionValue
                  ? suggestion.suggeestionValue
                  : suggestion.suggestionValue
              );
              setSuggestions(results);
              setLoading(false);
            })
            .catch(() => {
              // Have to add a toaster message here to show error has occured
              setLoading(false);
            });
        }}
        options={suggestions}
      />
    </div>
  );
};

export default Inputsearchbox;
