import { BehaviorSubject } from "rxjs";
import { constants } from "../constants";
import { ILoginPageState } from "../types/login";
import { Handleauth } from "../utils/Handleauth";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
const { login_page } = constants;

export const initialState: ILoginPageState = {
  loading: false,
  phone: "",
  password: "",
  newPassword: false,
  showForgotBtn: false,
  authResponse: {},
  showForgotInput: false,
  getToken: false,
};

let attempts = 0;

const subject$ = new BehaviorSubject(initialState);

const hasMobilePrefix = (mobile: string) => {
  if (isNaN(Number(mobile))) {
    return mobile;
  }

  const prefix = mobile.split(mobile.slice(-10))[0];
  if (prefix!== '+91') {
    if (prefix==='91') {
      return '+'+mobile;
    }
    return '+91'+mobile;
  }

  if (mobile.charAt(0)!=='+') {
    return '+'+mobile;
  }

  return mobile;

}

const getStateObservable = () => {
  return subject$;
};

const updateState = (updateObj: any) => {
  subject$.next(updateObj);
};

const reducerFn = async (
  updateEvent: string,
  params?: { [key: string]: string }
) => {
  const state = subject$.getValue();
  const { phone, password, authResponse } = state;
  const { events, errors } = login_page;
  const uid = uuidv4().toString();
  const uid1 = uid.split('-')[0]
  const uid2 = uid.split('-')[1];

  // POSSIBLE USER EVENTS
  const {
    SHOW_FORGOT_PASSWORD_BUTTON,
    RESEND_OTP,
    LOGIN_USER,
    GO_TO_LOGIN,
    RESET_PASSWORD,
    SET_PASSWORD,
    NEW_PASSWORD_REQUIRED,
    PASSWORD_VERIFIER,
    VERIFY_OTP,
  } = events;

  // POSSIBLE ERROR STATES
  const {
    INVALID_USER_NAME_PASSWORD,
    UNABLE_UPDATE_PASSWORD,
    INVALID_USER,
    INVALID_OTP,
    FORCE_RESET_PASSWORD
  } = errors;

  updateState({ ...state, ...{ loading: true, userErrorState: null } });

  switch (updateEvent) {
    case VERIFY_OTP: {
      try {
        const request = await Handleauth.updatePassword(
          hasMobilePrefix(phone),
          params!.otp,
          params!.password
        );

        toast.success("Password updated successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });

        setTimeout(() => {
          updateState({
            ...state,
            ...{
              loading: false,
              userErrorState: null,
              showForgotInput: false,
              getToken: false,
            },
          });
        });
      } catch (e) {
        if (e.code === "CodeMismatchException") {
          updateState({
            ...state,
            ...{ loading: false, userErrorState: INVALID_OTP },
          });
        } else {
          updateState({
            ...state,
            ...{
              loading: false,
              userErrorState: UNABLE_UPDATE_PASSWORD,
              showForgotInput: false,
              getToken: true,
            },
          });
        }
      }

      break;
    }
    case RESET_PASSWORD:
    case RESEND_OTP: {
      try {
        const request = await Handleauth.forgotPassword(hasMobilePrefix(phone));
        updateState({
          ...state,
          ...{
            phone: hasMobilePrefix(phone),
            loading: false,
            userErrorState: null,
            showForgotInput: false,
            getToken: true,
          },
        });
      } catch (e) {
        console.log(e);
        updateState({
          ...state,
          ...{
            loading: false,
            userErrorState: INVALID_USER,
          },
        });
      }
      break;
    }
    case SHOW_FORGOT_PASSWORD_BUTTON: {
      updateState({
        ...state,
        ...{ loading: false, userErrorState: null, showForgotInput: true },
      });
      break;
    }
    case GO_TO_LOGIN: {
      updateState({
        ...state,
        ...{
          loading: false,
          password: "",
          newPassword: false,
          showForgotBtn: false,
          showForgotInput: false,
          getToken: false,
        },
      });
      break;
    }
    case SET_PASSWORD: {
      const resetState = () => {
        updateState({
          ...state,
          ...{
            loading: false,
            password: "",
            userErrorState: UNABLE_UPDATE_PASSWORD,
            showForgotBtn: attempts > 0,
          },
        });
      };
      try {
        authResponse.completeNewPasswordChallenge(
          params!.password,
          {
            // name:  'hospital-'+uuidv4().substr(1, 8)
          },
          {
            onSuccess: (session: CognitoUserSession) => {
              toast.success('Password updated');
              setTimeout(() => {
                updateState({
                  ...state,
                  ...{
                    loading: false,
                    userErrorState: null,
                    newPassword: false,
                    showForgotInput: false,
                    getToken: false,
                  },
                });
              });
            },
            onFailure: (err: any) => {
              resetState();
            },

          },
        );
        // OPTIONAL, the required attributes
      } catch (e) {
        resetState();
      }
      break;
    }
    case LOGIN_USER: {
      try {
        attempts += 1;
        const authResponse: any = await Handleauth.login(hasMobilePrefix(phone), password);
        console.log(authResponse);

        if (
          authResponse.challengeName === NEW_PASSWORD_REQUIRED ||
          authResponse.challengeName === PASSWORD_VERIFIER

        ) {
          updateState({
            ...state,
            ...{
              authResponse,
              loading: false,
              password: "",
              newPassword: true,
              userErrorState: {},
            },
          });
          return;
        }

        const {attributes} = authResponse;

        if (attributes['custom:onlyPharmacy'] === 'true') {
          window.location.href = "/pharmacy/"+(attributes['custom:centerId'] || attributes.name);
        } else  if (!attributes['custom:onlyPharmacy']){
          window.location.href = "/patients/"+ (attributes['custom:centerId'] ? attributes['custom:centerId'] :attributes.name);
        }

       
      } catch (e) {
        console.log(e);
        if (e['__type'] && e['__type'] === FORCE_RESET_PASSWORD.error) {
          alert();
        }
        updateState({
          ...state,
          ...{
            loading: false,
            password: "",
            userErrorState: INVALID_USER_NAME_PASSWORD,
            showForgotBtn: attempts > 0,
          },
        });
        break;
      }
    }
  }
};

export const loginPage = {
  constants: login_page,
  initialState,
  updateState,
  getStateObservable,
  reducerFn,
};
