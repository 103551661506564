import React, { useState } from "react";
import "../../App.scss";
import { useToasts } from "react-toast-notifications";
import { Button, Form } from "react-bootstrap";
import { Label_form } from "../../style-components/Labels";
import { Showtoastermsg } from "../../utils/Showtoastermsg";

const style = {
  fontSize: "13px",
  color: "#5F5F5F",
  height: "32px",
  borderRadius: "4px",
  border: "1px solid #AAAAAA",
};

export const Setnewpassword = (props: any) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { addToast } = useToasts();

  const { parentState, eventHandler, errorStates, userEvents, otp } = props;
  const { phone, userErrorState, getToken } = parentState;
  const {
    PASSWORD_NOT_MATCHING,
    PASSWORD_INVALID,
    INVALID_OTP,
    UNABLE_UPDATE_PASSWORD,
  } = errorStates;

  const loaderClass = parentState.loading ? "app-loading" : "placeholder-class";
  return (
    <div className={loaderClass}>
      {!getToken ? (
        <>
          <p>Set New password</p>
          <small>
            Looks like you are logging in for the first time, Set a new password
          </small>{" "}
          <br />
        </>
      ) : null}

      <br />
      <form onSubmit={(e: any) => e.preventDefault()}>
        <Label_form>{getToken ? "New password" : " Password "}</Label_form>
        <Form.Control
          style={{
            fontSize: "13px",
            color: "#5F5F5F",
            height: "32px",
            borderRadius: "4px",
            border: "1px solid #AAAAAA",
          }}
          autoFocus={!getToken}
          // className={invalid ? "invalid-text-box" : ""}
          type="password"
          placeholder={"Enter Password"}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <br />

        <Label_form>
          {getToken ? "Confirm New Password" : "Confirm Password "}
        </Label_form>
        <Form.Control
          style={style}
          type="password"
          placeholder={"Enter Password"}
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
        />
      </form>
      {userErrorState ? (
        <div className="error-info">{userErrorState.display}</div>
      ) : (
        <div></div>
      )}
      <br />

      <div className="btn-container">
        <Button
          variant="dark"
          onClick={() => {
            if (getToken && isNaN(otp)) {
              addToast(Showtoastermsg("Error", INVALID_OTP.display), {
                appearance: "error",
                autoDismiss: true,
              });
              return;
            }

            if (password !== confirmPassword) {
              addToast(Showtoastermsg("Error", PASSWORD_NOT_MATCHING.display), {
                appearance: "error",
                autoDismiss: true,
              });
              return;
            }

            if (password.length < 6) {
              addToast(Showtoastermsg("Error", PASSWORD_INVALID.display), {
                appearance: "error",
                autoDismiss: true,
              });
              return;
            }

            eventHandler(
              getToken ? userEvents.VERIFY_OTP : userEvents.SET_PASSWORD,
              { password, otp }
            );
          }}
        >
          {" "}
          {parentState.loading ? (
            <span className="spinner-border spinner-border-sm"></span>
          ) : (
            "Set Password"
          )}
        </Button>
      </div>
      {!getToken ? (
        <div className="link-button-container">
          <Button
            variant="link"
            onClick={() => {
              eventHandler(userEvents.GO_TO_LOGIN);
            }}
          >
            Login
          </Button>
        </div>
      ) : null}
    </div>
  );
};
