import { constants } from "../../constants";
import { IReducer } from "../../types/patients";

const { manage_visits } = constants;

export const Vitals = async (args: IReducer) => {
  const { state, selectedPatient, params, event, updateState } = args;
  const record_constants = manage_visits.ManageRecords;
  const { events, recordsDefaults }: any = record_constants;
  const {
    VITALS: { ADD_VITALS, UPDATE_VITALS },
  } = events;

  switch (event) {
    case ADD_VITALS: {
      // @ts-ignore
      selectedPatient.visits[params!.index].records.push(
        Object.assign({}, recordsDefaults[ADD_VITALS])
      );
      break;
    }
    case UPDATE_VITALS: {
      console.log(params);
      const record =
        selectedPatient.visits[state.visitIndex].records[params!.index];
      // const { weight, bp, pulse, temperature, respRate, notes, saturation, bloodSugar, tempScale, height, painAssessment, heartRate } = params!.vitals;
      selectedPatient.visits[state.visitIndex].records.splice(
        params!.index,
        1,
        Object.assign(
          {},
          record,
          { mode: "PREVIEW" },
          params!.vitals
        )
      );
    }
  }

  updateState(Object.assign({}, state, { selectedPatient }));
};
