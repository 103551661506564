import { Fade } from "@material-ui/core";
import * as React from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { Label_md, Label_form } from "../../style-components/Labels";
import { Cancel_btn_sm, Save_btn_sm} from "../../style-components/StyledButtons";
import PaitentCard from "../patients/Leftpanel/PatientCard";
import { Textbox } from "../shared/Inputcomponents/Textbox";
import { v4 as uuidv4 } from 'uuid';


export const Listpatients = (props: any) => {
    const {patients, searchKey, goToBilling, createPatient} = props;
    const setName = searchKey && isNaN(Number(searchKey)) ? searchKey : '';
    const [name, setPatientName] = React.useState(setName);
    const [phone, setPhone] = React.useState('');

    if (!patients.length) {
        return <div className='no-patient-found'>
            <div>
                <Label_form>No results found for this search.</Label_form>  
                <br/>
                <Label_md>Would you like to create new patient named "{searchKey}"?</Label_md>
                <br/>
                <div className='text-box-container'>
                    <Textbox
                        width={'320px'}
                        label={"Patient Name"}
                        property={"name"}
                        placeHolder={"Patient Name"}
                        value={name}
                        updateCb={(value: string, property: string) => {
                            setPatientName(value);
                        }}
                        autoFocus={true}
                        required={true}
                    />
                <br/>
                <Textbox
                        width={'320px'}
                        label={"Phone Number"}
                        property={"phone"}
                        placeHolder={"Phone Number"}
                        value={phone}
                        updateCb={(value: string, property: string) => {
                            setPhone(value);
                        }}
                    />
                <br/>
                    <div>
                        <Cancel_btn_sm
                            className={
                            "btn btn-outline-dark btn-sm"

                            }
                            onClick={() => console.log('Cancel')}
                        >
                            {" "}
                            Cancel{" "}
                        </Cancel_btn_sm>
                        &nbsp;
                        <Save_btn_sm
                            className={
                            "btn btn-dark btn-sm"
                            }
                            onClick={() => {
                              if (!name) {
                                toast.error('Add valid name');
                                return;
                              }

                              if (!phone) {
                                toast.error('Enter valid phone number');
                                return;
                              }

                              const patientId =  uuidv4();
                              createPatient(patientId, {fullName: name, phone, patientId})

                            }}
                        >
                        Save
                        </Save_btn_sm>
                    </div>
                </div>

                 
            </div>
        </div>
    }
    return  <Fade in={true}>
      <div className='list-patients'>
      {
        patients.map((patient: any, index: number) => {
        return <>
            <PaitentCard
            customClass={'side-bar-card-custom'}
            patient={patient}
            leftPanelLoading={false}
            key={index}
            updatePatientIndex={(patientId: any) => {
                goToBilling(patientId);
            }}
            index={index}
            />
        </>
        })}

      </div>
    </Fade>

}