// @flow
import * as React from "react";
import { Card, ProgressBar } from "react-bootstrap";
import bloodGroupSvg from "../../../images/patient/002-blood-donation.svg";
import genderSvg from "../../../images/patient/004-masculine.svg";
import genderFemaleSvg from "../../../images/patient/002-femenine.svg";
import yearImage from "../../../images/patient/003-infographic.svg";
import phoneImage from "../../../images/patient/001-phone.svg";
import checkImage from "../../../images/patient/001-check.svg";
import warningImage from "../../../images/patient/001-warning.svg";
import moment from "moment";
import {SpinnerLoader} from "../../shared/SpinnerLoader";
import {Label_form} from "../../../style-components/Labels";
import {Slide} from "@material-ui/core";


export const PaitentCard = (props: any) => {
  const { patient, updatePatientIndex, customClass='' } = props;
  let mainClass = "side-bar-card";
  if (props.active) {
    mainClass += " active-card";
  }

  function getAge(dob: any) {
    try {
      dob = moment(dob, "DD/MM/YYYY");
      dob = new Date(dob);
      const diff_ms = Date.now() - dob.getTime();
      const age_dt = new Date(diff_ms);

      return Math.abs(age_dt.getUTCFullYear() - 1970);
    } catch (e) {
      return 30;
    }
  }

  let age: any = getAge(new Date(patient.dob));
  if (patient.age && (patient.age.years || patient.age.months)) {
    age = `${patient.age.years ? patient.age.years + 'Y' : ''}  ${patient.age.months && patient.age.months <= 11   ?+' '+patient.age.months + 'M' : ''}`;
  } else {
    age = isNaN(age) ? '-': age+'Y'
  }



  return (
    <Slide direction={'down'} in={true}>
    <Card
      className={mainClass+' '+customClass}
      onClick={() => {
        updatePatientIndex(patient.patientId);
        // setSelectedpatient(patient);
      }}
    >

       <Card.Body className={"card-body-class "}>
         <div className={'row'}>
           <div className="sidebar-name">
             <img
               alt=""
               className="avatar"
               src="https://www.w3schools.com/howto/img_avatar.png"
             />
             {patient.progress === 100 ? (
               <img alt="" src={checkImage} className="progress-image" />
             ) : (
               <img alt="" src={warningImage} className="progress-image" />
             )}
             <span className="patient-name">{patient.fullName} </span>
             <br/> <div style={{textAlign: 'left', marginLeft: '50px', marginTop: '-10px'}}><Label_form>{patient.uhid ? 'UHID : '+patient.uhid : "-"}</Label_form></div>
           </div>
         </div>
        <div className="row">
          <div className="col-4 svg-display">
            <img alt="" src={yearImage} />{" "}
            <span>{age ? age : '-'}</span>
          </div>
          <div className="col-4 svg-display">
            <img alt="" src={bloodGroupSvg} />{" "}
            <span>{patient.bloodGroup ? patient.bloodGroup : "-"}</span>
          </div>
          <div className="col-4 svg-display">
            {patient.gender === "male" ? (
              <>
                {" "}
                <img alt="" src={genderSvg} /> <span>{patient.gender}</span>{" "}
              </>
            ) : (
              <>
                {" "}
                <img alt="" src={genderFemaleSvg} />{" "}
                <span>{patient.gender}</span>{" "}
              </>
            )}
          </div>
        </div>
        {patient.phone ? (
          <div className="row" style={{ marginTop: "10px" }}>
            <div className="col-12 svg-display">
              <img alt="" src={phoneImage} /> &nbsp;&nbsp;{" "}
              <span style={{ color: "#737373", fontSize: "13px" }}>
                {patient.phone}
              </span>
            </div>
          </div>
        ) : null}

        {patient.progress < 100 ? (
          <ProgressBar variant="warning" now={patient.progress} />
        ) : null}
      </Card.Body>
    </Card>
    </Slide>
  );
};

export default PaitentCard;
