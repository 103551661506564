import * as React from "react";
import uniqVisitorsImage from "../../../../images/reports/visitors.svg";
import { Label_form, Label_md } from "../../../../style-components/Labels";
import ReportsTable from "../../../shared/Reportstable";
import { API } from "../../../../utils/Api";
import _ from 'lodash';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from "moment";
import { Badge } from "react-bootstrap";


const addComma = (data: any) => {
  try {
    return (data.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,"))
  } catch(e) {
    return data;
  }

}

export const BillingTotalCollection = (props: any) => {
  const {autoRefresh, patients, textFilters, selectedFilter, centerId, startDate, endDate} = props;
  const [loading, setLoading] = React.useState(true);
  const [reports, setReport] = React.useState([] as any);
  const [billCount, setCount] = React.useState({
    cash: 0,
    card: 0,
    cheque: 0,
    rtgs: 0,
    totalDiscount: 0,
    totalDue: 0,
    totalAdvance: 0,
    totalAmount: 0,
  } as any);
  const skeletonRows = new Array(10).fill(0);

  const columns: any = [{
    dataField: 'id',
    text: 'UHID',
    sort: true
  }, {
    dataField: 'name',
    text: 'Name',
    sort: true
  }, 
  {
    dataField: 'billDate',
    text: 'Bill Date',
    sort: true
  },
  {
    dataField: 'billId',
    text: 'Bill ID',
    sort: true
  },
  {
    dataField: 'billAmount',
    text: 'Amount',
    sort: true
  },
  {
    dataField: 'paid',
    text: 'Paid',
    sort: true
  },
  {
    dataField: 'cash',
    text: 'Cash',
    sort: true
  },
  {
    dataField: 'card',
    text: 'Card',
    sort: true
  },
  {
    dataField: 'credit',
    text: 'Credit',
    sort: true
  },
  {
    dataField: 'others',
    text: 'Others',
    sort: true
  },
];


  React.useEffect(()=>{
    let amounts = {
      cash: 0,
      card: 0,
      cheque: 0,
      credit: 0,
      rtgs: 0,
      totalDiscount: 0,
      totalDue: 0,
      totalAdvance: 0,
      totalAmount: 0,
      totalPaid: 0,
    };

    setLoading(true);
    setCount({...amounts});
    (async ()=>{
      try {
        const results: any = []; 
        const patiensList = [];
        let uniqueCount = 0;
        const patientData: any = [];
        
        // if (patients.length) {
          const response = await API.get(`/bills/filter/${centerId}/${startDate.toISOString()}/${endDate.toISOString()}`);
          const YesText = <b>Yes</b>;
          const patientIds =  _.map(response.data.Items, 'patientId');
          await Promise.all(patientIds.map(async (pid: any, index: number) => {
            if (pid) {
              const patient = await API.get(`/patients/patient/${centerId}/${pid}`);
              patientData.push(patient.data);
            }
          }));

          console.log(response.data.Items);

          response.data.Items.forEach((bill: any, index: number) => {
            const patient = patientData.find((patient: any) => patient.patientId === bill.patientId);
            if (patient) {
              bill.invoices.forEach((invoice: any, index: any) => {
                let totalAmount: any = 0;
                let cost = 0, discountedCost = 0, tax = 0,  totalCost= 0, paid = 0;
                let method = invoice.method.toLowerCase();
                paid =  Number(invoice.totalPaid);
                if (method === 'cash') {
                  amounts.cash += paid;
                } else if (method === 'card') {
                  amounts.card += paid;
                } else if (method === 'credit') {
                  amounts.credit += paid;
                } else if (method === 'cheque') {
                  amounts.cheque += paid;
                }  else if (method === 'rtgs') {
                  amounts.rtgs += paid;
                }
                invoice.items.forEach((item: any) => {
                  cost = item.cost * item.unit;
                  discountedCost = Number(item.discount);
                  tax = (item.tax * (cost-discountedCost)) / 100;
                  totalCost = (((cost-discountedCost) + tax));
                  totalAmount += totalCost;
                });
                amounts.totalAmount += totalAmount;
                amounts.totalDiscount += discountedCost;
                amounts.totalPaid += paid;
                results.push({
                  id: patient.uhid,
                  name: patient.fullName,
                  billDate: moment(bill.creation_date).format('DD-MM-YYYY'),
                  billId: bill.billId,
                  billAmount: ` ${(totalAmount).toFixed(2)}`,
                  paid: invoice.totalPaid ? ` ${Number(invoice.totalPaid).toFixed(2)}` : '-',
                  cash: method === 'cash' ?  YesText : '-',
                  card: method === 'card' ?  YesText : '-',
                  credit: method === 'credit' ?  YesText : '-',
                  others: method !== 'cash' && method !== 'credit' &&  method !== 'card' ? YesText : '-'
                });        
              });
            }
          });
        // }

        // (ReactDOM as any).unstable_batchedUpdates(() => {
          setCount(amounts);
          setReport(results);
          setLoading(false);
        // });
      } catch(e) {
        console.log(e);
        setReport([]);
        setLoading(false);
      }
     

    })()
  }, [startDate, endDate, selectedFilter, autoRefresh]);

 

  if (loading) {
    return <>
      <Skeleton variant="rect"  height={80} />
      <br/>
      {
        skeletonRows.map((value: number, index: number) => <Skeleton animation="wave"  key={index}/>)
      }
     
    </>
  }

  if (!reports.length) {
    return <div className={'text-center no-patient-info'}> <Label_md >No patients available for selected date(s)</Label_md></div>
  }

  return <>
    
      <div className={'report-stats'} style={{background: '#FFF', justifyContent: 'center'}}>
        
          <div className={'text-center report-bill'}>
            <Label_form>Cash payment </Label_form>
            <br/>
            <Label_md style={{color: '#2497E9'}}>{Math.ceil(billCount.cash)}</Label_md>
          </div>
          <div className={'text-center report-bill'}>
            <Label_form>Card payment </Label_form>
            <br/>
            <Label_md style={{color: '#2497E9'}}>{Math.ceil(billCount.card)}</Label_md>
          </div>
          <div className={'text-center report-bill'}>
            <Label_form>Total Discount </Label_form>
            <br/>
            <Label_md style={{color: '#2497E9'}}>{Math.ceil(billCount.totalDiscount)}</Label_md>
          </div>
       
          <div className={'text-center report-bill'}>
            <Label_form>Total Paid </Label_form>
            <br/>
            <Label_md style={{color: '#2497E9'}}>{(billCount.totalPaid).toFixed(2)}</Label_md>
          </div>
          <div className={'text-center report-bill'}>
            <Label_form>Total Due </Label_form>
            <br/>
            <Label_md style={{color: '#2497E9'}}> ~{Math.ceil(billCount.totalPaid - billCount.totalAmount).toFixed(2)}</Label_md> 
          </div>
          <div className={'text-center report-bill'}>
            <Label_form>Total Amount </Label_form>
            <br/>
            <Label_md style={{color: '#2497E9'}}>{(billCount.totalAmount).toFixed(2)}</Label_md>
          </div>
        
        
    </div>
    <div className={'reports-main-container'}>

      <div className={'reports-table-container'}>
       
        <ReportsTable columns={columns} list={reports}></ReportsTable>
      </div>
    </div>
    </>
  }
