import styled from "styled-components";
import { Row, Col } from "react-bootstrap";

export const Plain_row = styled(Row)``;
export const BORDER_BOTTOM_ROW = styled(Row)`
  border-bottom: 1px solid #aaaaaa;
`;
export const BORDER_RIGHT_COLUMN = styled(Row)`
  border-right: 1px solid #aaaaaa;
  padding: 15px;
`;
export const Plain_column = styled(Col)``;
export const Marker = styled.div`
  border-radius: 6px;
  background: rgba(147, 150, 151, 0.3) !important;
  height: 20px;
  width: 20px;
  float: left;
  margin-right: 10px;
`;

export const Gender_select_container = styled.div`
  display: flex;
  //width: 350px;
  height: 32px;
  flex-flow: row;
`;

export const Gender_display = styled.div`
  display: flex;
  width: 100%;
  height: 32px;
  border: 1px solid #aaaaaa;
  border-radius: 6px;
  margin-right: 10px;
  justify-content: start;
  align-items: center;
  padding: 2px;
  color: #6c6c6c;
  font-size: 13px;
  font-weight: normal;
  cursor: pointer;
  opacity: 90%;
`;

export const Gender_img = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 20px;
  margin-left: 10px;
`;

export const CHECKBOX_IMG = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

export const RADIO_GROUP_DIV = styled.div`
  display: flex;
`;
