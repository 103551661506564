import styled from "styled-components";

// Healthcheckup
export const Visits_accordion = styled.div`
  display: flex;
  flex-flow: column;
  height: 70px;
  width: 100%;
  padding: 15px;
  background: #fff;
  font-size: 14px;
  border-radius: 6px;
  cursor: pointer;
  justify-content: space-evenly;
  border: 1px solid #e4e8ea;
  width: 100%;
`;

export const Checkup_accordion = Visits_accordion;

export const Visit_header_label = styled.div`
  opacity: 0.5;
  font-size: 12px;
  font-weight: normal;
`;

export const Visit_info_label = styled.div`
  color: #333333;
  font-size: 14px;
  opacity: 0.9;
  margin-top: 5px;
  font-weight: 600;
`;

// Medical history
export const History_accordion = styled.div`
  display: flex;
  flex-flow: column;
  width: 900px;
  height: 60px;
  padding: 20px;
  background: #fff;
  font-size: 14px;
  border-radius: 6px;
  cursor: pointer;
  align-contents: center;
  justify-content: space-evenly;
  border: 1px solid #e4e8ea;
  width: 100%;
  margin-top: 15px;
  color: #333333;
`;

export const Dropdown_caret = styled.img`
  width: 18px;
  height: 10px;
  float: right;
  margin-right: 20px;
  margin-top: 15px;
`;

export const Accordion_label = styled.label`
  color: #333333;
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
`;
