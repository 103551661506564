export const ManageCheckup = {
  context: 'manage-health-checkup',
  labels: {
    title: '',
  },
  events: {
    GET_CHECKUP_DATA: 'get_checkup',
    SET_ACTIVE_CHECKUP_STEP: 'set_active_checkup_step',
    MARK_SAMPLES_COLLECTED: 'MARK_SAMPLES_AS_COLLECTED',
    MARK_SAMPLES_COLLECTED_BB: 'MARK_SAMPLES_AS_COLLECTEDBB',
    UPDATE_STEP: 'update_step',
    UPDATE_STEP_DATA: 'update_step_data',
  },
  errors: {
  }
};
