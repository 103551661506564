import * as React from "react";
import { Label_form } from "../../../style-components/Labels";
import Select from "react-select";

export const Dropdown = (props: {
  label: string;
  options: string[];
  customClass?: string;
  value: string;
  updateCb: Function;
  placeHolder: string | "";
  property: string;
  width?: string;
  height?: any;
  validator?: boolean;
}) => {
  const {
    customClass = "select-box",
    label,
    value,
    updateCb,
    placeHolder,
    property,
    options,
    width,
    height,
  } = props;
  // const style: any = {
  //   fontSize: "13px!important",
  //   color: "#5F5F5F",
  //   height: "32px!important",
  //   borderRadius: "6px",
  //   border: "1px solid #AAAAAA!important",
  // };
  const selectStyle = {
    control: (base: any, state: any) => ({
      ...base,
      border: "1px solid #AAAAAA!important",
      height: 32 || height,
      minHeight: 32 || height,
      borderRadius: "5px",
      textTransform: "capitalize!important",
      width: width || "330px",
      color: "#5F5F5F",
      flexWrap: "revert",
      fontSize: "13px",
      "&:hover": {
        border: "1px solid #AAAAAA!important",
      },
      "&:active": {
        border: "1px solid #AAAAAA!important",
      },
    }),
  };

  return (
    <>
      {label ? <Label_form>{label}</Label_form> : null}
      <Select

        placeholder={label}
        className={customClass}
        defaultInputValue={value}
        styles={selectStyle}
        options={options.map((option) => {
          return { value: option, label: option };
        })}
        onChange={(e: any) => updateCb(e.value, property)}
      />
    </>
  );
}
