import {IHaemogram} from "../../types/checkup";

export const HaemogramDefaults = () => {
  return {
    name: 'Haemogram - Complete Blood Count',
    mode: 'EDIT',
    printParam: 'haemogram',
    completed: false,
    records: [{
      id: 'haemogram',
      title: 'Haemogram',
      fields:  [
        {
          name: 'Hemoglobin',
          result: '',
          unit: 'g/dl',
          range: {
            min: 13.0,
            max: 17.0
          }
        },
        {
          name: 'Total Leucocytes Count',
          result: '',
          unit: 'Cells/Cumm',
          range: {
            min: 4000,
            max: 11000
          }
        },
        {
          name: 'Total RBC Count',
          result: '',
          unit: 'Millions/Cumm',
          range: {
            min: 4.6,
            max: 6.0
          }
        },
        {
          name: 'Packaged Cell Volume',
          result: '',
          unit: '%',
          range: {
            min: 40.0,
            max: 52
          }
        },
        {
          name: 'Platelet Count',
          result: '',
          unit: 'Lakhs/Cumm',
          range: {
            min: 1.5,
            max: 4.0
          }
        },
        {
          name: 'MCV',
          result: '',
          unit: 'fl',
          range: {
            min: 76.0,
            max: 96.0
          }
        },
        {
          name: 'MCH',
          result: '',
          unit: 'pg',
          range: {
            min: 27,
            max: 31
          }
        },
        {
          name: 'MCHC',
          result: '',
          unit: 'g/dl',
          range: {
            min: 31,
            max: 36
          }
        },
        {
          name: 'Differential Count',
          type: 'topic',
          result: '',
          unit: '',
          range: {

          }
        },
        {
          name: 'Polymorphs',
          result: '',
          unit: '%',
          range: {
            min: 40,
            max: 65
          }
        },
        {
          name: 'Lymphocytes',
          result: '',
          unit: '%',
          range: {
            min: 30,
            max: 50
          }
        },
        {
          name: 'Eosinophils',
          result: '',
          unit: '%',
          range: {
            min: 2,
            max: 8
          }
        },
        {
          name: 'Basophils',
          result: '',
          unit: '%',
          range: {
            min: 0,
            max: 1
          }
        },
        {
          name: 'Monocytes',
          result: '',
          unit: '%',
          range: {
            min: 0.3,
            max: 12
          }
        },

      ]
    },
    ] as IHaemogram
  };
}
