import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import { Label_form, Label_lg, Label_md } from "../style-components/Labels";
import reportIcon from "../images/reports.svg"
import {  Collapse, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from "@material-ui/core";

const drawerWidth = 200;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: 30,
    //   paddingBottom: 50,
      height: '100vh',
      background: '#F7F7F7',
      width: '80%'

    //   padding: theme.spacing(1),
    },
    nested: {
      paddingLeft: theme.spacing(4),
      fontSize: 12
    },
  }),
);

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const Handlelist = (props: any) => {
  const {list, index, step,setStep} = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const activeStyle = {cursor: 'pointer',  color: '#4A87F8', fontWeight: 'bold', fontSize: '12px'};
  const inactiveStyle = {cursor: 'pointer'};
  let parentActive = false;

  if (index  === 0 && step > -1 && step <3) {
    parentActive = true;
  } else if (index ===1 && step > 2) {
    parentActive = true;
  }

  return <List key={index} >  
          <ListItem button>
        
          <Label_md  style={parentActive  ? activeStyle : inactiveStyle}>{list.type}</Label_md>
          {/* {open ? <ExpandLess /> : <ExpandMore />} */}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          
          <List component="div" disablePadding>
            {
              list.items.map((item: any, index: number) => {
                return <ListItem button key={item.stepIndex} className={classes.nested} onClick={() => setStep(item.stepIndex)}>
                    <Label_form style={item.stepIndex === step ? activeStyle : inactiveStyle}>{item.title}</Label_form>
              </ListItem>
              })
            }
          
          </List>
        </Collapse>
      </List>
}

export default function ReportsLayout(props: any) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);


  const {leftPanelList, leftPanelTitle, children, step, setStep} = props;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div className={classes.toolbar}>
       <List dense={false} subheader={<ListSubheader> <img src={reportIcon}></img> <Label_md>&nbsp;{leftPanelTitle}</Label_md></ListSubheader>} style={{padding: '10px'}}>
                {leftPanelList.map((list: any, index: number) => (<Handlelist index={index} list={list} setStep={setStep} step={step}/>))}
            </List>
      
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
 

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="permanent"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
          <div>
            {children}
          </div>
      </main>
    </div>
  );
};
