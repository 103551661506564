import * as React from "react";
import { Form } from "react-bootstrap";
import { Label_form } from "../../../style-components/Labels";
import { useEffect, useState } from "react";

export const Textbox = (props: {
  label: string;
  value: string;
  updateCb: Function;
  placeHolder: string | "";
  property: string;
  width?: string;
  height?: string;
  validator?: Function;
  autoFocus?: boolean;
  required?: boolean;
  fontSize?: string;
  customClass?: string;
  updateOnChange?: boolean;
  noLabel?: boolean;
}) => {
  const {
    noLabel = false,
    customClass = "",
    label,
    value,
    updateCb,
    placeHolder,
    property,
    autoFocus,
    width,
    height,
    required,
    validator,
    fontSize,
    updateOnChange,
  } = props;
  const [localValue, setValue] = useState(value);
  const [invalid, setInvalid] = useState("");

  const style: any = {
    fontSize: fontSize || "13px",
    color: "#5F5F5F",
    height: "32px",
    borderRadius: "4px",
    border: "1px solid #AAAAAA",
  };
  if (width) {
    style.width = width;
  }

  if (height) {
    style.height = height;
  }

  return (
    <>
      {!noLabel ?  <><Label_form>
        {label}{" "}
        {required ? <span className={"required-indicator"}>*</span> : ""}
      </Label_form></> : <Label_form/>}
      <Form.Control
        style={style}
        className={customClass + (invalid ? " invalid-text-box" : "")}
        type="text"
        placeholder={placeHolder}
        value={localValue}
        onBlur={(e: any) => {
          if (
            (required && !e.target.value) ||
            (validator && !validator(e.target.value))
          ) {
            setInvalid(validator ? `Invalid ${label}` : "Required");
            updateCb(e.target.value, property);
          } else {
            updateCb(e.target.value, property);
          }
        }}
        onChange={(e) => {
          setInvalid("");
          setValue(e.target.value);
          if (updateOnChange) {
            updateCb(e.target.value, property);
          }
        }}
        autoFocus={autoFocus}
      />
      <div style={{ height: "5px", marginBottom: "10px" }}>
        {invalid ? (
          <>
            <span className="required-indicator text-center invalid-value">
              {invalid}
            </span>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
