import * as React from "react";
import { Container, Col, Row } from "react-bootstrap";
import registerImage from "../images/registration/registration.svg";

import "../css/registration.scss";

export const RegistrationLayout = (props: any) => {
  return (
    <Container fluid>
      <Row>
        <Col xs="12" sm="12" md="12" lg="7" className="left-panel">
          <div className="doctor-image">
            <img alt="bwell-doc" src={registerImage} />
            <div className="caption">
              {" "}
              Treat patient effectively and <br /> manage clinics digitally with
              BeWell Digital
            </div>
          </div>
        </Col>
        <Col xs="12" sm="12" md="12" lg="5">
          <div className="form-component">{props.children}</div>
        </Col>
      </Row>
    </Container>
  );
};

export default RegistrationLayout;
