import { constants } from "../../constants";
import { IReducer } from "../../types/patients";
import axios from "axios";
import _ from "lodash";
const { manage_visits } = constants;


export const VisitFiles = async (args: IReducer) => {
  const { state, selectedPatient, params, event, updateState } = args;
  const record_constants = manage_visits.ManageRecords;
  const { events, recordsDefaults }: any = record_constants;
  const {
    FILES: {
      ADD_FILE,
      UPLOAD_FILE
    }
  } = events;

  switch (event) {
    case ADD_FILE: {
      // @ts-ignore
      // selectedPatient.visits[params!.index].records.push(
      //   Object.assign({}, recordsDefaults[ADD_FILE])
      // );

      selectedPatient.visits[params!.index].records.push(_.cloneDeep(Object.assign({}, recordsDefaults[ADD_FILE], {items: []})));
      updateState(Object.assign({}, state, { selectedPatient }));

      break;
    }
    case UPLOAD_FILE: {
      const {file, description, customName} = params!;
      const ext = file.filemeta.split('/')[1];
      const name = file.name.trim();
      const url = `${constants.betaEndPoint}/upload-to-s3?patientId=${selectedPatient.patientId}&name=${name}&ext=${ext}`;
      const postData = {content: file.url};

     try {
        const upload =  await axios.post(`${url}`, postData);
        const record = selectedPatient.visits[state.visitIndex].records[params!.index];
        if (!record.items[params!.itemIndex]) {
          record.items[params!.itemIndex] = {};
        }

        record.items[params!.itemIndex].name = customName || name;
        record.items[params!.itemIndex].url = url;
        record.items[params!.itemIndex].description = description;
        record.items[params!.itemIndex].filemeta = ext;

        selectedPatient.visits[state.visitIndex].records.splice(params!.index,1, Object.assign(
          {},
          record,
          { mode: "PREVIEW" },
          { items: record.items }
        ));
       updateState(Object.assign({}, state, { selectedPatient }), true);
     } catch(e) {
        console.log(e);
     }
     break;
    }
  }

};
