import {
  IFiles,
  ILaborders,
  INotes,
  IPrescription,
  IProcedure,
  IProcedures, ITreatmentplan, IVisitNotes,
  IVitals,
  TRecordMode
} from "../../types/visit-records";

export const ManageRecords = {
  context: 'visit-records',
  labels: {
    title: '',
    prescriptions: 'Prescriptions',
    notes: 'Clinical Notes',
    vitals: 'Vital Signs',
    labOrders: 'Lab Orders',
    procedures: 'Completed procedures',
    files: 'Files',
    visitNotes: 'Notes',
    treatmentPlan: 'Treatment Plan'
  },
  events: {
    ADD_NEW_RECORD: 'add_new_visit_record',
    REMOVE_RECORD: 'remove_visit_record',
    PRESCRIPTIONS: {
      ADD_PRESCRIPTION: 'manage-prescriptions',
      UPDATE_PRESCRIPTION: 'update_prescription',
      ADD_FROM_PREVIEW_MODE: 'add_from_preview_mode'
    },
    VISIT_NOTES: {
      ADD_VISIT_NOTES: 'visit-notes',
      UPDATE_VISIT_NOTES: 'update_visit_notes'
    },
    NOTES: {
      ADD_NOTES: 'add-clinic-notes',
      UPDATE_NOTES: 'update_notes',
    },
    VITALS: {
        ADD_VITALS: 'add-vitals',
        UPDATE_VITALS: 'update_vitals',
    },
    LAB_ORDERS: {
        ADD_ORDER: 'lab-orders',
        UPDATE_ORDER: 'update_lab_orders',
        ADD_FROM_PREVIEW_MODE: 'add_from_preview_mode',
    },
    FILES: {
        ADD_FILE: 'manage-files',
        UPLOAD_FILE: 'upload_file',
    },
    PROCEDURES: {
      ADD_PROCEDURE: 'completed-procedures',
      UPDATE_PROCEDURE: 'update_completed_procedure',
      ADD_FROM_PREVIEW_MODE: 'add_from_preview_mode',
    },
    TREATMENT_PLAN: {
      ADD_PLAN: 'add-treatment-plan',
      UPDATE_TREATMENT_PLAN: 'update_treatment_plan'
    },
    ADD_BILLING: 'billing-record',
    ADD_CLINICAL_NOTES: 'clinical-notes',
    ADD_COMPLETED_PROCEDURES: 'completed-procedures',
    ADD_LAB_ORDERS: 'lab-orders',
    ADD_TREATMENT_PLANS: 'treatment-plans',
    ADD_VITAL_SIGNS: 'vital-signs',
    CANCEL_EDIT: 'cancel_edit',
    SHOW_EDIT: 'show_edit',
  },
  errors: {
  },
   recordsDefaults: {
    'manage-prescriptions': {
      id: 'manage-prescriptions',
      mode: 'EDIT',
      items: [
        {
          name: '',
          morning: 0,
          afternoon: 0,
          evening: 0,
          night: 0,
          administration: 'oral',
          intake: 'after-food',
          days: 1,
        }
      ]
    } as IPrescription,
    'add-clinic-notes': {
      id: 'add-clinic-notes',
      mode: 'EDIT',
      complaints: '',
      observations: '',
      diagnosis: '',
    } as INotes,
    'add-vitals': {
      id: 'add-vitals',
      mode: 'EDIT',
      weight: '',
      height: '',
      bp: {
        high: 120,
        low: 80,
      },
      pulse: '',
      temperature: '',
      tempScale: 'fahrenheit',
      respRate: '',
      saturation: '',
      bloodSugar: '',
      notes: '',
      painAssessment: '',
    } as IVitals,
    'lab-orders': {
      id: 'lab-orders',
      mode: 'EDIT',
      items: [{
        name: '',
        description: '',
      }]
    } as ILaborders,
     'completed-procedures': {
       id: 'completed-procedures',
       mode: 'EDIT',
       items: [{
         name: '',
         unit: 1,
         cost: 0,
         discount: 0,
       }],
     } as IProcedures,
     'manage-files': {
       id: 'manage-files',
       mode: 'EDIT',
       items: [{
         name: '',
         url: '',
         description: '',
         filemeta: ''
       }],
     } as IFiles,
     'visit-notes': {
      id: 'visit-notes',
       mode: 'EDIT',
       notes: '',
     } as IVisitNotes,
     'add-treatment-plan': {
       id: 'add-treatment-plan',
       mode: 'EDIT',
       notes: '',
     } as ITreatmentplan

  }
}

export const PrescriptionIntake = [{label: 'After food', value: 'after-food'}, {label: 'Before food', value: 'before-food'}, {label: 'As needed', value: 'as-needed'}, {label: '-', value: '-'}]
export const PrescriptionAdministration = [
  {
    label: 'Oral',
    value: 'oral',
  },
  {
    label: 'Nasal',
    value: 'nasal',
  },
  {
    label: 'Tropical',
    value: 'tropical',
  },
  {
    label: 'Inj IM',
    value: 'inj-im',
  },
  {
    label: 'Inj IV',
    value: 'inj-iv',
  },
  {
    label: 'Inj ID',
    value: 'inj-id',
  },
  {
    label: 'Inj SC',
    value: 'inj-sc',
  },
  {
    label: '-',
    value: '-',
  }
]
