import { constants } from "../../constants";
import { IReducer } from "../../types/patients";

const { manage_visits } = constants;

export const TreatmentPlans = async (args: IReducer) => {
  const { state, selectedPatient, params, event, updateState } = args;
  const record_constants = manage_visits.ManageRecords;
  const { events, recordsDefaults }: any = record_constants;
  const {
    TREATMENT_PLAN: { ADD_PLAN, UPDATE_TREATMENT_PLAN },
  } = events;

  switch (event) {
    case ADD_PLAN: {
      // @ts-ignore
      selectedPatient.visits[params!.index].records.push(
        Object.assign({}, recordsDefaults[ADD_PLAN])
      );
      break;
    }
    case UPDATE_TREATMENT_PLAN: {
      const record =
        selectedPatient.visits[state.visitIndex].records[params!.index];
      selectedPatient.visits[state.visitIndex].records.splice(
        params!.index,
        1,
        Object.assign(
          {},
          record,
          { mode: "PREVIEW" },
          { notes:  params!.notes }
        )
      );
      break;
    }
  }

  updateState(Object.assign({}, state, { selectedPatient }));
};
