import {IClinic} from "../types/add-clinic";
import {addClinicPage} from "../statemanager/managehospitals";

export const Addclinic = {
  context: 'add-clinic',
  labels: {
    title: 'Clinic information',
  },
  events: {
    ADD_NEW_CLINIC: 'add_new_clinic',
    UPDATE_CLINIC: 'update_clinic',
    UPDATE_PASSWORD: 'update_password',
    VERIFY_PASSWORD: 'verify_password',
    GET_ALL_HOSPITALS: 'get_all_hospitals',
    GO_BACK_TO_LIST: 'go_to_list'
  },
  errors: {
    UNABLE_TO_UPDATE_SERVER: {display: 'Unable to update clinic info', error: 'unableToUpdateClinic'},
    INVALID_PASSWORD: {display: 'Invalid Password',  error: 'invalidPassword'},
    PASSWORD_MISMATCH: {display: 'Make sure passwords match', error: 'passwordsDontMatch'},
    REQUIRED_FIELDS_MISSING: {display: 'Required fields are missing', error: 'requiredFieldsAreMissing'}

  },
  tnDistricts: ['Thiruvallur', 'Chennai', 'Kancheepuram', 'Vellore', 'Tiruvannamalai',
      'Viluppuram', 'Salem', 'Namakkal', 'Erode', 'The Nilgiris', 'Dindigul',
     'Karur', 'Tiruchirappalli', 'Perambalur', 'Ariyalur', 'Cuddalore',
     'Nagapattinam', 'Thiruvarur', 'Thanjavur', 'Pudukkottai', 'Sivaganga',
     'Madurai', 'Theni', 'Virudhunagar', 'Ramanathapuram', 'Thoothukkudi',
     'Tirunelveli', 'Kanniyakumari', 'Dharmapuri', 'Krishnagiri', 'Coimbatore', 'Tiruppur'
  ],
  tariffTypes: [
    'lab',
    'consultation',
    'room'
  ],
  specializations: [{
    id: 'multi',
    name: 'Multi speciality',
    },
    {
      id: 'ortho',
      name: 'Ortho',
    },
    {
      id: 'Diabetics',
      name: 'Diabetics',
    },
    {
      id: 'cardiaology',
      name: 'Cardiology',
    },
  ],
  centerType: [{
    id: 'mid-hospital',
    name: 'Mid size hospital',
  },
    {
      id: 'mid-size-clinic',
      name: 'Mid Size Clinic (More than one doctor)',
    },
    {
      id: 'small-size-clinic',
      name: 'Small Size Clinic (Only one doctor)',
    }
  ],
  departments: [
    {
      id: 'OP',
      name: 'Out Patient',
    },{
    id: 'IP',
    name: 'In Patient',
  },

    {
      id: 'Health-Checkup',
      name: 'Health checkup',
    }
  ],

};

export const hospitalBasicDetails = {
  name: '',
  emailId: '',
  specialization: Addclinic.specializations[0],
  website: '',
  gst: '',
  type: Addclinic.centerType[0],
  departments: [],
  phone: '',
  location: {
    address: '',
    areaCode: '',
    city: '',
    state: 'Tamil Nadu',
    country: 'India',
  },
  autogenerateUhid: true,
};

export const Tariffstypes = [
  {
    id: 'doctor',
    name: 'Doctor',
  },

];
