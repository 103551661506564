import React, {useState, useEffect, useMemo} from "react";
import {Auth} from "aws-amplify";
import {constants} from "./constants";
import {API} from "./utils/Api";
import ReactDOM from 'react-dom';
import {SpinnerLoader} from "./components/shared/SpinnerLoader";
import _ from 'lodash';

export const  Appcontext = React.createContext(null as any);

const serviceFn = {
  getDoctors: (centerId: string) => {
    return API.get('/doctors/'+centerId);
  },
  getCenterInfo: (centerId: string) => {
    return API.get('/medicalCenter/'+centerId);
  },
  getTariffs: (centerId: any) => {
    return API.get('/tariffs/'+centerId);
  },
}

export const Appcontextprovider = (props: any) => {

  const [authenticatedUser, setAuthenticatedUser] = useState(constants.appEvents.LOADING_APP);
  const [user, setUser] = useState({});
  const [centerId, setCenterId] = useState(null as any);
  const [pharma, setpharma] = useState(null as any);
  const [tariffs, setTarrifs] = useState(null as any);
  const [doctors, setDoctors] = useState(null as any);
  const [centerInfo, setCenterInfo] = useState(null as any);
  const [labtests, setlabtests] = useState(null as any);

  useEffect(()=>{
    (async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const {attributes} = user;
        const centerId = attributes['custom:centerId'] || attributes.name;
        const tariffs = await serviceFn.getTariffs(centerId);
        const doctors = await serviceFn.getDoctors(centerId);
        const centerInfo = await serviceFn.getCenterInfo(centerId);
        const getlabMeta = await API.get(`/labtests/meta`);
        // const pharma = await API.get(`/pharmacy?medicalCenterId=${centerId}`);

        
        (ReactDOM as any).unstable_batchedUpdates(() => {
            setCenterId(centerId);
            setUser(user);
            setTarrifs(tariffs.data);
            setDoctors(doctors.data);
            setCenterInfo(centerInfo.data[0]);
            setlabtests(getlabMeta.data);
            // if (attributes['custom:onlyPharmacy'] === 'true') {
            //   setpharma(pharma.data.data[0]);
            // }
        
            setAuthenticatedUser(constants.appEvents.AUTHENTICATED_USER);
        });

     
     
        if (attributes['custom:onlyPharmacy'] === 'true' && window.location.href.indexOf('/pharmacy') === -1) {
          window.location.href = "/pharmacy/"+(attributes['custom:centerId'] || attributes.name);
          return;
        } 
    
        if (window.location.href.indexOf('/patients') === -1 && window.location.href.indexOf('/logout') === -1 && window.location.href.indexOf('/add-patient') === -1 && window.location.href.indexOf('/manage-clinics') === -1 &&  window.location.href.indexOf('/analytics') === -1 &&  window.location.href.indexOf('/pharmacy') === -1)  {
            window.location.href = "/patients/"+(attributes['custom:centerId'] || attributes.name);
        }


      } catch(e) {
        console.log(e);
        await Auth.signOut();
        setAuthenticatedUser(constants.appEvents.NOT_AUTHENTICATED_USER);

        if (window.location.href.indexOf('/login') === -1){
          window.location.href='/login';
        }
      }

    })();
  }, []);

  const contextValue = useMemo(
    () => ({
      authenticatedUser,
      user,
      centerId,
      tariffs,
      centerInfo,
      doctors,
      labtests,
      pharma,
      setAuthenticatedUser : (value: any) => setAuthenticatedUser(value),
    }),
    [authenticatedUser, centerId, centerInfo, doctors, tariffs, user],
  );

  if (authenticatedUser === constants.appEvents.LOADING_APP) {
    return <SpinnerLoader/>;
  }

  return (
    <Appcontext.Provider
      value={contextValue}
    >
      {props.children}
    </Appcontext.Provider>
  );
};

export default Appcontextprovider;
