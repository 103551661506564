export const medicalHistory = {
  title: 'Medical History',
  subtitle: '',
  id: 'medical-history'
  // items: [{
  //   id: 'unique1',
  // title: 'History of weight loss',
  // type: 'toggle',
  // children: [{
  //   title: 'comment',
  //   type: 'textareabox',
  // }]
  // },
  // {
  //   title: 'Diabetics',
  //   id: 'unique2',
  //   type: 'toggle',
  //   children: [{
  //     title: 'Treatment for diabetics',
  //     type: 'illness',
  //   },
  //     {
  //       title: 'Medications',
  //       type: 'medication',
  //     }
  //   ]
  // },
  // {
  //   title: 'Hypertension',
  //   type: 'toggle',
  //   id: 'unique3',
  //   children: [{
  //     title: 'Treatment for hypertension',
  //     type: 'illness',
  //   }, {
  //     title: 'Medications',
  //     type: 'medication',
  //   }]
  // },
  // {
  //   title: 'Cardiovascular disease',
  //   type: 'toggle',
  //   id: 'unique4',
  //   children: [{
  //     title: 'Treatment for hypertension',
  //     type: 'illness',
  //   },
  //     {
  //       title: 'Medications',
  //       type: 'medication',
  //     }
  //   ]
  // },
  // {
  //   title: 'Respiratory illness',
  //   id: 'unique5',
  //   type: 'toggle',
  //   children: [{
  //     title: 'Treatment for hypertension',
  //     type: 'illness',
  //   },
  //   ]
  // },
  // {
  //   title: 'Gastrointestinal',
  //   type: 'toggle',
  //   id: 'unique6',
  //   children: [{
  //     title: 'Treatment for hypertension',
  //     type: 'illness',
  //   },
  //   ]
  // },
  // {
  //   title: 'Genitourinary conditions',
  //   type: 'toggle',
  //   id: 'unique7',
  //   children: [{
  //     title: 'Treatment for hypertension',
  //     type: 'illness',
  //   },
  //   ]
  // },
}


