import { ManageRecords } from "./ManageRecords";
const { events } = ManageRecords;

export const RecordsMenuitems = [
  // {
  //   title: 'Billing',
  //   id: 'billing-record'
  // },
  {
    title: "Vital Signs",
    id: events.VITALS.ADD_VITALS,
  },
  {
    title: "Clinical Notes",
    id: events.NOTES.ADD_NOTES,
  },
  {
    title: "Lab Orders",
    id: events.LAB_ORDERS.ADD_ORDER,
  },
  {title: "Files", id: events.FILES.ADD_FILE},
  {
    title: "Prescriptions",
    id: events.PRESCRIPTIONS.ADD_PRESCRIPTION,
  },
  {
    title: "Treatment plan",
    id: events.TREATMENT_PLAN.ADD_PLAN,
  },
  {
    title: "Completed Procedures",
    id: events.PROCEDURES.ADD_PROCEDURE,
  },
  {
    title: "Notes",
    id: events.VISIT_NOTES.ADD_VISIT_NOTES,
  },


  // {
  //   title: 'Files',
  //   id: events.FILES.ADD_FILE
  // },
];

export const RecordsIPMenuitems = [
  // {
  //   title: 'Billing',
  //   id: 'billing-record'
  // },
  {
    title: "Clinical Notes",
    id: events.NOTES.ADD_NOTES,
  },
  {
    title: "Vital Signs",
    id: events.VITALS.ADD_VITALS,
  },

  {
    title: "Lab Orders",
    id: events.LAB_ORDERS.ADD_ORDER,
  },
  {title: "Files", id: events.FILES.ADD_FILE},
  {
    title: "Prescriptions",
    id: events.PRESCRIPTIONS.ADD_PRESCRIPTION,
  },
  {
    title: "Treatment plan",
    id: events.TREATMENT_PLAN.ADD_PLAN,
  },
  {
    title: "Completed Procedures",
    id: events.PROCEDURES.ADD_PROCEDURE,
  },
  {
    title: "Notes",
    id: events.VISIT_NOTES.ADD_VISIT_NOTES,
  },


  // {
  //   title: 'Files',
  //   id: events.FILES.ADD_FILE
  // },
];
