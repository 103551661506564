import * as React from "react";
import CheckedIcon from "../../../images/patient/Checkbox_checked.svg";
import UncheckIcon from "../../../images/patient/Checkbox_uncheck.svg";
import { CHECKBOX_IMG } from "../../../style-components/Divs";
import { Label_form, Label_md } from "../../../style-components/Labels";

export const Checkbox = (props: {
  updateCb: Function;
  option: string;
  checked: boolean;
  disabled?: boolean;
  boldItem?: boolean;
}) => {
  const { checked, option, updateCb, disabled, boldItem=false } = props;
  const style = disabled ? { marginRight: "30px", opacity: '0.9', pinterEvents: 'none' } :  { marginRight: "30px" };
  // const [selected, setSelected] = useState(checked);

  return (
    <span style={style}  onClick={() => {
      updateCb(option.toLowerCase());
    }}>
      <CHECKBOX_IMG
        src={checked ? CheckedIcon : UncheckIcon}
        alt={"check box"}
      />
      {boldItem ?  <Label_md>{option}</Label_md> : <Label_form>{option}</Label_form> } 
    </span>
  );
};
