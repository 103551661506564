import * as React from "react";
import {useEffect, useState} from "react";
import { Col, Form, Row } from "react-bootstrap";
import addVisit from "../../../images/patient/visit-add-icon.svg";
import deleteIcon from "../../../images/patient/delete-prescription-record.svg";
import {Cancel_btn_sm, Save_btn_sm} from "../../../style-components/StyledButtons";
import '../../../css/patients/visits.scss';
import '../../../css/patients/visits/prescriptions-edit.scss';
import {Textbox} from "../../shared/Inputcomponents/Textbox";
import {API} from "../../../utils/Api";
import {SpinnerLoader} from "../../shared/SpinnerLoader";
import {billsPage} from "../../../statemanager/bills";
import {Dropdown} from "../../shared/Inputcomponents/Dropdown";
import {Label_form} from "../../../style-components/Labels";
import {Typeahead} from "react-bootstrap-typeahead";
import ReactDOM from 'react-dom';

export const Managetariffs = (props: any) => {
  const { clinic } = props;
  const [loading, setLoading] = useState(true);
  const [tariffs, setTariffs] = useState([] as any);
  const [meta, setMeta] = useState({} as any);
  const [typeddOpen, settypeDDOpen] = useState(false);
  const [nameddOpen, setNameDDOpen] = useState(false);

  const saveTariffs = (async (deleteIndex?: number) => {
    setLoading(true);
    await Promise.all(tariffs.map(async (tariff: any, index: number) => {
      if (deleteIndex === index) {
        await API.post(  '/tariffs/delete', tariff);
      } else {
        if (tariff.name) {
           await API.post( '/tariffs', tariff);
        }
      }

    }));
    const response = await API.get('/tariffs/'+clinic.medicalCenterId);
    setTariffs(response.data);
    setLoading(false);
    alert(deleteIndex! > -1 ? 'Tariffs deleted' : 'Tariffs list updated');
  });

  useEffect(()=>{
    (async () => {
      setTimeout(async ()=>{
        const response = await API.get('/tariffs/'+clinic.medicalCenterId);
        const labtests = await API.get('/tariffs/meta');
        ReactDOM.unstable_batchedUpdates(() => {
          setMeta(labtests.data);
          setTariffs(response.data);
          setLoading(false);
        });

      }, 100)

    })();
  }, [])

  if (loading) {
    return <SpinnerLoader/>
  }


  return (
    <div style={{background: '#FFF', padding: '10px'}}>
      <Row className={"prescription-edit-header text-center-align"} >
        <Col>Name</Col>
        <Col>Type</Col>
        <Col>Cost</Col>
      </Row>
      {tariffs.map((item: any, index: number) => {

        return (
          <Form key={index}>
            <Row className={"prescription-edit-body prescription-row-body"}>
              <Col>
                <Typeahead
                  id={'tariffs-'+index}
                  defaultInputValue={item.name}
                  allowNew={true}
                  open={nameddOpen}
                  clearButton={true}
                  options={meta.autosuggestNames}
                  placeholder={"Name"}
                  onInputChange={(value) => {
                    setNameDDOpen(true);
                  }}
                  onChange={(value: any) => {
                    tariffs[index] = Object.assign({}, tariffs[index], {
                      name: value[0] && value[0].label  ? value[0].label : value[0],
                    });
                    setTariffs([...tariffs]);
                    setNameDDOpen(false);
                  }}
                />
              </Col>
              <Col >
                {/*<Label_form>Type</Label_form>*/}
                <Dropdown
                  width={"100%"}
                  height={"32px"}
                  options={meta.types}
                  label={""}
                  property={""}
                  placeHolder={""}
                  value={item.type}
                  updateCb={(value: string) => {
                    tariffs[index] = Object.assign({}, tariffs[index], {
                      type: value,
                    });
                    setTariffs([...tariffs]);
                  }}
                />

              </Col>
              <Col >
                <Row>
                 <Col xs={8}>
                  <Textbox
                    noLabel={true}
                    width={"100%"}
                    label={""}
                    property={"cost"}
                    placeHolder={"cost"}
                    value={item.cost}
                    updateCb={(value: string, property: string)=>{
                      tariffs[index] = Object.assign({}, tariffs[index], {
                        cost: value,
                      });
                      setTariffs([...tariffs]);
                    }}
                  />
                 </Col>
                  <Col style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                      <img
                        src={deleteIcon}
                        alt={""}
                        style={{height: '20px', width: '20px'}}
                        onClick={() => {
                            const res = window.confirm('Are you sure ?');
                            if (res) {
                              saveTariffs(index);
                            }
                        }}
                      />

                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        );
      })}
      <br/>
      <Row>
        <Col>
          <div className={"add-prescription"}>
            <div
              className={"add-prescription-btn"}
              onClick={() => {
                tariffs.push({
                  name: '',
                  type: '',
                  cost: 0,
                  medicalCenterId: clinic.medicalCenterId
                });
                setTariffs([...tariffs]);
              }}
            >
              <img src={addVisit} alt="add prescription"  />
              Add new Tariff
            </div>
          </div>
        </Col>
        <Col className={"visits-record-footer"}>
          &nbsp;
          <Cancel_btn_sm
            className={
              "btn btn-outline-dark btn-sm"

            }
            onClick={() => console.log('Cancel')}
          >
            {" "}
            Cancel{" "}
          </Cancel_btn_sm>
          <Save_btn_sm
            className={
              "btn btn-dark btn-sm"
            }
            onClick={() =>
              saveTariffs()
            //  addClinicPage.reducerFn(addClinicPage.constants.events.UPDATE_CLINIC, {
            //    clinic: Object.assign({}, clinic, {tariffs: [...tariffs]})}
            //    )
            }
          >
            Save
          </Save_btn_sm>
        </Col>
      </Row>
    </div>
  );
}

export default Managetariffs;
