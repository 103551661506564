import { constants } from "../../constants";
import { IReducer } from "../../types/patients";

const { manage_visits } = constants;

export const VisitNotes = async (args: IReducer) => {
  const { state, selectedPatient, params, event, updateState } = args;
  const record_constants = manage_visits.ManageRecords;
  const { events, recordsDefaults }: any = record_constants;
  const {
    VISIT_NOTES: { ADD_VISIT_NOTES, UPDATE_VISIT_NOTES },
  } = events;

  switch (event) {
    case ADD_VISIT_NOTES: {
      // @ts-ignore
      selectedPatient.visits[params!.index].records.push(
        Object.assign({}, recordsDefaults[ADD_VISIT_NOTES])
      );
      break;
    }
    case UPDATE_VISIT_NOTES: {
      const record =
        selectedPatient.visits[state.visitIndex].records[params!.index];
      selectedPatient.visits[state.visitIndex].records.splice(
        params!.index,
        1,
        Object.assign(
          {},
          record,
          { mode: "PREVIEW" },
          { notes:  params!.notes }
        )
      );
      break;
    }
  }

  updateState(Object.assign({}, state, { selectedPatient }));
};
