import { constants } from "../../constants";
import { IReducer } from "../../types/patients";
const { manage_visits } = constants;

export const Prescriptions = async (args: IReducer) => {
  const { state, selectedPatient, params, event, updateState } = args;
  const record_constants = manage_visits.ManageRecords;
  const { events, recordsDefaults }: any = record_constants;
  const {
    PRESCRIPTIONS: {
      ADD_PRESCRIPTION,
      UPDATE_PRESCRIPTION,
      ADD_FROM_PREVIEW_MODE,
    },
  } = events;

  switch (event) {
    case ADD_PRESCRIPTION: {
      // @ts-ignore
      selectedPatient.visits[params!.index].records.push(
        Object.assign({}, recordsDefaults[ADD_PRESCRIPTION])
      );
      break;
    }
    case UPDATE_PRESCRIPTION: {
      const record =
        selectedPatient.visits[state.visitIndex].records[params!.index];
      selectedPatient.visits[state.visitIndex].records.splice(
        params!.index,
        1,
        Object.assign(
          {},
          record,
          { mode: "PREVIEW" },
          { items: params!.prescriptions }
        )
      );
      break;
    }
    case ADD_FROM_PREVIEW_MODE: {
      const record =
        selectedPatient.visits[state.visitIndex].records[params!.index];

      if (!record.items) {
        record.items = [];
      }

      record.items.push({
        name: "",
        morning: 1,
        afternoon: 1,
        evening: 1,
        intake: "before-food",
        days: 1,
      });

      record.mode = "EDIT";
      selectedPatient.visits[state.visitIndex].records.splice(
        params!.index,
        1,
        Object.assign({}, record)
      );
      break;
    }
  }

  updateState(Object.assign({}, state, { selectedPatient }));
};
