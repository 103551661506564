import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useState, useEffect } from "react";
import { Table, Button, Form } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Appcontext } from "../../Appcontextprovider";
import MainmenuPharmacy from "../../components/MainmenuPharmacy";
import { SpinnerLoader } from "../../components/shared/SpinnerLoader";
import { Visit_header_label, Visit_info_label } from "../../style-components/Accordions";
import { API } from "../../utils/Api";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Inputsearchbox from "../../components/shared/Inputcomponents/Inputsearchbox";
import addVisit from "../../images/patient/visit-add-icon.svg";
import { Label_form, Label_md } from "../../style-components/Labels";
import { Numberinput_v2 } from "../../components/shared/Inputcomponents/Numberinput_v2";
import deleteIcon from "../../images/patient/delete-prescription-record.svg";
import { billsPage } from "../../statemanager/bills";
import { Cancel_btn_sm, Save_btn_sm } from "../../style-components/StyledButtons";


const SearchDrugs = (props: any) => {
  const { name, updateBillItem, index, focus } = props;
  const updateIndex = index;
  const [isSearching, setSearching] = useState(false);
  
  return (
    <div key={index}>
      {/* <FontAwesomeIcon icon={faSearch} className={"tablet-search-icon"} /> */}
      <Inputsearchbox
        menuId={"visit-prescription-add-"+index}
        selectedValue={name}
        updateCb={(value: string) => {
          updateBillItem("name", value, updateIndex);
          setSearching(true);
        }}
        slug={"/medicine"}
        placeholder={"Add medication"}
        minLength={3}
        autoFocus={focus}
        clearInput={false}
      />
    </div>
  );
};


export const PharmaBilling: React.FunctionComponent = (
  { match, location }: any,
  props
) =>  { 
  const { params } = match;
  const {custId} = params;
  const {centerId, pharma} = React.useContext(Appcontext); 
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState([] as any);
  const [drugs, setDrugs] = useState([] as any);


  const [billsItems, setBillItems] = useState([{
    "customerId": custId,
    "pharmacyId": pharma.id,
    "name": "",
    "drugId": 1,
    "batch": "Batch",
    "quantity": '',
    "rate": '',
    "discountPercentage": '',
    "discountValue": '',
    "tax": '',
    "total": ''
  }] as any);

  const [payment, setPayment] = useState({
    "customerId": 1,
    "pharmacyId": 1,
    "paymentMode": "Cash",
    "amount": ''
  });

  const billItemLength = billsItems.length - 1;


  const addComma = (data: any) => {
    try {
      return (data.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,"))
    } catch(e) {
      return data;
    }

  }

  const updateBillItem = (property: string, value: any, updateIndex: any) => {
    const items = [...billsItems];
   
    if (property === 'discountPercentage') {
      items[updateIndex] = Object.assign({}, billsItems[updateIndex], {
        [property]: value,
        "discountValue": (Number(items[updateIndex].rate * value) / 100),
      });
    } else if (property === 'discountValue') {
      items[updateIndex] = Object.assign({}, billsItems[updateIndex], {
        "discountValue": value,
        "discountPercentage": (Number(items[updateIndex].rate * value) * 100),
      });
    } else {
      items[updateIndex] = Object.assign({}, billsItems[updateIndex], {
        [property]: value,
      });
  
    }

  
    // caclculating 
    const item = items[updateIndex];
    if (item.name) {
      const cost = Number(item.rate || 0) * Number(item.quantity || 0);
      const discountedCost =  Number(item.discountValue || 0);
      const tax = (Number(item.tax || 0) * (cost - discountedCost)) / 100;
      item.total = (cost - discountedCost) + tax;
      if (!item.total) {
        item.total = '';
      } else {
        item.total = item.total.toFixed(2);
      }
      items[updateIndex] = item;
    }
    
    setBillItems([...items]);
  }

  const history = useHistory();

  const total = billsItems.reduce((acc: any, item: any) => acc + Number(item.total), 0);

  console.log(total);
  React.useEffect(()=>{
    (async () => {
      // Should have filter to get customer with custId right now filtering from data
      const customerReponse  = await API.get(`/pharmacy/customers?pharmacyId=${pharma.id}`);
      const drugsResponse = await API.get(`/pharmacy/drugs?pharmacyId=${pharma.id}`);
     
      const InvalidCustomer = () => {
        toast.error('Customer not found');
        history.push(`/pharmacy/${centerId}`);
        setLoading(false);
      }

      if (customerReponse.data && customerReponse.data.data && customerReponse.data.data.length) {
        const cust = customerReponse.data.data.find((cust: any) => cust.id === Number(custId));
        if(!cust) {
          InvalidCustomer();
          return;
        }
        setCustomer(cust);
        setLoading(false);
      } else {
        InvalidCustomer();
      }
      

    })()
  }, []);

  const deleteBillItem = (index: number) => {
    const items = [...billsItems];
    items.splice(index, 1);
    setBillItems([...items]);
  };

  if (loading) {
      return <SpinnerLoader></SpinnerLoader>
  }

  return <>
      <MainmenuPharmacy centerId={centerId} menuIndex={0}></MainmenuPharmacy>
      <div className='container-fluid pharma-billing'>
        <div className='header-row'>
            <div>
              <Visit_header_label>Patient name</Visit_header_label>
              <Visit_info_label>{customer.name}</Visit_info_label>
            </div>
            <div className='total-bill'>
              <Visit_header_label>Due amount</Visit_header_label>
              <Visit_info_label>{addComma(total)}</Visit_info_label>
            </div>
        </div>
        <div className='billing-table'>
           <Table size={'sm'} bordered={true}>
             <thead>
                <tr>
                  <th key={'th-id-1'}>
                    S.No
                  </th>
                  <th key={'th-id-2'} colSpan={3}>
                    Name
                  </th>
                  <th key={'th-id-3'}>
                    Batch
                  </th>
                  <th key={'th-id-4'}>
                    Quantity
                  </th>
                  <th key={'th-id-5'} colSpan={2}>
                    Rate
                  </th>
                  <th key={'th-id-6'}>
                    Dis %
                  </th>
                  <th key={'th-id-7'}>
                    Dis
                  </th>
                  <th key={'th-id-8'}>
                    Tax
                  </th>
                  <th key={'th-id-9'}>
                    Total
                  </th>
                  <th key={'th-id-10'}>
                    Cnl
                  </th>
                </tr>
             </thead>
             <tbody>
               {
                 billsItems.length ?  billsItems.map((billItem: any, index: number) => {
                    return <tr key={index}>
                        <td>
                         <Label_form> {index +1} </Label_form>
                        </td>
                        <td colSpan={3}>
                            <SearchDrugs updateBillItem={updateBillItem} name={billItem.name} index={index} focus={billItemLength === index}/>
                        </td>
                        <td>
                          Batch info
                        </td>
                        <td>
                          <Numberinput_v2
                              width={'100%'}
                              min={0}
                              max={10000000}
                              label={""}
                              property={""}
                              placeHolder={""}
                              value={billItem.quantity}
                              noLabel={true}
                              updateCb={(value: number) => {
                                updateBillItem("quantity", (value), index);
                              }}
                            />

                        </td>
                        <td colSpan={2}>
                          <Numberinput_v2
                              width={'100%'}
                              min={0}
                              max={10000000}
                              label={""}
                              property={""}
                              placeHolder={""}
                              value={billItem.rate}
                              noLabel={true}
                              updateCb={(value: number) => {
                                updateBillItem("rate", (value), index);
                              }}
                            />

                        </td>
                        <td>
                        <Numberinput_v2
                              width={'100%'}
                              min={0}
                              max={10000000}
                              label={""}
                              property={""}
                              placeHolder={""}
                              value={billItem.discountPercentage}
                              noLabel={true}
                              updateCb={(value: number) => {
                                updateBillItem("discountPercentage", (value), index);
                              }}
                            />
                        </td>
                        <td>
                        <Numberinput_v2
                              width={'100%'}
                              min={0}
                              max={10000000}
                              label={""}
                              property={""}
                              placeHolder={""}
                              value={billItem.discountValue}
                              noLabel={true}
                              updateCb={(value: number) => {
                                updateBillItem("discountValue", (value), index);
                              }}
                            />
                        </td>
                        <td>
                        <Numberinput_v2
                              width={'100%'}
                              min={0}
                              max={10000000}
                              label={""}
                              property={""}
                              placeHolder={""}
                              value={billItem.tax}
                              noLabel={true}
                              updateCb={(value: number) => {
                                updateBillItem("tax", (value), index);
                              }}
                            />
                        </td>
                        <td>
                          <Label_md> {billItem.total} </Label_md>
                        </td>
                        <td>
                        <img
                          src={deleteIcon}
                          alt={""}
                          onClick={() => {
                            deleteBillItem(index);
                          }}
                        />
                        </td>
                    </tr>
                 }) :
                 <tr key='empty-row'>
                   <td colSpan={13} className='text-center'>
                       <Label_form> No Items Added , Press Add new record to start adding items</Label_form>
                   </td>
                 </tr>
               }

             </tbody>
           </Table>
        </div>
        <br/>
        <div>
          <Button variant="outline-dark" size={'sm'} className='add-btn' onClick={
            ()=>{
              billsItems.push({
                "customerId": custId,
                "pharmacyId": pharma.id,
                "name": "",
                "drugId": 1,
                "batch": "Batch",
                "quantity": '',
                "rate": '',
                "discountPercentage": '',
                "discountValue": '',
                "tax": '',
                "total": ''
              });
              setBillItems([...billsItems]);
            }
          }>
            <img src={addVisit} alt="add prescription" />
            &nbsp;
            Add new record
          </Button>
        </div>
        <div className='footer-row'>
            <div>
              {/* <Visit_header_label>Patient name</Visit_header_label> */}
              <Visit_info_label>Payment</Visit_info_label>
            </div>
            <div className='total-bill'>
              <Visit_header_label>Mode of payment</Visit_header_label>
              <Visit_info_label>
              <Form.Control
                  as="select"
                  style={{    height: '32px',
                    width: '150px',
                    padding: '0',
                  fontSize: '13px'}}
                  value={payment.paymentMode}
                  onChange={(e) =>{
                    payment.paymentMode = e.target.value;
                    setPayment({...payment});
                  }}>
                  {
                    billsPage.constants.paymentMethods.map((item: any, index)=>
                      <option className="option-value" value={item}>
                        {item}
                      </option>
                    )
                  }
                </Form.Control>
              </Visit_info_label>
            </div>
            <div>
              <Visit_header_label>Pay now</Visit_header_label>
              <Visit_info_label>
              <Numberinput_v2
                  width={'150px'}
                  min={0}
                  max={10000000}
                  label={""}
                  property={""}
                  placeHolder={""}
                  value={payment.amount}
                  noLabel={true}
                  updateCb={(value: any) => {
                    payment.amount = value;
                    setPayment({...payment});
                  }}
                />

              </Visit_info_label>

            </div>
            <div>
            <Visit_header_label></Visit_header_label>
            <br/>
            <Visit_info_label>
              <Cancel_btn_sm  className={"btn btn-outline-dark btn-sm"}>Cancel</Cancel_btn_sm>
              &nbsp;<Save_btn_sm className={"btn btn-dark btn-sm"} style={{width: '200px'}}>Proceed to payment</Save_btn_sm>
            </Visit_info_label>
            </div>
        </div>
      </div>
    </>
  
};

export default withRouter(PharmaBilling);
