import * as React from "react";
import uniqVisitorsImage from "../../../../images/reports/visitors.svg";
import { Label_form, Label_md } from "../../../../style-components/Labels";
import ReportsTable from "../../../shared/Reportstable";
import { API } from "../../../../utils/Api";
import _ from 'lodash';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from "moment";
import { constants } from "../../../../constants";


const {manage_visits: {ManageRecords}} = constants;


export const Diagnosisreport = (props: any) => {
  const {autoRefresh, patients, textFilters, selectedFilter, centerId, startDate, endDate} = props;
  const [loading, setLoading] = React.useState(true);
  const [reports, setReport] = React.useState([] as any);
  const [visitCount, setCount] = React.useState({uniqueCount: 0} as any);
  const skeletonRows = new Array(10).fill(0);



  const columns: any = [{
    dataField: 'id',
    text: 'UHID',
    sort: true
  }, {
    dataField: 'name',
    text: 'Name',
    sort: true
  }, {
    dataField: 'timeAndDate',
    text: 'Visit Date',
    sort: true,
  },
  {
    dataField: 'age',
    text: 'Age',
    // sort: true,
  },
  {
    dataField: 'gender',
    text: 'Gender',
    // sort: true,
  },
  {
    dataField: 'diagnosis',
    text: 'Diagnosis',
    sort: true,
  },
  
];

  React.useEffect(()=>{
    setLoading(true);
    (async ()=>{
      try {
        const results: any = []; 
        let uniqueCount = 0;
        const patientData: any = [];
        // if (patients.length) {
        const response = await API.get(`/visits/filter/${centerId}/${startDate.toISOString()}/${endDate.toISOString()}`);
        const patientIds =  _.map(response.data.Items, 'patientId');
        await Promise.all(patientIds.map(async (pid: any, index: number) => {
          if (pid) {
            const patient = await API.get(`/patients/patient/${centerId}/${pid}`);
            patientData.push(patient.data);
          }
        }));
        const hash: any = [];
        response.data.Items.forEach((visit: any, index: number) => {
          const patient = patientData.find((patient: any) => patient.patientId === visit.patientId);
          if (patient) {  
            results.push({
              id: patient.uhid,
              name: patient.fullName,
              timeAndDate: moment(visit.creation_date).format('DD-MM-YYYY'),
              age: patient.age && patient.age.years || patient.age.months  ?   patient.age.years + ' Y ' + (patient.age.months ?  patient.age.months + ' M' : '') : '-',
              gender: patient.gender.toString() === 'male' ? 'Male' : 'Female',
              phone: patient.phone || '-',
              diagnosis: visit.diagnosis,
            });

            //  To calculate uniq visits
            if (!hash[patient.patientId]) {
              hash[patient.patientId] = 1;
              uniqueCount +=1;
            }              
          }
        });
        // }

        // (ReactDOM as any).unstable_batchedUpdates(() => {
          setCount({
            uniqueCount,
  
          });
          setReport(results);
          setLoading(false);
        // });
      } catch(e) {
        console.log(e);
        setReport([]);
        setLoading(false);
      }
     

    })()
  }, [startDate, endDate, selectedFilter, autoRefresh]);

 

  if (loading) {
    return <>
      <Skeleton variant="rect"  height={80} />
      <br/>
      {
        skeletonRows.map((value: number, index: number) => <Skeleton animation="wave"  key={index}/>)
      }
     
    </>
  }

  if (!reports.length) {
    return <div className={'text-center no-patient-info'}> <Label_md >No patients available for selected date(s)</Label_md></div>
  }

  return <>
    
      <div className={'report-stats'} style={{background: '#FFF', justifyContent: 'flex-start'}}>
        {/* <div><h5>Report</h5></div> */}
        <div className={'report-numbers'}>
          <div><img alt='' src={uniqVisitorsImage}/></div>
          <div className={'stat'}>
            <Label_form># of visits</Label_form>
            <p>{reports.length}</p>
          </div>
        </div>
        <div className={'report-numbers'}>
        <div><img alt='' src={uniqVisitorsImage}/></div>
          <div className={'stat'}>
            <Label_form>Unique patients</Label_form>
            <p>{visitCount.uniqueCount}</p>
          </div>
        </div>
    </div>
    <div className={'reports-main-container'}>

      <div className={'reports-table-container'}>
       
        <ReportsTable columns={columns} list={reports}></ReportsTable>
      </div>
    </div>
    </>
  }
