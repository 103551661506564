import { BehaviorSubject } from "rxjs";
import axios from "axios";

// import { bwellServices } from "@bwell/common";
import { constants } from "../constants";
import { IHospitalList } from "../types/add-clinic";
import {API} from "../utils/Api";
import {hospitalBasicDetails} from "../constants/add-clinic";

const { add_clinic_page, apiEndPoint } = constants;

export const initialState: any = {
  authenticated: !constants.isProd,
  listOfHospitals: [],
  clinic: hospitalBasicDetails,
  addclinic: false,
  loading: false
};

const subject$ = new BehaviorSubject(initialState);

const getStateObservable = () => {
  return subject$;
};

const updateState = (updateObj: any) => {
  subject$.next(updateObj);
};

const serviceFn = {
  setPassword: async (user: any, phone: string, password: string) => {
    // Coz for us user name and phone number are the same
    // return bwellServices.auth.setPassword(user, phone, password);
  },
  addClinic: (clinic: any) => {
    // return axios.get('https://oo7p0063kd.execute-api.ap-south-1.amazonaws.com/dev/medicalcenter/8cbce1a3-90f3-4e27-b27c-67b3310f8971')
    return API.post(`/medicalCenter`, clinic);
  },
  verifyPassword: (password: string) => {
    return API.post(`/medicalCenter/verify-password`, {password});
  },
  getListOfClinics: () => {
    return API.get(`/medicalcenter`);
  }
};

const reducerFn = async (updateEvent: string, params?: any) => {
  const state = subject$.getValue();
  const { add_clinic_page } = constants;
  const { events } = add_clinic_page;

  // Possible UI screens

  // POSSIBLE USER EVENTS
  const { UPDATE_CLINIC, VERIFY_PASSWORD, GET_ALL_HOSPITALS, ADD_NEW_CLINIC, GO_BACK_TO_LIST } = events;

  // POSSIBLE ERROR STATES

  updateState({ ...state, ...{ loading: true, userErrorState: null } });
  switch (updateEvent) {
    case GO_BACK_TO_LIST: {
      updateState({ ...state, ...{ loading: false, addclinic: false, clinic: hospitalBasicDetails } });
      break;
    }
    case ADD_NEW_CLINIC:
    case UPDATE_CLINIC: {
      try {

      
        const response = await serviceFn.addClinic(Object.assign({}, params!.clinic));
        // const list = await serviceFn.getListOfClinics();

        updateState({ ...state, ...{ loading: false, userErrorState: null, clinic:  response.data} });
        alert('Updated!');
      } catch (e) {
        alert('Error occured');
        updateState({ ...state, ...{ loading: false}});
        console.log(e);
      }
      break;
    }
    case GET_ALL_HOSPITALS: {
      try {
        const response = await serviceFn.getListOfClinics();

        updateState({ ...state, ...{ loading: false, userErrorState: null, listOfHospitals:  response.data } });
      } catch(e) {
        updateState({ ...state, ...{ loading: false, userErrorState: null } });
      }
      break;
    }
    case VERIFY_PASSWORD: {
      updateState(Object.assign({},state, { loading: true }));
      try {
        const response = await serviceFn.verifyPassword(params!.password);
        updateState(Object.assign({}, state, {authenticated: response.data.success, loading: false}));
      } catch(e) {
        console.log(e);
        alert('Enter valid password');
        updateState(Object.assign({},state, { loading: false }));
      }
      break;
    }

  }
};

export const addClinicPage = {
  constants: add_clinic_page,
  initialState,
  updateState,
  getStateObservable,
  reducerFn,
};
