import { RecordsMenuitems, RecordsIPMenuitems } from "./RecordsMenuitems";
import { ManageRecords } from "./ManageRecords";
import { ManageVisits } from "./ManageVisits";

export const Managevisits = {
  RecordsMenuitems,
  RecordsIPMenuitems,
  ManageRecords,
  ManageVisits,
};
