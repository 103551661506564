import * as React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { stateManagers } from "../statemanager";
import { Getphonenumber } from "../components/authentication/Getphonenumber";
import { Getotp } from "../components/authentication/Getotp";
import RegistrationLayout from "../layout/Registration";

export const Registration: React.FunctionComponent = (props) => {
  const history = useHistory();

  const [state, setState] = useState({ currentState: "" } as any);
  const { registerPage } = stateManagers;
  const {
    constants,
    reducerFn,
    getStateObservable,
    updateState,
  } = registerPage;
  const { context, show, events, errors, labels } = constants;
  const { currentState } = state;

  let UseComponent = null;

  useEffect(() => {
    getStateObservable().subscribe((updatedState) => {
      if (Object.keys(updatedState).length) {
        setState(updatedState);
      }
    });

    return () => getStateObservable().unsubscribe();
  }, []);

  if (!currentState) {
    return null;
  }

  switch (currentState) {
    case show.INPUT_PHONE_NUMBER: {
      UseComponent = null;
      // <Getphonenumber parentState={state} eventHandler={reducerFn} updateCb={(value: string) => {
      //                 updateState({...state, ...{phone: value}});
      //               }} userEvents = {events} errorStates={errors} pageLabels={labels} appContext={context}/>;
      break;
    }
    case show.GET_OTP: {
      UseComponent = null;
      // <Getotp parentState={state} eventHandler={reducerFn} updateCb={(value: string) => {
      //                 updateState({...state, ...{phone: value}});
      //               }} userEvents = {events} errorStates={errors} pageLabels={labels} appContext={context}/>;
      break;
    }
    case show.OTP_VERIFICATION_DONE: {
      UseComponent = null;
      break;
    }
    case show.REGISTER_CLINIC: {
      history.push(`/add-clinic/${state.phone}`);
      break;
    }
  }

  return <RegistrationLayout children={UseComponent} />;
};

export default Registration;
