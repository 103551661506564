import * as React from "react";
import { useState, useEffect } from "react";
import { stateManagers } from "../statemanager";
import RegistrationLayout from "../layout/Registration";
import { Getphonenumber } from "../components/authentication/Getphonenumber";
import { Setnewpassword } from "../components/authentication/Setnewpassword";
import { Getotp } from "../components/authentication/Getotp";

export const Login: React.FunctionComponent = (props) => {
  const [state, setState] = useState({ currentState: "" } as any);
  const { loginPage } = stateManagers;
  const { constants, reducerFn, getStateObservable, updateState } = loginPage;
  const { context, events, forgotPasswordLabels, errors, labels } = constants;

  let UseComponent = (
    <>
      <Getphonenumber
        parentState={state}
        eventHandler={reducerFn}
        updatePassword={(value: string) => {
          updateState({ ...state, ...{ password: value } });
        }}
        updateCb={(value: string) => {
          updateState({ ...state, ...{ phone: value } });
        }}
        userEvents={events}
        errorStates={errors}
        pageLabels={state.showForgotInput ? forgotPasswordLabels : labels}
        appContext={context}
      />
    </>
  );

  if (state.newPassword) {
    UseComponent = (
      <Setnewpassword
        parentState={state}
        eventHandler={reducerFn}
        updatePassword={(value: string) => {
          updateState({ ...state, ...{ password: value } });
        }}
        updateCb={(value: string) => {
          updateState({ ...state, ...{ phone: value } });
        }}
        userEvents={events}
        errorStates={errors}
        pageLabels={labels}
        appContext={context}
      />
    );
  } else if (state.getToken) {
    UseComponent = (
      <Getotp
        parentState={state}
        eventHandler={reducerFn}
        userEvents={events}
        errorStates={errors}
        pageLabels={labels}
        updatePassword={(value: string) => {
          updateState({ ...state, ...{ password: value } });
        }}
        updateCb={(value: string) => {
          updateState({ ...state, ...{ phone: value } });
        }}
      />
    );
  }

  useEffect(() => {
    const subscription = getStateObservable().subscribe((updatedState) => {
      if (Object.keys(updatedState).length) {
        setState(updatedState);
      }
    });

    return () => subscription.unsubscribe();
  }, [getStateObservable]);

  return <RegistrationLayout children={UseComponent} />;
};

export default Login;
