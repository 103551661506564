import { BehaviorSubject } from "rxjs";
import axios from "axios";

// import { bwellServices } from "@bwell/common";
import { constants } from "../constants";
import {API} from "../utils/Api";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";

const { BillsConstants, apiEndPoint } = constants;

const showToast = (message: string, error?: boolean) =>
  toast[error ? "error" : "success"](message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });

export const initialState: any = {
  initialLoading: false,
  loading: false,
  selectedPatient: {} as any,
  billIndex: -1,
  listOfBills: [],
};

const showConfirm = (callback: Function) => {
  confirmAlert({
    title: 'Are you sure ?',
    message: ``,
    buttons: [
      {label: 'Cancel', onClick: () => {return;} , className: 'cancel-button'},
      {label: 'Confirm', className:'confirm-button', onClick: () => {
          callback();
        }},]
  });
}

const subject$ = new BehaviorSubject(initialState);

const getStateObservable = () => {
  return subject$;
};

const updateState = (updateObj: any) => {
  subject$.next(updateObj);
};

const serviceFn = {
  getBills: (patientId: string, medicalcenterId: string) => {
    return API.get(`/bills/patient/${medicalcenterId}-${patientId}`);
  },
  addBill:(data: any) => {
    return API.post(`/bills`, data);
  },
  updateBill: (data: any) => {
    return API.post(`/bills`, data);
  },

};

const reducerFns = async (updateEvent: string, params?: { [key: string]: any }) => {
  const state = subject$.getValue();
  const { add_clinic_page } = constants;
  const { events } = BillsConstants;

  // Possible UI screens

  // POSSIBLE USER EVENTS
  const {GET_BILLS, SAVE_NEW_BILL, SET_ACTIVE_BILL, DELETE_BILL  } = events;

  // POSSIBLE ERROR STATES


  switch (updateEvent) {
    case DELETE_BILL: {
        // showConfirm(async () => {
        try {
            updateState({ ...state, ...{ loading: true, userErrorState: null,  billIndex: -1 } });
            const saveBill = await serviceFn.addBill(Object.assign(params!.bill, {patientId: state.selectedPatient.patientId, medicalCenterId: state.selectedPatient.medicalCenterId}));
            const bills = await serviceFn.getBills(state.selectedPatient.patientId, state.selectedPatient.medicalCenterId);
            updateState({ ...state, ...{ loading: false, userErrorState: null,  listOfBills: bills.data, billIndex: -1 } });
            showToast('Entry deleted');

          } catch (e) {
            showToast('Unable to save bill', true);
            console.log(e);
          }
          // });

      break;
      }
    case GET_BILLS: {
      const { patient } = params!;
      try {
        updateState(Object.assign({}, state, { initialLoading: true }));

        const bills = await serviceFn.getBills(patient.patientId, patient.medicalCenterId);
        // const labtestsList = await serviceFn.getLabTestsList();

        updateState(Object.assign({}, state, { initialLoading: false, selectedPatient: patient, listOfBills: bills.data }));
      } catch(e) {
        updateState(Object.assign({}, state, { initialLoading: false, selectedPatient: patient, listOfBills: []}));
        console.log(e);

      }

      break;
    }
    case SET_ACTIVE_BILL: {
      if (state.billIndex === params!.index) {
        updateState(Object.assign({}, state, { billIndex: -1 }));
        return;
      }

      updateState(Object.assign({}, state, { billIndex: params!.index }));
      break;
    }
    case SAVE_NEW_BILL: {
      try {
        updateState({ ...state, ...{ loading: true, userErrorState: null,  billIndex: -1 } });
        const saveBill = await serviceFn.addBill(Object.assign(params!.bill, {patientId: state.selectedPatient.patientId, medicalCenterId: state.selectedPatient.medicalCenterId}));
        const bills = await serviceFn.getBills(state.selectedPatient.patientId, state.selectedPatient.medicalCenterId);
        updateState({ ...state, ...{ loading: false, userErrorState: null,  listOfBills: bills.data, billIndex: -1 } });
        showToast('Bill saved');

      } catch (e) {
        showToast('Unable to save bill', true);
        console.log(e);
      }

      break;
    }
  }
};

export const billsPage = {
  constants: BillsConstants,
  initialState,
  updateState,
  getStateObservable,
  reducerFns,
};
