import {IBiochemistry, IHaemogram, ILipidprofile, ISamplesObtained, IUrinaryTest} from "../../types/checkup";
import _ from "lodash";
import {ExaminationDefault} from "./examinationDefaults";
import {generaltestDefaults} from "./Generaltest";
import {pftDefaults} from "./pftDefaults";
import {HaemogramDefaults} from "./Haemogram";
import {BiochemistryDefaults} from "./Biochemistry";
import {HormonesDefaults} from "./Hormones";
import {BloodglucoseDefaults} from "./BloodGlucose";
import {CompelteUrineAnalysisDefaults} from "./Completeurineanalysis";



export const Checkupstepevents = {
  context: 'manage-health-checkup-step',
};

const Checkupdefaults = {
  sampleCollection: {
    completed: false,
  } as ISamplesObtained,
  sampleCollectionBB: {
    completed: false,
  } as ISamplesObtained,
  steps: [
    {
      name: 'Vital Parameters',
      printParam: 'assessment',
      mode: 'EDIT',
      completed: false,
      records: [{
        id: 'initial-assessment',
        title: 'Assessment',
        fields:  {
          height: '',
          weight: '',
          BMI: '',
          temperature: '',
          bp: { high: '', low: '' },
          tempScale: 'celsius',
          saturation: '',
          painAssessment: '1',
          heartRate: '',
        }
      }]
    },
    ExaminationDefault(),
    HaemogramDefaults(),
    BiochemistryDefaults(),
    HormonesDefaults(),
    BloodglucoseDefaults(),
    CompelteUrineAnalysisDefaults(),
    generaltestDefaults(),
    pftDefaults(),
    {
      name: 'Echocardiogram',
      mode: 'EDIT',
      completed: false,
      records: [{
        id: 'echocardiogram',
        title: 'Echocardiogram',
        fields:  {
          impression: '',
        }
      }]
    },
    {
      name: 'MRI-Brain',
      mode: 'EDIT',
      completed: false,
      records: [{
        id: 'mri-brain',
        title: 'MRI-Brain',
        fields:  {
          impression: '',
        }
      }]
    },
    {
      name: 'Diagnosis & Advised Medication',
      mode: 'EDIT',
      completed: false,
      records: [{
        id: 'diagnosis-advised-medication',
        title: 'Diagnosis & Advised Medication',
        fields:  {
          diagnosis: '',
          prescription: '',
          generalAdvice: '',
          dietCounselling: '',
          review: '',
        }
      }]
    },
    {
      name: 'Notes',
      mode: 'EDIT',
      completed: false,
      records: [{
        id: 'notes',
        title: 'Notes',
        fields:  [{
          by: '',
          notes: '',
        }]
      }]
    },

  ]
}

export const getCheckupDefaults = () => _.cloneDeep(Checkupdefaults);
