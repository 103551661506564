import React from 'react';
import {Slide} from "@material-ui/core";
import {Tariffstypes} from "../../constants/add-clinic";
import {Col, Form} from "react-bootstrap";
import {Textbox} from "../shared/Inputcomponents/Textbox";
import Managedoctors from "./components/Managedoctors";
import Managetariffs from "./components/Managetariffs";


export default function Configurehospital(props: any) {
  const {clinic} = props;

  return <div className="add-clinic-container">
    <Slide in={true} direction={'down'}>
      <div className={'patient-group'}>
        <h5 className="clinic-info">Add Doctors</h5>
        <Managedoctors clinic={clinic}></Managedoctors>
      </div>
    </Slide>
    <Slide in={true} direction={'down'}>
      <div className={'patient-group'}>
        <h5 className="clinic-info">Add Tariffs</h5>
        <Managetariffs clinic={clinic}></Managetariffs>
      </div>
    </Slide>
  </div>

}
