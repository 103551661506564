import * as React from "react";

import { InputGroup, Form} from "react-bootstrap";
import { Label_form, Label_md } from "../../style-components/Labels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    faSearch
  } from "@fortawesome/free-solid-svg-icons";
import { API } from "../../utils/Api";
import { Appcontext } from "../../Appcontextprovider";
import { toast } from "react-toastify";
import { distinctUntilChanged, debounceTime } from "rxjs/operators";
import { Subject } from "rxjs";
const BillIcon = <FontAwesomeIcon icon={faSearch} color="#FFF" />;

export const Searchpatients = (props: any) => {
    const {setPatients, patients} = props;
    const {centerId} = React.useContext(Appcontext);
    const [searchKey, setSearchKey] =  React.useState('');
    const [searching, setSearching] =   React.useState(false);
    const [onSearch$] = React.useState(()=>new Subject());

    const searchPatients =  async (q=searchKey) => {
   
        try {
            setSearching(true);
            const response: any = await API.get(`/patients/search/${centerId}/${q}`);
            setSearching(false);
            setPatients({list: response.data.Items || [], searchResult: true, searchKey: q});
        } catch(e) {
            console.log(e);
            toast.error('Unable to get patient(s)', {position: 'top-center', hideProgressBar: true});
            setSearching(false);
            setPatients({list:  [], searchResult: false, searchKey: ''});
        } 
       
    }   
    

    React.useEffect(() => {
       
        const searchSubscription = onSearch$.pipe(
          debounceTime(400),
          distinctUntilChanged(),
        ).subscribe((q: any ) => {
          if (q.length > 2) {
            searchPatients(q);
          } else if(!q.length) {
            setPatients({list:  [], searchResult: false, searchKey: ''});
          }
    
        });
        return () => {   searchSubscription.unsubscribe(); }
    
      }, []);

    return <div className='pharmacy-search-patient'  onKeyDown={(e: any)=>{
        if (e.keyCode === 13 && searchKey.length > 2) {
            searchPatients();
          }
        }}>
           <Label_md>Find a patient for billing</Label_md>
           <br/>
           <br/>
            <div className='row'>
                <div className='col'>
                <InputGroup className="mb-3">
                    <Form.Control
                        className={"left-patient-search"}
                        placeholder={"Search with Patient name, phone number and Uhid" }
                        value={searchKey}
                        onChange={(event) => {
                            setSearchKey(event.target.value);
                            onSearch$.next(event.target.value);
                        }}
                        autoFocus={true}
                    />
                <InputGroup.Append>
                    <InputGroup.Text id="basic-addon2" onClick={() => {searchPatients()}} className={searchKey.length > 2 ? '' : 'disable-div'}>
                       {searching ? <>Searching ... <span className={"spinner-border spinner-border-sm"} style={{color: '#FFF'}}></span></> :  <>{BillIcon} Search</>}
                    </InputGroup.Text>
                </InputGroup.Append>
             </InputGroup>
              </div>
            
            </div>
    </div>
}