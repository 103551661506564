import * as React from "react";
import { Form, Button } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { IRegisterPageState } from "../../types/registration";
import { Showtoastermsg } from "../../utils/Showtoastermsg";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { Label_form } from "../../style-components/Labels";
import { Plain_column } from "../../style-components/Divs";
import { ILoginPageState } from "../../types/login";

interface IGetphonenumberProps {
  parentState: any;
  updateCb: any;
  pageLabels: { [key: string]: string };
  eventHandler: any;
  errorStates: TEventError;
  userEvents: { [key: string]: string };
  appContext: string;
  updatePassword?: any;
}

export const Getphonenumber = (props: IGetphonenumberProps) => {
  const { addToast } = useToasts();

  const {
    eventHandler,
    parentState,
    pageLabels,
    errorStates,
    userEvents,
    updateCb,
    updatePassword,
  } = props;
  const {
    phone,
    password,
    showForgotBtn,
    userErrorState,
    showForgotInput,
  } = parentState;
  const {
    INVALID_PHONE_NUMBER,
    USER_ALREADY_REGISTERED,
    INVALID_USER_NAME_PASSWORD,
  } = errorStates;

  const loaderClass = parentState.loading ? "app-loading" : "placeholder-class";

  const ForgotpasswordContext = () => {
    return (
      <>
        {userErrorState ? (
          <div className="error-info">{userErrorState.display}</div>
        ) : (
          <div></div>
        )}
        <div className={"btn-container" + (!phone ? " disable-div" : "")}>
          <Button
            variant="dark"
            onClick={() => {
              if (!phone) {
                addToast(
                  Showtoastermsg(
                    INVALID_USER_NAME_PASSWORD.error,
                    INVALID_USER_NAME_PASSWORD.display
                  ),
                  { appearance: "error", autoDismiss: true }
                );
                return;
              }

              eventHandler(userEvents.RESET_PASSWORD);
            }}
          >
            {" "}
            {parentState.loading ? (
              <span className={"spinner-border spinner-border-sm"}></span>
            ) : (
              "Get verification code"
            )}
          </Button>
        </div>
        <div className="link-button-container">
          <Button
            variant="link"
            onClick={() => {
              eventHandler(userEvents.GO_TO_LOGIN);
            }}
          >
            Go to login
          </Button>
        </div>
      </>
    );
  };

  const LoginContext = () => {

    const Login = ()=>{
      if (!phone) {
        addToast(
          Showtoastermsg(
            INVALID_USER_NAME_PASSWORD.error,
            INVALID_USER_NAME_PASSWORD.display
          ),
          { appearance: "error", autoDismiss: true }
        );
        return;
      }

      eventHandler(userEvents.LOGIN_USER);
    };

    return (
      <React.Fragment>
        <form onKeyDown={(e: any)=>  {
          if (e.keyCode === 13) {
            e.preventDefault();
            Login();
          }
        } }>

        <Label_form>{" Password "}</Label_form>
        <Form.Control
          style={{
            fontSize: "13px",
            color: "#5F5F5F",
            height: "32px",
            borderRadius: "4px",
            border: "1px solid #AAAAAA",
          }}
          // className={invalid ? "invalid-text-box" : ""}
          type="password"
          placeholder={"Enter Password"}
          value={password}
          onChange={(e) => {
            updatePassword(e.target.value);
          }}
        />
        <br />
        {userErrorState ? (
          <div className="error-info">{userErrorState.display}</div>
        ) : (
          <div></div>
        )}
        <div
          className={
            "btn-container" + (!password || !phone ? " disable-div" : "")
          }
        >
          <Button
            variant="dark"
            onClick={() => {
              Login();
            }}
          >
            {" "}
            {parentState.loading ? (
              <span className={"spinner-border spinner-border-sm"}></span>
            ) : (
              "Login"
            )}
          </Button>
        </div>
        {showForgotBtn ? (
          <div className="link-button-container">
            <Button
              variant="link"
              onClick={() => {
                eventHandler(userEvents.SHOW_FORGOT_PASSWORD_BUTTON);
              }}
            >
              Forgot password ?
            </Button>
          </div>
        ) : null}
        </form>
      </React.Fragment>
    );
  };

  return (
    <div className={loaderClass}>

      <p>{pageLabels.title}</p>
      <small>{pageLabels.subtitle}</small>
      <br />
      <br />
      <Label_form>User Name</Label_form>
      <Form.Control
        style={{
          fontSize: "13px",
          color: "#5F5F5F",
          height: "32px",
          borderRadius: "4px",
          border: "1px solid #AAAAAA",
        }}
        // className={invalid ? "invalid-text-box" : ""}
        type="text"
        placeholder={"Enter User Name"}
        value={phone}
        autoFocus={true}
        onChange={(e) => {
          updateCb(e.target.value);
        }}
      />
      <br />
      {showForgotInput ? ForgotpasswordContext() : LoginContext()}
    </div>
  );
};
