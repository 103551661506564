import {IHaemogram} from "../../types/checkup";

export const CompelteUrineAnalysisDefaults = () => {
  return {
    name: 'Complete Urine Analysis',
    mode: 'EDIT',
    completed: false,
    records: [{
      id: 'complete-urine-analysis',
      title: 'Urine Function Test',
      fields: [
        {
         title: 'Urine Function Test',
        fields: [{
          name: 'Color',
          type: 'dropdown',
          result: '',
          unit: '',
          range: {
          }
        },
          {
            name: 'Transparency',
            result: '',
            type: 'text',
            unit: '',
            range: {

            }
          },
          {
            name: 'Albumin',
            type: 'text',
            result: '',
            unit: '',
            range: {

            }
          },
          {
            name: 'Sugar',
            type: 'text',
            result: '',
            unit: '',
            range: {

            }
          },
          {
            name: 'Ketones',
            type: 'text',
            result: '',
            unit: '',
            range: {

            }
          },
          // {
          //   name: 'Ketones',
          //   result: '',
          //   unit: '',
          //   range: {
          //
          //   }
          // },
          {
            name: 'Bilirubin',
            type: 'text',
            result: '',
            unit: '',
            range: {

            }
          },
          {
            name: 'Urobilinogen',
            type: 'text',
            result: '',
            unit: '',
            range: {

            }
          },
          {
            name: 'Nitrate',
            type: 'text',
            result: '',
            unit: '',
            range: {

            }
          },
          {
            name: 'Blood',
            type: 'text',
            result: '',
            unit: '',
            range: {

            }
          },
          {
            name: 'PH',
            type: 'text',
            result: '',
            unit: '',
            range: {

            }
          },
          {
            name: 'Specific Gravity',
            type: 'text',
            result: '',
            unit: '',
            range: {

            }
          },
      ]
    },
        {
          title: 'Microscopic Examination',
          fields: [{
            name: 'Puscells',
            result: '',
            type: 'text',
            unit: '',
            range: {

            }
          },
            {
              name: 'Epithelial Cells',
              result: '',
              type: 'text',
              unit: '',
              range: {

              }
            },
            {
              name: 'RBC’s',
              result: '',
              type: 'text',
              unit: '',
              range: {

              }
            },
            {
              name: 'Yeast Cells',
              type: 'text',
              result: '',
              unit: '',
              range: {

              }
            },
            {
              name: 'Cast',
              type: 'text',
              result: '',
              unit: '',
              range: {

              }
            },
            {
              name: 'Crystals',
              type: 'text',
              result: '',
              unit: '',
              range: {

              }
            },
            {
              name: 'Others',
              type: 'text',
              result: '',
              unit: '',
              range: {

              }
            },

          ]
        }
    ]

    }

    ]
  };
}
