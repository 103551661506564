import React, {lazy, Suspense, useContext} from "react";
import "react-toastify/dist/ReactToastify.css";
import { Amplify } from "aws-amplify";


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastProvider } from "react-toast-notifications";

// Styles


import "./App.scss";
import "./css/checkup.scss";
import "./css/patients.scss";
import "./css/reports.scss";
import "./css/settings.scss";
import "./css/patients/visits/prescriptions-edit.scss";
import "./ipad.scss";
import "./loader.scss";
import "./css/pharmacy.scss"
import "react-bootstrap-typeahead/css/Typeahead.css";

// import 'react-image-lightbox/style.css';

// Pages

import Registration from "./pages/Registration";
import Login from "./pages/Login";
import AddPatient from "./pages/Addpatient";
import { Settings } from "./pages/Settings";
import Reports from "./pages/Reports";
import { ToastContainer } from "react-toastify";
import Appcontextprovider, {Appcontext} from "./Appcontextprovider";
import Logout from "./pages/Logout";
import {constants} from "./constants";
import {Managehospitals} from "./pages/Managehospitals";
import Pharmacy from "./pages/Pharmacy";
import { PharmaBilling } from "./pages/Pharmacy/PharmaBilling";


const AddpatientPage = lazy(() => import("./pages/Addpatient"));
const Patients = lazy(() => import("./pages/Patients"));
const LoginPage =  lazy(() => import("./pages/Login"));

function App() {

  if (window.location.href.indexOf('app.bewelldigital.com') > -1) {
      Amplify.configure(constants.prodConfig);
  }else if (window.location.href.indexOf('harshitha.bewelldigital.com') > -1 ) {
    Amplify.configure(constants.harshithaConfig);
  }  else if (window.location.href.indexOf('localhost') > -1) {
     Amplify.configure(constants.localConfig);
  } else {
      Amplify.configure(constants.betaConfig);
  }



  return (
    <Appcontextprovider>
    <ToastProvider>
      <ToastContainer />
      <Router>
        { <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route path="/registration" component={Registration} />
            <Route path="/login" component={LoginPage} />
            <Route path="/logout" component={Logout} />
            <Route path="/patients/:id" component={Patients} />
            <Route path="/analytics/:id" component={Reports} />
            <Route path="/settings/:id" component={Settings} />
            <Route path="/add-patient/:id" component={AddpatientPage} />
            <Route path="/manage-clinics" component={Managehospitals} />
            {/* Pharmacy */}
            <Route path="/pharmacy/:id" component={Pharmacy}></Route>
            <Route path="/pharmacy-billing/:custId" component={PharmaBilling} />
          
            <Route component={Login} />
          </Switch>
        </Suspense>}
      </Router>
    </ToastProvider>
    </Appcontextprovider>
  );
}

export default App;
