export const surgicalHistory = {
  title: "Surgical History",
  subtitle: "List out all the surgeries that the patient has underwent",
  id: "surgical-history",
  // items: [
  //   {
  //     title: 'Surgical History',
  //     id: 'surgery-id',
  //     type: 'surgery',
  //   }
  // ]
};
