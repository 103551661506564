import { Auth } from "aws-amplify";

let loginAttempts = 0;

export const Handleauth = {
  login: async (userName: string, password: string, successCb?: Function) => {
    try {
      return await Auth.signIn(userName, password);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  setNewPassword: async (userName: string, newPassword: string) => {
    try {
      return await Auth.completeNewPassword(userName, newPassword);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  forgotPassword: async (userName: string) => {
    try {
      return await Auth.forgotPassword(userName);
    } catch (e) {
      throw e;
    }
  },
  updatePassword: async (
    userName: string,
    code: string,
    newPassword: string
  ) => {
    try {
      return await Auth.forgotPasswordSubmit(userName, code, newPassword);
    } catch (e) {
      throw e;
    }
  },
  logout: async (
  ) => {
    try {
      return await Auth.signOut();
    } catch (e) {
      throw e;
    }
  },
};
