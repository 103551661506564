
import { BehaviorSubject } from 'rxjs';
// import {bwellServices} from '@bwell/common';
import {constants} from "../constants";
import {IRegisterPageState} from "../types/registration";
const {register_page} = constants;

export const initialState: IRegisterPageState = {
    currentState: register_page.show.INPUT_PHONE_NUMBER,
    loading: false,
    phone: '',
}

const subject$ = new BehaviorSubject(initialState);

const getStateObservable = () => {
    return subject$;
};

const updateState = (updateObj: IRegisterPageState) => {
    subject$.next(updateObj);
};

const serviceFn = {
    signUp: async (phone: string) =>{
        // Coz for us user name and phone number are the same
        // return bwellServices.auth.signUp(phone, phone);
    },
    resendOtp: async (phone: string) =>{
      // Coz for us user name and phone number are the same
      // return bwellServices.auth.resendOtp(phone);
    },
    confirmSignUp: async (phone: string, otp: string) => {
      // return bwellServices.auth.confirmSignUp(phone, otp);
    },
};

const reducerFn = (updateEvent: string, params?: {[key: string]: string}) => {
    const state = subject$.getValue();
    const {register_page} = constants;
    const {show, events, errors} = register_page;

    // Possible UI screens
    const {INPUT_PHONE_NUMBER, GET_OTP, REGISTER_CLINIC} = show;

    // POSSIBLE USER EVENTS
    const {ENTER_PHONE_NUMBER, RESEND_OTP, VERIFY_PHONE_NUMBER, VERIFY_OTP} = events;

    // POSSIBLE ERROR STATES
    const {INVALID_OTP, USER_ALREADY_REGISTERED, UNABLE_TO_SEND_OTP} = errors;

    updateState({...state, ...{loading: true, userErrorState: null}});
    switch(updateEvent) {
      case ENTER_PHONE_NUMBER: {
        updateState({...state, ...{loading: false, phone:'', currentState: INPUT_PHONE_NUMBER, userErrorState: null}});
        break;
      }
      case VERIFY_PHONE_NUMBER : {
            (async () => {
                try {
                    const user = await serviceFn.signUp(state.phone!);
                    localStorage.setItem('user', JSON.stringify(user));
                    updateState({...state, ...{loading: false, currentState: GET_OTP, user, userErrorState: null}});
                } catch(e) {
                    if (e.code && e.code === USER_ALREADY_REGISTERED.error) {
                      updateState(Object.assign(state, {loading: false, userErrorState: USER_ALREADY_REGISTERED}));
                    }
                }
            })();
            break;
      }
      case RESEND_OTP: {
          (async () => {
            try {
              const user = await serviceFn.resendOtp(state.phone!);
              updateState({...state, ...{loading: false, currentState: GET_OTP, user, userErrorState: null}});
            } catch(e) {
              updateState(Object.assign(state, {loading: false, userErrorState: {...UNABLE_TO_SEND_OTP, ...{display: e.message ? e.message : UNABLE_TO_SEND_OTP.display}}}));
            }
          })();


          break;
      }
      case VERIFY_OTP: {
        (async () => {
          try {
            const user = await serviceFn.confirmSignUp(state.phone!, params!.otp!);

            updateState({...state, ...{loading: false, currentState: REGISTER_CLINIC, user, userErrorState: null}});
          } catch(e) {
            updateState(Object.assign(state, {loading: false, userErrorState: {...INVALID_OTP, ...{display: e.message ? e.message : INVALID_OTP.display}}}));
          }
        })();


        break;
      }


    }
};


export const registerPage = {
  constants: register_page,
  initialState,
  updateState,
  getStateObservable,
  reducerFn,
}
