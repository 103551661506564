export const ManageVisits = {
  context: 'manage-visits',
  labels: {
    title: '',
  },
  events: {
    GET_VISITS: 'get_visits',
    ADD_NEW_VISIT: 'add_visit',
    REMOVE_VISIT: 'remove_visit',
    SET_ACTIVE_VISIT: 'set_active_visit',
    DELETE_VISIT: 'delete_visit'
  },
  errors: {
  }
};
