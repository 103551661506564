import * as React from "react";
import {useEffect, useState} from "react";
import { Col, Form, Row } from "react-bootstrap";
import addVisit from "../../../images/patient/visit-add-icon.svg";
import {Cancel_btn_sm, Save_btn_sm} from "../../../style-components/StyledButtons";
import '../../../css/patients/visits.scss';
import '../../../css/patients/visits/prescriptions-edit.scss';
import {Textbox} from "../../shared/Inputcomponents/Textbox";
import {API} from "../../../utils/Api";
import {SpinnerLoader} from "../../shared/SpinnerLoader";
import {Auth} from "aws-amplify";
import ReactDOM from 'react-dom';
import {Typeahead} from "react-bootstrap-typeahead";

export const Manageusers = (props: any) => {
  const { clinic } = props;
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([] as any);
  const [permissionDD, setPermission] = useState(false);
  const permissions = ['admin', 'registration', 'lab-admin', 'radiology-admin', 'nurse', 'duty-doctor','pharma-admin', 'pharma-staff'];

  const saveNewUser = async (index: number) => {
    const user = users[index];
    // console.log(user);
    if (!user.uname || !user.role || !user.phone || !user.email || !user.password) {
      alert('Make sure all user details are entered');
      return;
    }

    try {
      let onlyPharmacy = false;

      switch(user.role.toLowerCase()) { 
        case 'pharma-admin' :
        case 'pharma-staff' :{
          onlyPharmacy = true;
          break;
        }
      }

      const attributes: any = {
        email: user.email,
        phone_number: user.phone,
        'custom:centerId': clinic.medicalCenterId,
        'custom:role': user.role,
      };

      if (onlyPharmacy) {
        attributes['custom:onlyPharmacy'] = "true";
      }
    
      const addUser = await Auth.signUp({
        username: user.uname,
        password: user.password,
        attributes,
      });

      alert('User added to cognito');
    } catch(e) {
      alert('Error occurred');
      console.log(e);
    }

  }

  useEffect(()=>{
    (async () => {
      const users = await API.get('/users/'+clinic.medicalCenterId);
      ReactDOM.unstable_batchedUpdates(async ()=>{
        setUsers(users.data);
        setLoading(false);
      })

    })();
  }, [])

  if (loading) {
    return <SpinnerLoader/>
  }

  return (
    <div style={{background: '#FFF', padding: '10px'}}>
      <Row className={"prescription-edit-header text-center-align"} >
        <Col>User name</Col>
        <Col>Role</Col>
        <Col>Password</Col>
        <Col>Phone</Col>
        <Col>Email</Col>
      </Row>
      {users.map((item: any, index: number) => {

        return (
          <Form key={index}>
            <Row className={"prescription-edit-body prescription-row-body"}>
                  <Col>
                    <Textbox
                      noLabel={true}
                      width={"100%"}
                      label={""}
                      property={"uname"}
                      placeHolder={"User name"}
                      value={item.role}
                      updateCb={(value: string, property: string)=>{
                        users[index] = Object.assign({}, users[index], {
                          uname: value,
                        });
                        setUsers([...users]);
                      }}
                    />
                  </Col>
              <Col>
                <Typeahead
                  id={'tariffs-'+index}
                  defaultInputValue={item.name}
                  allowNew={true}
                  open={permissionDD}
                  clearButton={true}
                  options={permissions}
                  placeholder={"Role"}
                  onInputChange={(value) => {
                    setPermission(true);
                  }}
                  onChange={(value) => {
                    users[index] = Object.assign({}, users[index], {
                      role: value[0],
                    });
                    setUsers([...users]);
                    setPermission(false);
                  }}
                />
              </Col>
                  <Col>
                    <Form.Control
                      style={{
                        fontSize: "13px",
                        color: "#5F5F5F",
                        height: "32px",
                        borderRadius: "4px",
                        border: "1px solid #AAAAAA",
                      }}
                      // className={invalid ? "invalid-text-box" : ""}
                      type="password"
                      placeholder={"Enter Password"}
                      value={item.password}
                      onChange={(e) => {
                        users[index] = Object.assign({}, users[index], {
                          password: e.target.value,
                        });
                        setUsers([...users]);
                      }}
                    />
                  </Col>
                  <Col>
                    <Textbox
                      noLabel={true}
                      width={"100%"}
                      label={""}
                      property={"phone"}
                      placeHolder={"Phone number"}
                      value={item.phone}
                      updateCb={(value: string, property: string)=>{
                        users[index] = Object.assign({}, users[index], {
                          phone: value,
                        });
                        setUsers([...users]);
                      }}
                    />
                  </Col>
              <Col>
                <Textbox
                  noLabel={true}
                  width={"100%"}
                  label={""}
                  property={"email"}
                  placeHolder={"Email address"}
                  value={item.email}
                  updateCb={(value: string, property: string)=>{
                    users[index] = Object.assign({}, users[index], {
                      email: value,
                    });
                    setUsers([...users]);
                  }}
                />
              </Col>
                <Col xs={12}>
                  <Save_btn_sm style={{color: '#FFF'}} onClick={(e: any)=>{
                    e.preventDefault();
                    e.stopPropagation();
                    saveNewUser(index);

                  }}>Save User</Save_btn_sm>
                </Col>
                </Row>
          </Form>
        )
      })}
      <br/>
      <Row>
        <Col>
          <div className={"add-prescription"}>
            <div
              className={"add-prescription-btn"}
              onClick={() => {
                users.push({
                  uname: '',
                  role: '',
                  cognitoId: '',
                  password: '',
                  phone: '',
                  email: 'vishnu@bewelldigital.com',
                  medicalCenterId: clinic.medicalCenterId
                });
                setUsers([...users]);
              }}
            >
              <img src={addVisit} alt="add prescription"  />
              Add new user to this hospital
            </div>
          </div>
        </Col>
        <Col className={"visits-record-footer"}>
          &nbsp;
          <Cancel_btn_sm
            className={
              "btn btn-outline-dark btn-sm"

            }
            onClick={() => console.log('Cancel')}
          >
            {" "}
            Cancel{" "}
          </Cancel_btn_sm>
          <Save_btn_sm
            className={
              "btn btn-dark btn-sm"
            }
            onClick={() =>
              console.log()
              // saveTariffs()
              //  addClinicPage.reducerFn(addClinicPage.constants.events.UPDATE_CLINIC, {
              //    clinic: Object.assign({}, clinic, {tariffs: [...tariffs]})}
              //    )
            }
          >
            Save
          </Save_btn_sm>
        </Col>
      </Row>
    </div>
  );
}

export default Manageusers;
