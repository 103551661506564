export const pftDefaults = ()=> {
  return {
    name: 'Pulmonary function test (PFT)',
    mode: 'EDIT',
    completed: false,
    records: [{
      id: 'pft-test',
      title: 'Pulmonary function test (PFT)',
      fields:  [{
        title: 'Pulmonary function test (PFT)',
        fields: [{
          title: 'Normal Spirometry',
          id:'normal-spirometry',
          comments: '',
        }
        ]
      }]
    }]
  };
};
