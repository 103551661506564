import * as React from "react";
import registeredUserImage from "../../../../images/reports/people.svg";
import uniqVisitorsImage from "../../../../images/reports/visitors.svg";
import { Label_form, Label_md } from "../../../../style-components/Labels";
import ReportsTable from "../../../shared/Reportstable";
import { API } from "../../../../utils/Api";
import _ from 'lodash';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from "moment";
import { constants } from "../../../../constants";


const {manage_visits: {ManageRecords}} = constants;


export const Patientinfo = (props: any) => {
  const {autoRefresh, patients, textFilters, selectedFilter, centerId, startDate, endDate} = props;
  const [loading, setLoading] = React.useState(true);
  const [reports, setReport] = React.useState([] as any);
  const [visitCount, setCount] = React.useState({ipCount: 0, opCount: 0, uniqueCount: 0} as any);
  const skeletonRows = new Array(10).fill(0);



  const columns: any = [{
    dataField: 'id',
    text: 'UHID',
    sort: true
  }, {
    dataField: 'name',
    text: 'Name',
    sort: true
  }, {
    dataField: 'timeAndDate',
    text: 'Visit Date',
    sort: true,
  },
  {
    dataField: 'age',
    text: 'Age',
    // sort: true,
  },
  {
    dataField: 'gender',
    text: 'Gender',
    // sort: true,
  },
  {
    dataField: 'phone',
    text: 'Phone',
    // sort: true,
  },
  {
    dataField: 'opVisit',
    text: 'OP',
    // sort: true,
  },
  {
    dataField: 'ipVisit',
    text: 'IP',
    // sort: true,
  },
  {
    dataField: 'prescription',
    text: 'Prescription',
    // sort: true,
  },
  {
    dataField: 'diagnosis',
    text: 'Diagnosis',
    // sort: true,
  },
  
];

  React.useEffect(()=>{
    setLoading(true);
    (async ()=>{
      try {
        const results: any = []; 
        let ipCount = 0, opCount = 0, uniqueCount = 0;
        const patientData: any = [];
        // if (patients.length) {
        const hash: any = [];
        const response = await API.get(`/visits/filter/${centerId}/${startDate.toISOString()}/${endDate.toISOString()}`);
        const patientIds =  _.map(response.data.Items, 'patientId');
        await Promise.all(patientIds.map(async (pid: any, index: number) => {
          if (pid) {
            const patient = await API.get(`/patients/patient/${centerId}/${pid}`);
            patientData.push(patient.data);
          }
        }));
        response.data.Items.forEach((visit: any, index: number) => {
          const patient = patientData.find((patient: any) => patient.patientId === visit.patientId);
          if (patient) {
            const YesText = <b>Yes</b>;
            const hasPrescription = visit.records.findIndex((record: any) => record.id === ManageRecords.events.PRESCRIPTIONS.ADD_PRESCRIPTION) > -1 ? YesText : '-'
            const hasDiagnosis = visit.diagnosis ? YesText : '-'
            let ipvisit = visit.department === 'ip';
            // let opVisit = visit.department === 'op' || !visit.department;
            
            results.push({
              id: patient.uhid,
              name: patient.fullName,
              timeAndDate: moment(visit.creation_date).format('DD-MM-YYYY'),
              age: patient.age && patient.age.years || patient.age.months  ?   patient.age.years + ' Y ' + (patient.age.months ?  patient.age.months + ' M' : '') : '-',
              gender: patient.gender.toString() === 'male' ? 'Male' : 'Female',
              phone: patient.phone || '-',
              ipVisit: visit.department === 'ip' ? YesText : '-',
              opVisit: visit.department === 'op' || !visit.department ? YesText : '-',
              prescription: hasPrescription,
              diagnosis: hasDiagnosis,
            });

            //  To calculate uniq visits
            if (!hash[patient.patientId]) {
              hash[patient.patientId] = 1;
              uniqueCount +=1;
            }

            if (ipvisit) {
              ipCount += 1;
            } else {
              opCount += 1;
            } 
            
          }
        });
        // }

        // (ReactDOM as any).unstable_batchedUpdates(() => {
          setCount({
            ipCount, 
            opCount,
            uniqueCount,
  
          });
          setReport(results);
          setLoading(false);
        // });
      } catch(e) {
        console.log(e);
        setReport([]);
        setLoading(false);
      }
     

    })()
  }, [startDate, endDate, selectedFilter, autoRefresh]);

 

  if (loading) {
    return <>
      <Skeleton variant="rect"  height={80} />
      <br/>
      {
        skeletonRows.map((value: number, index: number) => <Skeleton animation="wave"  key={index}/>)
      }
     
    </>
  }

  if (!reports.length) {
    return <div className={'text-center no-patient-info'}> <Label_md >No patients available for selected date(s)</Label_md></div>
  }

  return <>
    
      <div className={'report-stats'} style={{background: '#FFF'}}>
        {/* <div><h5>Report</h5></div> */}
        <div className={'report-numbers'}>
          <div><img alt='' src={uniqVisitorsImage}/></div>
          <div className={'stat'}>
            <Label_form># of visits</Label_form>
            <p>{reports.length}</p>
          </div>
        </div>
        <div className={'report-numbers'}>
        <div><img alt='' src={uniqVisitorsImage}/></div>
          <div className={'stat'}>
            <Label_form>Unique patients</Label_form>
            <p>{visitCount.uniqueCount}</p>
          </div>
        </div>
        <div className={'report-numbers'}>
        <div>
          <img alt='' src={registeredUserImage} style={{width: '25px'}}/></div>
          <div className={'stat'}>
            <Label_form>OP Visits</Label_form>
            <p>{visitCount.opCount}</p>
          </div>
        </div>
        <div className={'report-numbers'}>
          <div>
          <img alt='' src={registeredUserImage} style={{width: '25px'}}/>
          </div>
          <div className={'stat'}>
            <small>IP Visits</small>
            <p>{visitCount.ipCount}</p>
          </div>
        </div>
    </div>
    <div className={'reports-main-container'}>

      <div className={'reports-table-container'}>
        {/* <Table bordered size={'sm'} className='report-table'>
         <thead>
            <tr className={'reports-table-container-tr reports-table-header'}>
              {tableHeader.map((heading, index) => (
                <th key={index}>{heading}</th>
              ))}
            </tr>
          </thead>
          <tbody>
          {patients.map((patient: any, index: number) => {
            let age = '-';
            if (patient.age && (patient.age.years || patient.age.months)) {
              age = `${patient.age.years ? patient.age.years + 'Y' : ''}  ${patient.age.months && patient.age.months <= 11   ?+' '+patient.age.months + 'M' : ''}`;
            }

            const addedDate = moment(new Date(patient.creation_date), 'hh:ss');
            const hours = addedDate.hours().toString().length === 1 ? '0'+addedDate.hours() : addedDate.hours();
            const minutes = addedDate.minutes().toString().length === 1 ? '0'+addedDate.minutes() : addedDate.minutes();
            const dateToDisplay = (addedDate.date() + '/'+(addedDate.month()+1 )+'/'+addedDate.year()) + ' '+ (hours + ':' + minutes);
          
            return <tr key={index + uuidv4()} className={'reports-table-container-tr'}>
             
              <td key={index + uuidv4()}> <Label_form> {patient.uhid} </Label_form></td>
              <td key={index + uuidv4()}> <Label_form>{patient.fullName} </Label_form></td>
              <td key={index + uuidv4()}><Label_form>{moment(patient.creation_date).format('DD-MM-YYYY')}</Label_form></td>
              <td key={index + uuidv4()}> <Label_form>{age}</Label_form> </td>
              <td key={index + uuidv4()} style={{textAlign: 'center', width: '25px'}}>{patient.gender && patient.gender.toLowerCase() === 'male' ?  <> <Gender_img src={maleIcon}/> </> : <>  <Gender_img src={femaleIcon}/></>}</td>
              <td key={index + uuidv4()}> <Label_form>{patient.phone ?  patient.phone : '-'}</Label_form></td>
            </tr>
          })}



          </tbody>
        </Table> */}
        <ReportsTable columns={columns} list={reports}></ReportsTable>
      </div>
    </div>
    </>
  }
