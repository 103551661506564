
import { BehaviorSubject } from "rxjs";
import { constants } from "../constants";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import {API} from "../utils/Api";
import {confirmAlert} from "react-confirm-alert";
import axios from "axios";

const { lab_constants } = constants;

export const initialState: any = {
  initialLoading: false,
  loading: false,
  selectedPatient: {} as any,
  labtestIndex: -1,
  labtests: [],
  labtestsMeta: [],
};

const subject$ = new BehaviorSubject(initialState);
const getStateObservable = () => {
  return subject$;
};

const updateState = (updateObj: any) => {
  subject$.next(updateObj);
};

const showToast = (message: string, error?: boolean) =>
  toast[error ? "error" : "success"](message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });

const showConfirm = (callback: Function) => {
  confirmAlert({
    title: 'Are you sure ?',
    message: ``,
    buttons: [
      {label: 'Cancel', onClick: () => {return;} , className: 'cancel-button'},
      {label: 'Confirm', className:'confirm-button', onClick: () => {
          callback();
        }},]
  });
}

const serviceFn = {
  getLabTests: (patientId: string, medicalcenterId: string) => {
    return API.get(`/labtests/patient/${medicalcenterId}-${patientId}`);
  },
  getLabTestsList: ()=>{
    return API.get(`/labtests/meta`);
  },
  addLabTest: (data: any) => {
    return API.post(`/labtests`, data);
  },
  updateLabtest: (data: any) => {
    return API.post(`/labtests`, data);
  },
  deleteLabrecord: (labtestId: string, patientId: string) => {
    return API.delete(`/labtests/${patientId}/${labtestId}`);
  }
};

const Managelab = async (
  updateEvent: string,
  params?: { [key: string]: string | number | boolean | any }
) => {
  const state = subject$.getValue();
  const { selectedPatient } = state;
  const { events } = lab_constants;
  const { GET_LAB_ORDERS, SAVE_NEW_LAB_TEST, MARK_LAB_AS_COMPLETED, SET_ACTIVE_LAB_ACCORDION, DELETE_LAB_ACCORDION, UPDATE_LAB_ORDERS, UPLOAD_FILE } = events;

  switch (updateEvent) {
    case DELETE_LAB_ACCORDION: {
      (async ()=>{
        try {
          updateState(Object.assign({}, state, { loading: true, labtestIndex: -1}));
          const {labtestId, patientId, medicalCenterId} = state.labtests[params!.index];
          const deleteLab = await serviceFn.deleteLabrecord(labtestId, patientId);
          showToast('Lab test deleted');
          const labtests = await serviceFn.getLabTests(patientId, medicalCenterId);
          updateState(Object.assign({}, state, { loading: false, labtests: labtests.data, labtestIndex: -1}));

        } catch(e) {
          console.log(e)
          showToast('Unable to delete lab test, try again!', true);
        }
       
      })()
      break;
    }
    case UPDATE_LAB_ORDERS: {
      const {index, selectedRecordIndex, records, close} = params!;
      state.labtests[selectedRecordIndex].tests = records;
      const labtestIndex = close ? -1 : state.labtestIndex;
      try {
        updateState(Object.assign({}, state, { loading: true, labtestIndex }));
        const updateLabtest  = await serviceFn.updateLabtest(state.labtests[selectedRecordIndex]);
        updateState(Object.assign({}, state, { loading: false, labtestIndex}));
        showToast('Lab test updated');
      } catch(e) {
        console.log(e);
        updateState(Object.assign({}, state, { loading: false, labtestIndex}));
        showToast('Unable to update lab test');
      }

      break;
    }
    case SET_ACTIVE_LAB_ACCORDION: {
      if (state.labtestIndex === params!.index) {
        updateState(Object.assign({}, state, { labtestIndex: -1 }));
        return;
      }
      updateState(Object.assign({}, state, { labtestIndex: params!.index }));
      break;
    }
    case GET_LAB_ORDERS: {
      const { patient } = params!;
      try {
        updateState(Object.assign({}, state, { initialLoading: true }));

        const labtests = await serviceFn.getLabTests(patient.patientId, patient.medicalCenterId);
        // const labtestsList = await serviceFn.getLabTestsList();
        updateState(Object.assign({}, state, { initialLoading: false, loading: false, selectedPatient: patient, labtests: labtests.data }));
      } catch(e) {
        updateState(Object.assign({}, state, { initialLoading: false, selectedPatient: patient }));
        console.log(e);

      }
      break;
    }
    case MARK_LAB_AS_COMPLETED: {
      showConfirm(async ()=>{
        if (!state.labtests[params!.index].completed) {
          state.labtests[params!.index].completed = false;
        }

        state.labtests[params!.index].completed =  !state.labtests[params!.index].completed;

        if (!state.labtests[params!.index].completed_at) {
          state.labtests[params!.index].completed_at = '';
        }

        if (state.labtests[params!.index].completed) {
          state.labtests[params!.index].completed_at = new Date().toISOString()
        } else {
          state.labtests[params!.index].completed_at = '';
        }

        try {
          updateState(Object.assign({}, state, { loading: true }));
          const updateLabtest  = await serviceFn.updateLabtest(state.labtests[params!.index]);
          updateState(Object.assign({}, state, { loading: false }));
          showToast('Lab test status updated');
        } catch(e) {
          console.log(e);
          updateState(Object.assign({}, state, { loading: false }));
          showToast('Unable to update lab test');
        }

        // updateState(Object.assign({}, state, {checkupData:  state.checkupData} ));
      });
      break
    }
    case SAVE_NEW_LAB_TEST: {
      try {
        updateState(Object.assign({}, state, { loading: true }));
     
        const response = await serviceFn.addLabTest(Object.assign({}, params!.labTestDetails, {medicalCenterId: state.selectedPatient.medicalCenterId, patientId:  state.selectedPatient.patientId}));
        // const labtests = await serviceFn.getLabTests(state.selectedPatient.patientId, state.selectedPatient.medicalCenterId);

        updateState(Object.assign({},  state, {loading: false}));
      } catch(e) {
        updateState(Object.assign({}, state, { loading: false }));
        console.log(e);

      }
      break;
    }
    case UPLOAD_FILE: {
      const {file, records, selectedRecordIndex, index} = params!;
      console.log(file);
      // const ext = file.filemeta.split('/')[1];
      const name = file.file.name.split(' ').join('_');
      const url = `${constants.betaEndPoint}/upload-to-s3?patientId=${selectedPatient.patientId}&name=${name}`;
      const postData = {content: file.dataURL.split(',')[1]};

      try {
        const upload =  await axios.post(`${url}`, postData);
        if (!state.labtests[selectedRecordIndex].tests[index].files) {
          state.labtests[selectedRecordIndex].tests[index].files = [];
        }
        state.labtests[selectedRecordIndex].tests[index].files.push({
          url,
          name,
        });
        try {
          updateState(Object.assign({}, state, { loading: true, labtestIndex: -1}));
          const updateLabtest  = await serviceFn.updateLabtest(state.labtests[selectedRecordIndex]);
          updateState(Object.assign({}, state, { loading: false}));
          showToast('File added successfully');
        } catch(e) {
          console.log(e);
          updateState(Object.assign({}, state, { loading: false }));
          showToast('Unable to add file');
        }

      } catch (e) {
        console.log(e);
      }
      break;
    }
  }
};

export const MangeLab = {
  constants: lab_constants,
  initialState,
  getStateObservable,
  serviceFn,
  reducerFns: {
    ManageRecords: (
      event: string,
      params?: { [key: string]: string | number | boolean | any }
    ) => {

    },
    Managelab,
  },
  updateState,
};
