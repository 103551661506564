
import { BehaviorSubject } from "rxjs";
import { constants } from "../constants";
import "react-confirm-alert/src/react-confirm-alert.css";
import { IState } from "../types/patients";
import { Prescriptions } from "./visits/prescriptions";
import { ITablets } from "../types/visit-records";
import { Notes } from "./visits/notes";
import { Vitals } from "./visits/vitals";
import { Laborders } from "./visits/laborders";
import { Procedures } from "./visits/procedures";
import { VisitFiles } from './visits/visitFiles';
import {VisitNotes} from "./visits/visitNotes";
import {TreatmentPlans} from "./visits/treatmentPlans";
import { toast } from "react-toastify";
import {API} from "../utils/Api";



const { manage_visits } = constants;

export const initialState: any = {
  initialLoading: true,
  loading: false,
  selectedPatient: {} as any,
  visitIndex: -1,
  labordersMeta: [],
};

const subject$ = new BehaviorSubject(initialState);
const getStateObservable = () => {
  return subject$;
};

const updateState = (updateObj: any) => {
  subject$.next(updateObj);
};

const showToast = (message: string, error?: boolean) =>
  toast[error ? "error" : "success"](message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });

const serviceFn = {
  getVisits: (patientId: string, medicalcenterId: string) => {
    return API.get(`/visits/patient/${medicalcenterId}-${patientId}`);
  },
  addVisit: (data: any) => {
    return API.post(`/visits`, data);
  },
  updateVisit: (data: any) => {
    return API.post(`/visits`, data);
  },
  getLabTestsList: ()=>{
    return API.get(`/labtests/meta`);
  },
  deleteVisit: (patientId: string, visitId: string)=>{
    return API.delete(`/visits/${patientId}/${visitId}`);
  }
};

const ManageVisits = async (
  updateEvent: string,
  params?: { [key: string]: string | number | boolean | any }
) => {
  const state = subject$.getValue();
  const { selectedPatient } = state;
  const record_constants = manage_visits.ManageVisits;
  const { events } = record_constants;
  const { ADD_NEW_VISIT, SET_ACTIVE_VISIT, GET_VISITS, DELETE_VISIT } = events;

  switch (updateEvent) {
    case DELETE_VISIT: {
      try {
        updateState(Object.assign({}, state, { initialLoading: true }));
        const visit = selectedPatient.visits[params!.index];
        const deleteVisit = await serviceFn.deleteVisit(visit.visitId, selectedPatient.patientId);
        showToast('Visit deleted');
        const response = await serviceFn.getVisits(selectedPatient.patientId, selectedPatient.medicalCenterId);
        selectedPatient.visits = [...response.data]
        updateState(Object.assign({}, state, { initialLoading: false, selectedPatient }));
      } catch(e) {
        showToast('Unable to delete visit', true);
        console.log(e);
        updateState(Object.assign({}, state, { initialLoading: false }));
      }
     
      break;
    }
    case GET_VISITS: {
      updateState(Object.assign({}, state, { initialLoading: true }));
      let labordersMeta = [];
      const { patient } = params!;
      const response = await serviceFn.getVisits(patient.patientId, patient.medicalCenterId);
      // try {
      //   const meta = await serviceFn.getLabTestsList();
      //   labordersMeta = meta.data;
      // } catch(e) {
      //   console.log(e);
      // }

      patient.visits = [];
      patient.visits = response.data;
      updateState(
        Object.assign({}, state, {
          initialLoading: false,
          selectedPatient: patient,
          labordersMeta: params!.labtests
        })
      );
      break;
    }
    case ADD_NEW_VISIT: {
      try {
        params!.records = [];
        updateState(
          Object.assign(
            {},
            state,
            { loading: true },
            { initialLoading: !selectedPatient.visits.length }
          )
        );

        // const startTime = new Date();
        //
        // const hours =  Number(params!.startTime.split(":")[0]) === 12 ?  params!.amOrpm === "pm" ? 12 : 0 : Number(params!.startTime.split(":")[0]) + (params!.amOrpm === "am" ? 0 : 12);
        // const minutes = Number(params!.startTime.split(":")[1]);
        //
        //
        // startTime.setHours(hours);
        // startTime.setMinutes(minutes);

        const visitAdded = await serviceFn.addVisit(
          Object.assign({}, params, {
            patientId: selectedPatient.patientId,
            // startTime: startTime.toISOString(),
            medicalCenterId: selectedPatient.medicalCenterId
          })
        );

        const visitResponse = await serviceFn.getVisits(
          selectedPatient.patientId,
          selectedPatient.medicalCenterId
        );

        selectedPatient.visits = [];
        selectedPatient.visits = visitResponse.data;
        showToast("Visit added successfully");
        updateState(
          Object.assign(
            {},
            state,
            { loading: false },
            { visitIndex: 0 },
            { selectedPatient }
          )
        );
      } catch (e) {
        showToast("Unable to save visit, Try again!", true);
        updateState(Object.assign({}, state, { loading: false }));
      }

      break;
    }
    case SET_ACTIVE_VISIT: {
      if (state.visitIndex === params!.index) {
        updateState(Object.assign({}, state, { visitIndex: -1 }));
        return;
      }
      updateState(Object.assign({}, state, { visitIndex: params!.index }));
      break;
    }
  }
};

export const Visits = {
  constants: manage_visits,
  initialState,
  getStateObservable,
  serviceFn,
  reducerFns: {
    ManageRecords: (
      event: string,
      params?: { [key: string]: string | number | boolean | any }
    ) => {
      const state = subject$.getValue();
      const { selectedPatient } = state;
      const record_constants = manage_visits.ManageRecords;
      const { events } = record_constants;

      const visitUpdate = async (stateUpdate: any, override?: boolean) => {
        if (event.indexOf("update_") > -1 || event === events.REMOVE_RECORD || override) {
          updateState({ ...state, ...{ loading: true } });
          try {
            const response = await serviceFn.updateVisit(
              stateUpdate.selectedPatient.visits[state.visitIndex]
            );
            showToast("Visit Updated!");
            updateState({ ...stateUpdate, ...{ loading: false } });
          } catch (e) {
            showToast("'Unable to update visit, try again!", true);
          }
        } else {
          updateState(stateUpdate);
        }
      };

      switch (event) {
        case events.REMOVE_RECORD: {
          selectedPatient.visits[state.visitIndex].records.splice(
            params!.index,
            1
          );
          visitUpdate(Object.assign(state, { selectedPatient }));
          // setState(selectedPatient);
          break;
        }
        case events.CANCEL_EDIT: {
          let record =
            selectedPatient.visits[state.visitIndex].records[params!.index];
          if (record.items) {
            record.items = record.items.filter((item: ITablets) => item.name);
            selectedPatient.visits[state.visitIndex].records.splice(
              params!.index,
              1,
              Object.assign({}, record, { mode: "PREVIEW" })
            );
          } else {
            selectedPatient.visits[state.visitIndex].records.splice(
              params!.index,
              1,
              Object.assign({}, record, { mode: "PREVIEW" })
            );
          }

          visitUpdate(Object.assign({}, state, { selectedPatient }));
          break;
        }

        case events.SHOW_EDIT: {
          const record =
            selectedPatient.visits[state.visitIndex].records[params!.index];
          selectedPatient.visits[state.visitIndex].records.splice(
            params!.index,
            1,
            Object.assign({}, record, { mode: "EDIT" })
          );
          visitUpdate(Object.assign({}, state, { selectedPatient }));
          break;
        }
      }

      return {
        prescriptions: () =>
          Prescriptions({
            state,
            selectedPatient,
            updateState: visitUpdate,
            event,
            params,
          }),
        notes: () =>
          Notes({
            state,
            selectedPatient,
            updateState: visitUpdate,
            event,
            params,
          }),
        vitals: () =>
          Vitals({
            state,
            selectedPatient,
            updateState: visitUpdate,
            event,
            params,
          }),
        labOrders: () =>
          Laborders({
            setLoader: ()=>{
              updateState({ ...state, ...{ loading: true } });
            },
            state,
            selectedPatient,
            updateState: visitUpdate,
            event,
            params,
          }),
        procedures: () =>
          Procedures({
            state,
            selectedPatient,
            updateState: visitUpdate,
            event,
            params,
          }),
          files: ()=> {
            VisitFiles({
              state,
              selectedPatient,
              updateState: visitUpdate,
              event,
              params,
            })
          },
          visitNotes: ()=> {
            VisitNotes({
              state,
              selectedPatient,
              updateState: visitUpdate,
              event,
              params,
            })
          },
         treatmentPlans: ()=> {
           TreatmentPlans({
             state,
             selectedPatient,
             updateState: visitUpdate,
             event,
             params,
           })
         }
      };
    },
    ManageVisits,
  },
  updateState,
  getLabordersMeta: () => {
    const state = subject$.getValue();
    return state.labordersMeta;
  }
};
