import * as React from "react";
import { useState, useEffect } from "react";
import { Container, Spinner } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";
import { Appcontext } from "../Appcontextprovider";
import MainmenuPharmacy from "../components/MainmenuPharmacy";
import { Listpatients } from "../components/Pharmacy/Listpatients";
import { Searchpatients } from "../components/Pharmacy/Searchpatients";
import { SpinnerLoader } from "../components/shared/SpinnerLoader";
import { API } from "../utils/Api";


export const Pharmacy: React.FunctionComponent = (props) => { 
  const {centerId, pharma} = React.useContext(Appcontext); 
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState({list: [], searchResult: false} as any);
  const history = useHistory();

  const createPatient = async (patientId: any, customerInfo?: any) => {
    if (!loading) {
        setLoading(true);
    }
    const {list} = patients;
    let customer = list.find((patient: any) => patient.patientId === patientId);
    if (!customer) {
        customer = customerInfo;
    }
    const patient = await API.post(`/pharmacy/customers`, {
        name: customer.fullName,
        phoneNo: customer.phone,
        pharmacyId: pharma.id,
        patientId
    });
    history.push(`/pharmacy-billing/${patient.data.customer.id}`);
    setLoading(false);
  }   

  const checkIfPatientExisits = async (patientId: string) => {

    try {
        setLoading(true);
        // PatientId should return data  right now patientId filter does not work
        const patient = await API.get(`/pharmacy/customers?pharmacyId=${pharma.id}`);
        if (patient.data && patient.data.data && patient.data.data.length) {
            
            const patientObj = patient.data.data.find((patient: any)=> patient.patientId === patientId);
            if (!patientObj) {
                createPatient(patientId);
            } else {
                history.push(`/pharmacy-billing/${patientObj.id}`);
                setLoading(false);
            }

        } else {
            // patient  is not added to pharma db
            createPatient(patientId);
        }
       
    } catch(e) {
        console.log(e);
    }
  }


  if (loading) {
      return <SpinnerLoader></SpinnerLoader>
  }

  return <div>
      <MainmenuPharmacy centerId={centerId} menuIndex={0}></MainmenuPharmacy>
      <div className="d-flex" id="wrapper">
            <div id="page-content-wrapper">
             <div className="container">
                 <div className='pharmacy-home'>
                    <Searchpatients setPatients={setPatients} patients={patients}></Searchpatients>
                 </div>
                 {
                patients && (patients.list.length || patients.searchResult) ?  
                    <Listpatients patients={patients.list} searchKey={patients.searchKey}
                        goToBilling={(patientId: any) => {
                        
                            checkIfPatientExisits(patientId);
                            
                        }}
                        createPatient={createPatient}
                    ></Listpatients> 
                    : null
                }
              </div>
            </div> 
      </div>
    </div>
  
};

export default withRouter(Pharmacy);
