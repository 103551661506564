import { medicalHistory } from "./medical-history-tabs/Medicalhistory";
import { surgicalHistory } from "./medical-history-tabs/SurgicalHistory";
import { Allergies } from "./medical-history-tabs/Allergy";
import { Lifestyleandsocial } from "./medical-history-tabs/Lifestyle-social";
import { familialHistory } from "./medical-history-tabs/Family-history";
import { mensuralHistory } from "./medical-history-tabs/Mensuralhistory";
import { emergencyContact } from "./medical-history-tabs/Emergencycontact";
import { pregnancyHistory } from "./medical-history-tabs/Pregnancyhistory";
import {
  IAllergyHistory, IComments,
  IEmergencycontact,
  IFamilialhistory,
  ILifestyleandsocial,
  IMedicalhistory,
  IMenstrualhistory,
  IPregnancyhistory,
  ISurgicalHistory,
  ITreatmentinfo,
} from "../types/medical-history";
import { currentMedications } from "./medical-history-tabs/CurrentMedications";
import {Comments} from "./medical-history-tabs/Comments";

export const Medicalhistoryaccordions = [
  currentMedications,
  medicalHistory,
  surgicalHistory,
  Allergies,
  Lifestyleandsocial,
  familialHistory,
  mensuralHistory,
  pregnancyHistory,
  // emergencyContact,
  Comments
];

export const TreatmentOptions = [
  "On treatment",
  "Not on treatment",
  "On native medication",
];

export const GestationalDiabeticsOptions = [
  "Diet",
  "Medication - Insulin",
  "Medication - Oral",
];

export const AccordionIds = {
  CURRENT_MEDICATION: "current_medications",
  MEDICAL_HISTORY: "medical-history",
  SURGICAL_HISTORY: "surgical-history",
  ALLERGY_HISTORY: "allergy-history",
  LIFE_STYLE_SOCIAL_HISTORY: "lifestyle-society-history",
  FAMILIAL_HISTORY: "familial-history",
  MENSTRUAL_HISTORY: "menstrual-history",
  PREGNANCY_HISTORY: "pregnancy-history",
  EMERGENCY_CONTACT: "emergency-contact",
  COMMENTS: "comments",
};

export const events = {
  GET_MEDICAL_HISTORY: "get_medical_history",
  UPDATE_MEDICAL_HISTORY: "update_medical_history",
  GO_TO_EDIT_MODE: "go_to_edit_mode",
  ADD_EMPTY_HISTORY: "add_empty_history",
  GO_TO_PREVIEW: "go_to_preview",
};

export const HistoryDefaults = {
  [AccordionIds.MEDICAL_HISTORY]: {
    weightLoss: {
      selected: false,
      comment: "",
    },
    diabetics: {
      selected: false,
      treatment: "",
      years: 0,
      months: 0,
      medications: [],
    },
    hypertension: {
      selected: false,
      treatment: "",
      years: 0,
      months: 0,
      medications: [],
    },
    cardiovascular: {
      selected: false,
      treatment: "",
      years: 0,
      months: 0,
      medications: [],
    },
    respiratory_illness: {
      selected: false,
      treatment: "",
      years: 0,
      months: 0,
      medications: [],
    },
    gastrointestinal: {
      selected: false,
      treatment: "",
      years: 0,
      months: 0,
      medications: [],
    },
    genitourinary_conditions: {
      selected: false,
      treatment: "",
      years: 0,
      months: 0,
      medications: [],
    },
    bleeding_conditions: {
      selected: false,
      treatment: "",
      years: 0,
      months: 0,
      medications: [],
    },
    neurological_conditions: {
      selected: false,
      treatment: "",
      years: 0,
      months: 0,
      medications: [],
    },
    psychological_conditions: {
      selected: false,
      treatment: "",
      years: 0,
      months: 0,
      medications: [],
    },
  } as IMedicalhistory,
  [AccordionIds.SURGICAL_HISTORY]: {
    surgeries: [],
  } as ISurgicalHistory,
  [AccordionIds.ALLERGY_HISTORY]: {
    medicalAllergies: [],
    foodAllergies: [],
    latexAllergies: {
      comment: "",
    },
    otherAllergies: {
      comment: "",
    },
  } as IAllergyHistory,
  [AccordionIds.LIFE_STYLE_SOCIAL_HISTORY]: {
    smoking: {
      years: 0,
      months: 0,
      amount: 0,
    },
    alcoholConsumption: {
      years: 0,
      months: 0,
      amount: 0,
    },
    historyOfStd: {
      comment: "",
    },
    diet: "",
  } as ILifestyleandsocial,
  [AccordionIds.FAMILIAL_HISTORY]: {
    diabetes: [],
    hypertension: [],
    cardiovascular: [],
    endocrine: [],
  } as IFamilialhistory,
  [AccordionIds.EMERGENCY_CONTACT]: {
    address: "",
    contact: "",
    name: "",
    relationship: "",
  } as IEmergencycontact,
  [AccordionIds.MENSTRUAL_HISTORY]: {
    age: 0,
    comment: "",
    cycle: "",
    days: 0,
  } as IMenstrualhistory,
  [AccordionIds.PREGNANCY_HISTORY]: {
    gravida: "",
    parity: "",
    abortion: "",
    live: "",
    historyOfAbortion: {
      type: "",
      cause: "",
    },
    gestationalDiabetes: {
      treatment: "",
      comments: "",
    },
    eclampsia: "",
    bleeding: "",
    others: "",
  } as IPregnancyhistory,
  [AccordionIds.COMMENTS]: {
    comments: '',
  } as IComments
};


