export const generaltestDefaults = ()=> {
  return {
    name: 'General Test',
    mode: 'EDIT',
    completed: false,
    records: [{
      id: 'general-test',
      title: 'General test',
      fields:  [{
        title: 'General Test',
        fields: [{
          title: 'ECG',
          id:'ecg',
          heartRate: '',
          comments: '',
        },
        {
          title: 'X-Ray Chest',
          id:'x-ray-chest',
          comments: '',
        },
          {
            title: 'Ultrasonogram of the abdomen',
            id:'ultrasonogram',
            comments: '',
          },

        ]
      }]
    }]
  };
};
