import { constants } from "../../constants";
import { IReducer } from "../../types/patients";
const { manage_visits } = constants;

export const Procedures = async (args: IReducer) => {
  const { state, selectedPatient, params, event, updateState } = args;
  const record_constants = manage_visits.ManageRecords;
  const { events, recordsDefaults }: any = record_constants;
  const {
    PROCEDURES: { ADD_PROCEDURE, UPDATE_PROCEDURE, ADD_FROM_PREVIEW_MODE },
  } = events;

  switch (event) {
    case ADD_PROCEDURE: {
      // @ts-ignore
      selectedPatient.visits[params!.index].records.push(
        Object.assign({}, recordsDefaults[ADD_PROCEDURE])
      );
      break;
    }
    case UPDATE_PROCEDURE: {
      const record =
        selectedPatient.visits[state.visitIndex].records[params!.index];
      selectedPatient.visits[state.visitIndex].records.splice(
        params!.index,
        1,
        Object.assign(
          {},
          record,
          { mode: "PREVIEW" },
          { items: params!.procedure }
        )
      );
      break;
    }
    case ADD_FROM_PREVIEW_MODE: {
      const record =
        selectedPatient.visits[state.visitIndex].records[params!.index];

      if (!record.items) {
        record.items = [];
      }

      record.items.push({
        name: "",
        unit: 0,
        cost: 0,
        discount: 0,
      });

      record.mode = "EDIT";
      selectedPatient.visits[state.visitIndex].records.splice(
        params!.index,
        1,
        Object.assign({}, record)
      );
      break;
    }
  }

  updateState(Object.assign({}, state, { selectedPatient }));
};
