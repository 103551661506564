import { TextField } from "@material-ui/core";
import * as React from "react";
import { Radiogroup } from "../../../shared/Inputcomponents/Radiogroup";
import moment from "moment";
import {useEffect, useState} from "react";



export const ReportDatefilter = (props: any) => {
    const {textFilters, selectedFilter, updateTextFilter, startDate, endDate, updateDate} = props;
    const [sDate, setStartDate] = useState('');
    const [eDate, setEndDate] = useState('');
    
    useEffect(()=>{
        setStartDate(moment(startDate).format('yyyy-MM-DD'))
    }, [startDate]);


    useEffect(()=>{
        setEndDate(moment(endDate).format('yyyy-MM-DD'))
    }, [endDate]);

    const createDateFormat = (value: string, from: string) => {
        const year = Number(value.split('-')[0]);
        const month = Number(value.split('-')[1]) - 1;
        const day = Number(value.split('-')[2]);
        const convertedDate = new Date();
        convertedDate.setDate(day);
        convertedDate.setMonth(month);
        convertedDate.setFullYear(year);

        updateDate(convertedDate, from);
    }

    return  <div className='sticky'> 
                <div className={'filter-box '}> 
                    <div className={'report-stats sticky'}>
                    <div className='report-date-filter'>
                        <Radiogroup
                                customClass={''}
                                
                                groupName={"calendar-filter"}
                                updateCb={(value: string) => {
                                    updateTextFilter(value);
                                }}
                                options={textFilters.map((textFilter: any) => {
                                    return textFilter.label;
                                })}
                                selected={selectedFilter.label}
                            />
                        
                    </div>
                    {/* <div>OR</div> */}
                    <div className='report-date-container'>
                        <div className='report-date-filter'>
                            <div className={'report-filter-date'}>
                                <TextField
                                id="start-date"
                                label="Start Date"
                                type="date"
                                value={sDate}
                                inputProps={{
                                    max: eDate,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e: any)=> {
                                    createDateFormat(e.target.value, 'startDate');
                                }}
                                
                            />
                            </div>
                        
                            <div className={'report-filter-date'}>
                                <TextField
                                id="end-date"
                                
                                label="End Date"
                                type="date"
                                value={eDate}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    max: moment(new Date()).format('yyyy-MM-DD'),
                                    min: sDate
                                }}
                                onChange={(e: any)=> {
                                    
                                    createDateFormat(e.target.value, 'endDate');
                                }}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
}

export default ReportDatefilter;