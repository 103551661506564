import axios from "axios";
import { constants } from "../constants";

const { apiEndPoint } = constants;

const checkForSlash = (slug: string) => {
  if (slug && slug.charAt(0) !== "/") {
    return `/${slug}`;
  }

  return slug;
};

export const API = {
  checkForSlash,
  get: (slug: string) => {
    return axios.get(`${apiEndPoint}${checkForSlash(slug)}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  post: (slug: string, postData: any) => {
    return axios.post(`${apiEndPoint}${checkForSlash(slug)}`, postData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  delete: (slug: string) => {
    return axios.delete(`${apiEndPoint}${checkForSlash(slug)}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  addLabTest: (data: any) => {
    return API.post(`/labtests`, data);
  },
};
