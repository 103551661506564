import * as React from 'react';
import {Container} from 'react-bootstrap';
import {Mainmenu} from "../components/Mainmenu";
import {useEffect, useState} from "react";
import {API} from "../utils/Api";
import {Patientinfo} from "../components/patients/Rightpanel/Reports/Patientinfo";
import {Diagnosisreport} from "../components/patients/Rightpanel/Reports/Diagnosisreport";
import ReportDatefilter from '../components/patients/Rightpanel/Reports/ReportDatefilter';
import ReactDOM from 'react-dom';
import { SpinnerLoader } from '../components/shared/SpinnerLoader';
import { Appcontext } from '../Appcontextprovider';
import { v4 as uuidv4 } from 'uuid';
import ReportsLayout from '../layout/ReportsLayout';
import { Label_md } from '../style-components/Labels';
import { Labstatus } from '../components/patients/Rightpanel/Reports/Labstatus';
import { BillingTotalCollection } from '../components/patients/Rightpanel/Reports/BillingTotalCollection';


export const Reports: React.FunctionComponent = ({ match, location }: any, props) => {
  const todaysDate = new Date();
  todaysDate.setHours(0,0,0,0);
  const {centerId} = React.useContext(Appcontext);
  const textFilters = [{id: 'today', label: 'Today'}, {id: 'last-seven-days', label: 'Last 7 days'}, {id: 'last-month', label: 'Last 30 days'}];
  const [patients, setPatients] = useState([] as any);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = React.useState(0);
  const [selectedFilter, setSelectedFilter] = useState(textFilters[0]);
  const [startDate, setstartDate] = useState(todaysDate);
  const [endDate, setEndDate] = useState(new Date());
  const [step, setStep] = useState(0);
  const [autoRefresh, setAutoRefresh] = useState(0 as any);
  const [updateChild, setUpdateChild] = useState(0 as any);
  const reportList = [{type: 'Process Reports', items: [
    {title: 'Patient Information', stepIndex: 0}, {title: 'Lab status', stepIndex: 1}, {title: 'Diagnosis report', stepIndex: 2}]}, 
    {type: 'Billing Reports', items: [{title: 'Total collection', stepIndex: 3}]}
  ];
  let currentReport: string = reportList[0].items[0].title;;


  // if (step > 0 && step <3) {
    currentReport = reportList[step > -1 && step <3 ? 0 : 1].items.find((item: any) => item.stepIndex === step)?.title!
   
  

  const updateDate = (date: any, from: string) => {
    if (from === 'startDate') {
      setstartDate(date);
    } else {
      setEndDate(date);
    }
  }

  const getInfo = async (onInit: boolean, startDate: Date, endDate: Date) => {

    try {
      // const patients = await API.get(`/patients/medicalcenter/${centerId}`);
      // setPatients(patients.data.Items);
      if (onInit) {
        setLoading(false);
      }
    } catch(e) {
      console.log(e);
    }
    
  };

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const updateTextFilter = (label: any)  => {
      const filter = textFilters.find((value: {id: string, label: string}) =>  label.toLowerCase() === value.label.toLowerCase());
      if (filter) {
        let startDate = new Date();
        let endDate = new Date();
        let todaysDate = new Date();

        if (filter.id === 'last-seven-days') {
          todaysDate.setDate(todaysDate.getDate() - 7);
        } else if (filter.id === 'last-month') {
          todaysDate.setDate(todaysDate.getDate() - 30);
        } else {
          todaysDate.setHours(0,0,0,0);
        }
        startDate =  todaysDate;

        (ReactDOM as any).unstable_batchedUpdates(() => {
          setstartDate(startDate);
          setEndDate(endDate);
          setSelectedFilter(filter!);
        });
       
      } 
  };

  useEffect(()=>{
    setLoading(true);
    getInfo(true,  startDate, endDate);
  }, [startDate, endDate, selectedFilter]);

  useEffect(()=>{
    getInfo(false,  startDate, endDate);
    setUpdateChild(uuidv4());
  }, [autoRefresh]);


  useEffect( ()=>{
      const interval = setInterval(() => {
        setAutoRefresh(uuidv4());
      }, 300000);
    
      return () => clearInterval(interval)
  }, []);





  const loaderClass = loading ? 'app-loading' : 'placeholder-class';

  return   <Container fluid style={{'padding': '0px'}} className={`container-style ${loaderClass}`}>

    <Mainmenu  menuItem = {'report'} centerId={centerId} menuIndex={1}/>

    <div className={'reports-body'}>
      <ReportsLayout leftPanelList={reportList} leftPanelTitle={'Analytics'} step={step} setStep={setStep}>
    
        {/* <Paper square >
          <Tabs
            centered
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab label="Admissions report" />
            <Tab label="Patient information" />
          </Tabs>
        </Paper> */}
        <>
         
             <div className='report-header'>
                <div><Label_md style={{fontSize: '16px'}}>{currentReport}</Label_md></div>
              </div>
              <br/>
                
            <ReportDatefilter textFilters={textFilters} selectedFilter={selectedFilter} updateTextFilter={updateTextFilter}  startDate={startDate} endDate={endDate} updateDate={updateDate}/> 
                
              <br/>
              {loading ? <SpinnerLoader message={'Loading report, please wait ...'}></SpinnerLoader> : null}
              {step === 0 && !loading ? <Patientinfo patients={patients} autoRefresh={updateChild} textFilters={textFilters} centerId={centerId} startDate={startDate} endDate={endDate}  selectedFilter={selectedFilter}></Patientinfo> :  null}
              {step === 1 && !loading ? <Labstatus patients={patients} autoRefresh={updateChild} textFilters={textFilters} centerId={centerId} startDate={startDate} endDate={endDate}  selectedFilter={selectedFilter}></Labstatus> :  null}
              {step === 2 && !loading ? <Diagnosisreport patients={patients} autoRefresh={updateChild} textFilters={textFilters} centerId={centerId} startDate={startDate} endDate={endDate}  selectedFilter={selectedFilter}></Diagnosisreport> : null}
              {step === 3 && !loading ? <BillingTotalCollection patients={patients} autoRefresh={updateChild} textFilters={textFilters} centerId={centerId} startDate={startDate} endDate={endDate}  selectedFilter={selectedFilter}></BillingTotalCollection> : null}
         
          </>
      
      </ReportsLayout>
    </div>



  </Container>;
};

export default (Reports);
