import { Register } from "./registration";
import { Addclinic } from "./add-clinic";
import { Login } from "./login";
import { Patients } from "./patient";
import { Medicalhistoryaccordions } from "./medical-history";
import { Addpatient } from "./addpatient";
import { Managevisits } from "./visits";
import {Checkup} from "./health-checkup";
import {LabConstants} from './Lab';
import {BillsConstants} from "./Bill";

export let  localConfig =  {
  aws_project_region: "us-west-2",
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: "us-west-2_n2WWGLnHs",
  aws_user_pools_web_client_id: "3454kktsrq7n3cbv3djj28ku6u",
  oauth: {},
};

export let betaConfig =  {
  aws_project_region: "us-west-2",
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: "us-west-2_n2WWGLnHs",
  aws_user_pools_web_client_id: "3454kktsrq7n3cbv3djj28ku6u",
  oauth: {},
};

export let prodConfig = {
  aws_project_region: "ap-south-1",
  aws_cognito_region: "ap-south-1",
  aws_user_pools_id: "ap-south-1_CZFitK4lo",
  aws_user_pools_web_client_id: "5hvbqdm3nmuiatmbkd1hq4ocar",
  oauth: {},
};

export let harshithaConfig = {
  aws_project_region: "ap-southeast-1",
  aws_cognito_region: "ap-southeast-1",
  aws_user_pools_id: "ap-southeast-1_c6tjtds4S",
  aws_user_pools_web_client_id: "4buo44f2febtgp43glg509lq6n",
  oauth: {},
};

export const appEvents = {
  NOT_AUTHENTICATED_USER: "The user is not authenticated",
  AUTHENTICATED_USER: "authenticated_user",
  LOADING_APP: "Loading_app",
}

const getAPIEndPoint = () => {
  if (window.location.href.indexOf('app.bewelldigital.com') > -1 ) {
    // THis is prod mumbai link
    return "https://q1lsdba6f6.execute-api.ap-south-1.amazonaws.com/prod";
  } else if (window.location.href.indexOf('harshitha.bewelldigital.com') > -1) {
    //|| window.location.href.indexOf('localhost') > -1
    // This is singapore link
    return "https://ojarjm4nvk.execute-api.ap-southeast-1.amazonaws.com/prod";
  } else {
    // return 'https://ojarjm4nvk.execute-api.ap-southeast-1.amazonaws.com/prod';
    // This is oregon link beta/dev
    // return "https://q1lsdba6f6.execute-api.ap-south-1.amazonaws.com/prod";
  //  return "http://localhost:3001";
  //  THis is dev link
   return "https://4440i72tm2.execute-api.us-west-2.amazonaws.com/prod";
  
  }
}

const betaEndPoint = () => {
  return "https://4440i72tm2.execute-api.us-west-2.amazonaws.com/prod";
}

const isProd = () => {
  return window.location.href.indexOf('app.bewelldigital.com') > -1;
}


export const constants = {
  apiEndPoint: getAPIEndPoint(),
  manage_checkup: Checkup,
  login_page: Login,
  lab_constants: LabConstants,
  register_page: Register,
  add_clinic_page: Addclinic,
  patients_page: Patients,
  medical_history_accordions: Medicalhistoryaccordions,
  add_patient: Addpatient,
  manage_visits: Managevisits,
  BillsConstants,
  appEvents,
  localConfig,
  betaConfig,
  prodConfig,
  harshithaConfig,
  pdfAppSms: 'pdf.bewelldigital.com/',
  pdfApp: 'https://pdf.bewelldigital.com/',
  pdfGenerator: 'https://yi3qolutpj.execute-api.us-west-2.amazonaws.com/test',
  printHtml: 'http://web-test-bewell.s3-website-ap-southeast-1.amazonaws.com/',
  betaEndPoint: betaEndPoint(),
  isProd: isProd(),
};
