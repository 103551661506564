import * as React from "react";
import { useEffect } from "react";
import RegistrationLayout from "../layout/Registration";
import {Spinner} from "react-bootstrap";
import {Plain_row, Plain_column} from "../style-components/Divs";
import {Handleauth} from "../utils/Handleauth";



export const Logout: React.FunctionComponent = (props) => {
  const UseComponent= <div><Plain_row><Plain_column>Securely logging out of BeWell Digital....</Plain_column></Plain_row>
    <br/>
  <Plain_row>
    <Plain_column style={{width: '30px', height: '30px', textAlign: 'center'}}>
    <Spinner animation="border" role="status" style={{width: '30px', height: '30px', margin: 'auto'}}>
  </Spinner></Plain_column></Plain_row>   </div>;
  useEffect(() => {

    (async () => {
      try {
        await  Handleauth.logout();
      } catch(e) {
        window.location.href ='/login';
      }
      setTimeout(()=>{
        window.location.href ='/login';
      }, 2000);
    }
     )();
  }, []);

  return <RegistrationLayout children={UseComponent} />;
};

export default Logout;
