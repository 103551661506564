export const ExaminationDefault = ()=> {
  return {
    name: 'Examination',
    mode: 'EDIT',
    completed: false,
    records: [{
      id: 'examination',
      title: 'Examination',
      fields:  [{
        title: 'General examination',
        fields: [{
          title: '',
          id:'general',
          buildBodyWeight: '',
          buildHeight: '',
          buildBodyType: '',
          comfortableAtRest: '',
          comfortableAtRestComments: '',
          levelsOfConsciousness: '',
          mentalStatus: '',
          temperature: '',
          pallor: '',
          icterus: '',
          cyanosis: '',
          cynosisType: '',
          clubbing: '',
          generalizedLymphadenopathy: '',
          pedalEdema: '',
          pedalEdemaType1: '',
          pedalEdemaType2: '',
          thyroid: '',
          thyroidComments: '',
          comments: '',
          respRate: '',
        }]
      }, {
        title: 'Systemic examination',
        fields: [{
          title: 'Cardiovascular system',
          id:'cardio',
          heartSounds: '',
          murmur: '',
          murmurComment: '',
          addedSounds: '',
          addedSoundsComment: '',
          jvpElevated: '',
          comments: '',
        },{
          title: 'Respiratory system',
          id:'respiratory',
          chestWallMovement: '',
          chestWallComments: '',
          bilateralAirentry: '',
          bilateralAirentryComments: '',
          breathSound: '',
          breathsoundComments: '',
          addedSounds: '',
          addedSoundsComments: '',
          trachealPosition: '',
          trachealPositionComments: '',
          comments: '',
          lastUpdated: '',

        },
          {
            title: 'Abdomen',
            id:'abdomen',
            feel: '',
            superficialPalpation: '',
            superficialPalpationComments: '',
            deepPalpation: '',
            deepPalpationComments: '',
            hernialOrifices: '',
            bowelSounds: '',
            bowelSoundsType: '',
            abnormalSounds: '',
            abnormalSoundsComments: '',
            comments: '',

          },
          {
            title: 'Central nervous system',
            id:'central-nervous-system',
            centralNervousSystem: '',
            centralNervousSystemComments: '',
            comments: '',

          },
          // {
          //   title: 'Comments',
          //   id:'comments',
          //   comments: '',
          // }
        ]


      }]
    }]
  };
};

export const BodyBuildDefaults = [['Lean', 'Obese'], ['Tall','Short'], ['Muscular', 'Asthenic', 'Cachexic']];
export const ConsciousnessDefaults = ['Conscious', 'Altered sensorium', 'Unconscious'];
export const CyanosisDefaults = ['Central', 'Peripheral', 'Differential'];
export const EdemaDefaults = [['Bilateral', 'Unilateral'], ['Pitting', 'Non pitting']];
export const MentalStatusDefaults = ['Oriented', 'disoriented'];
export const TemperatureStatusDefaults = ['Afebrile', 'Febrile'];
export const HeartSoundsDefaults = ['Normal','Muffled', 'Accentuated'];

//Respiratory
export const ChestwallDefaults = ['Equal','Unequal'];
export const BilateralAirEntry = ['Equal','Unequal'];
export const Breathsound = ['Vesicular','Bronchial'];
export const Trachealposition = ['Midline','Deviated'];

// Abdomen
export const AbdomenFeel = ['soft', 'tenderness', 'guarding', 'rigidity'];
export const HernialOrifices = ['NAD',];
export const BowelSounds = ['Normal','Hyperactive', 'Hypoactive'];

// Neurological deficit
export const CentralDeficit = ['No focal neurological deficit', 'Neurological deficit'];

// Urine color options
export const UrineColorOptions = ['No color','Transparent',
  'Pale Straw Color',
  'Transparent Yellow',
  'Dark Yellow',
  'Amber or Honey',
  'Syrup or brown ale',
  'Pink to reddish',
  'Orange',
  'Blue or green',
  'Purple',
  'Foaming or fizzing']

