export const Lifestyleandsocial = {
  title: "Lifestyle and social",
  subtitle: "",
  id: "lifestyle-society-history",
  // items: [
  //   {
  //     title: 'Smoker',
  //     type: 'toggle',
  //     id: 'unique1',
  //     children: [
  //       {
  //         title: 'Duration',
  //         type: 'smoking',
  //       }
  //     ]
  //   },
  //   {
  //     title: 'Alcoholic',
  //     type: 'toggle',
  //     id: 'unique2',
  //     children: [
  //       {
  //         title: 'Duration',
  //         type: 'drinking',
  //       }
  //     ]
  //   },
  //   {
  //     title: 'History of contact with STD',
  //     type: 'toggle',
  //     id: 'unique3',
  //     children: [{
  //       title: 'comment',
  //       type: 'textareabox',
  //     }]
  //   },
  //   {
  //     title: 'Diet',
  //     type: 'toggle',
  //     id: 'unique4',
  //     children: [{
  //       title: 'comment',
  //       type: 'checkboxes',
  //       options: ['Vegetarian', 'Non-vegetarian', 'Mixed']
  //     }]
  //   }
  // ]
};
