import { constants } from "../../constants";
import { IReducer } from "../../types/patients";
import { API } from "../../utils/Api";

const { manage_visits } = constants;

export const Laborders = async (args: IReducer) => {
  const { state, selectedPatient, params, event, updateState } = args;
  const record_constants = manage_visits.ManageRecords;
  const { events, recordsDefaults }: any = record_constants;
  const {
    LAB_ORDERS: { ADD_ORDER, UPDATE_ORDER, ADD_FROM_PREVIEW_MODE },
  } = events;

  switch (event) {
    case ADD_ORDER: {
      // @ts-ignore
      selectedPatient.visits[params!.index].records.push(
        Object.assign({}, recordsDefaults[ADD_ORDER])
      );
      break;
    }
    case UPDATE_ORDER: {
    
      const {patientId, medicalCenterId, medi_patient_id, docName} = selectedPatient.visits[state.visitIndex];

      const LabOrder = {
        creation_date: new Date().toISOString(),
        patientId,
        medicalCenterId,
        medi_patient_id,
        docName,
        tests: params!.orders.tests
      };
      args.setLoader!();
      const LaborderCreate = await API.addLabTest(LabOrder);
      
      const record = selectedPatient.visits[state.visitIndex].records[params!.index];
      selectedPatient.visits[state.visitIndex].records.splice(
        params!.index,
        1,
        Object.assign(
          {},
          record,
          { mode: "PREVIEW" },
          { items: params!.orders, labtestId: LaborderCreate.data.data.labtestId }
        )
      );
      break;
    }
    case ADD_FROM_PREVIEW_MODE: {
      const record =
        selectedPatient.visits[state.visitIndex].records[params!.index];

      if (!record.items) {
        record.items = [];
      }

      record.items.push({
        name: "",
        description: "",
      });

      record.mode = "EDIT";
      selectedPatient.visits[state.visitIndex].records.splice(
        params!.index,
        1,
        Object.assign({}, record)
      );
      break;
    }
  }

  updateState(Object.assign({}, state, { selectedPatient }));
};
