import { registerPage } from "./registration";
import { addClinicPage } from "./managehospitals";
import { loginPage } from "./login";
import { PatientPage } from "./patients";
import { addPatientPage } from "./addpatient";
import { Visits } from "./visits";
import { Patienthistory } from "./patient-history";
import {Healthcheckup} from "./healthcheckup";
import {MangeLab} from "./Lab";
import {billsPage} from "./bills";

export const stateManagers = {
  registerPage,
  addClinicPage,
  loginPage,
  PatientPage,
  addPatientPage,
  Visits,
  Patienthistory,
  Healthcheckup,
  MangeLab,
  billsPage
};
