import * as React from "react";
import uniqVisitorsImage from "../../../../images/reports/visitors.svg";
import { Label_form, Label_md } from "../../../../style-components/Labels";
import ReportsTable from "../../../shared/Reportstable";
import { API } from "../../../../utils/Api";
import _ from 'lodash';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from "moment";

export const Labstatus = (props: any) => {
  const {autoRefresh, patients, textFilters, selectedFilter, centerId, startDate, endDate} = props;
  const [loading, setLoading] = React.useState(true);
  const [reports, setReport] = React.useState([] as any);
  const [labtestCount, setCount] = React.useState({completionCount: 0} as any);
  const skeletonRows = new Array(10).fill(0);

  const columns: any = [{
    dataField: 'id',
    text: 'UHID',
    sort: true
  }, {
    dataField: 'name',
    text: 'Name',
    sort: true
  }, 
  // {
  //   dataField: 'age',
  //   text: 'Age',
  //   // sort: true,
  // },
  // {
  //   dataField: 'gender',
  //   text: 'Gender',
  //   // sort: true,
  // },
  {
    dataField: 'timeAndDate',
    text: 'Ordered Date',
    sort: true,
  },
  {
    dataField: 'labOrderedTime',
    text: 'Lab ordered At',
    sort: true,
  },
  {
    dataField: 'labFulfilledTime',
    text: 'Lab Completed At',
    sort: true,
  },
  {
    dataField: 'timeTaken',
    text: 'Time Taken',
    sort: true,
  },
  
];

  React.useEffect(()=>{
    setLoading(true);
    (async ()=>{
      try {
        const results: any = []; 
        const patientData: any = [];
        // if (patients.length) {
        const response = await API.get(`/labtests/filter/${centerId}/${startDate.toISOString()}/${endDate.toISOString()}`)
        const patientIds =  _.map(response.data.Items, 'patientId');

        await Promise.all(patientIds.map(async (pid: any, index: number) => {
            if (pid) {
              const patient = await API.get(`/patients/patient/${centerId}/${pid}`);
              patientData.push(patient.data);
            }
        }));
        response.data.Items.forEach((labtest: any, index: number) => {
          let duration: any = '';
          if (labtest.completed) {
            const completed = moment(labtest.completed_at);
            const ordered = moment(labtest.creation_date);
            const diff = moment.duration(completed.diff(ordered));
            duration = diff.asHours();

            try {
              if (duration) {
                duration = duration.toFixed(2).split('.').join(':');
                // duration = duration.toString().toFixed(2);
              } else {
                duration = '';
              }
            } catch(e) {
              duration = '';
            }
          }

          const patient = patientData.find((patient: any) => patient.patientId === labtest.patientId);
          if (patient) {  
            results.push({
              id: patient.uhid,
              name: patient.fullName,
              timeAndDate: moment(labtest.creation_date).format('DD-MM-YYYY'),
              labOrderedTime: moment(labtest.creation_date).format('hh:mm a'),
              labFulfilledTime: labtest.completed ?  moment(labtest.completed_at).format('hh:mm a') : '-',
              timeTaken: duration ? <b>{duration} (Hours)</b> : '-',
              completed: labtest.completed
            });        
          }
        });
        // }

        // (ReactDOM as any).unstable_batchedUpdates(() => {
          setCount({
            completionCount: results.filter((lab: any) => lab.completed).length,
          });
          setReport(results);
          setLoading(false);
        // });
      } catch(e) {
        console.log(e);
        setReport([]);
        setLoading(false);
      }
     

    })()
  }, [startDate, endDate, selectedFilter, autoRefresh]);

 

  if (loading) {
    return <>
      <Skeleton variant="rect"  height={80} />
      <br/>
      {
        skeletonRows.map((value: number, index: number) => <Skeleton animation="wave"  key={index}/>)
      }
     
    </>
  }

  if (!reports.length) {
    return <div className={'text-center no-patient-info'}> <Label_md >No patients available for selected date(s)</Label_md></div>
  }

  return <>
    
      <div className={'report-stats'} style={{background: '#FFF', justifyContent: 'flex-start'}}>
        {/* <div><h5>Report</h5></div> */}
        <div className={'report-numbers'}>
          <div><img alt='' src={uniqVisitorsImage}/></div>
          <div className={'stat'}>
            <Label_form>Tests Ordered</Label_form>
            <p>{reports.length}</p>
          </div>
        </div>
        <div className={'report-numbers'}>
        <div><img alt='' src={uniqVisitorsImage}/></div>
          <div className={'stat'}>
            <Label_form>Labtests fulfilled</Label_form>
            <p>{labtestCount.completionCount}</p>
          </div>
        </div>
    </div>
    <div className={'reports-main-container'}>

      <div className={'reports-table-container'}>
       
        <ReportsTable columns={columns} list={reports}></ReportsTable>
      </div>
    </div>
    </>
  }
