import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {version} from '../../package.json';


import {
  faCog,
  faUser,
  faChartPie,
  faBars,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import {
  Main_menu,
  Bewell_logo,
  Menu_items,
  Menu_item,
  VERSION_DISPLAY,
  LOGOUT_CONTAINER,
  LOGOUT_BUTTON
} from "../style-components/Navbar";

import {} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const Usericon = <FontAwesomeIcon icon={faUser} color="#77869E" />;
const Reporticon = <FontAwesomeIcon icon={faChartPie} color="#77869E" />;
// const Settingsicon = <FontAwesomeIcon icon={faCog} color="#77869E" />;

export const Mainmenu = (props: any) => {
  const { centerId, menuItem } = props;
  let menuIndex = 0;
  const [toggle, setToggle] = useState(false);

  switch (menuItem) {
    case "report":
      menuIndex = 1;
      break;
    case "settings":
      menuIndex = 2;
      break;
  }
  const history = useHistory();

  const menuItems = [
    {
      icon: Usericon,
      name: "Patients",
      route: `/patients/${centerId}`,
    },
    {
      icon: Reporticon,
      name: "Analytics",
      route: `/analytics/${centerId}`,
    },
  ];

  const isLandingPage = true;

  return (
    <Main_menu className="nav-bar">
      {isLandingPage ? (
        <div className={'menu-brand'}>
        <div
          className={"hamburger-menu"}
          onClick={() => {
            if (document.getElementById("sidebar-wrapper")) {
              if (!toggle) {
                document.body.style.width = "1400px";
                document.getElementById("sidebar-wrapper")!.className =
                  document.getElementById("sidebar-wrapper")!.className +
                  " animation";
                document.getElementById("sidebar-wrapper")!.style.display =
                  "block";
              } else {
                document.body.style.width = "1024px";
                document.getElementById("sidebar-wrapper")!.className = "";
                document.getElementById("sidebar-wrapper")!.style.display =
                  "none";
              }
              setToggle(!toggle);
            }
          }}
        >
          {" "}
          <FontAwesomeIcon icon={faBars} />{" "}

        </div>
          <Bewell_logo className={isLandingPage ? "" : "logo"}>BeWell <VERSION_DISPLAY>{version}</VERSION_DISPLAY></Bewell_logo></div>

      ) : null }

      <Menu_items className={"menu-items"}>
        {menuItems.map((item, index) => {
          const selectedItem = index === menuIndex ? " active-menu-item" : "";
          return (
            <Menu_item
              onClick={() => {
                history.push(item.route);
              }}
              className={`${selectedItem}`}
              key={index}
            >
              {" "}
              {item.icon} &nbsp; {item.name}
            </Menu_item>
          );
        })}
      </Menu_items>
      <LOGOUT_CONTAINER>
          <LOGOUT_BUTTON onClick={()=> window.location.href='/logout'}>Logout</LOGOUT_BUTTON>
      </LOGOUT_CONTAINER>

    </Main_menu>

  );
};

export default Mainmenu;
