import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const ReportsTable = (props: any) => {
    const {list, columns} = props;
    const pagenationOptions = {
        pageStartIndex: 0,
        sizePerPage: 50,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true
      };
    

    return  <BootstrapTable  keyField='uhid' data={ list } columns={ columns } rowClasses="reports-table-container-tr" bordered={true} bootstrap4={true}  />
}

export default ReportsTable;