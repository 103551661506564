import React, { useState } from "react";
import ReactInputVerificationCode from "react-input-verification-code";

import "../../App.scss";
import { useToasts } from "react-toast-notifications";
import { Button, Form } from "react-bootstrap";
import { Label_form } from "../../style-components/Labels";
import { Setnewpassword } from "./Setnewpassword";

interface IGetotpProps {
  parentState: any;
  pageLabels: { [key: string]: string };
  eventHandler: any;
  errorStates: TEventError;
  userEvents: { [key: string]: string };
  updatePassword: any;
  updateCb: any;
}

export const Getotp = (props: IGetotpProps) => {
  const [otp, setOtp] = useState("");
  const { addToast } = useToasts();

  const {
    updatePassword,
    updateCb,
    parentState,
    eventHandler,
    errorStates,
    userEvents,
    pageLabels,
  } = props;
  const { phone, userErrorState } = parentState;
  const { INVALID_OTP } = errorStates;

  const loaderClass = parentState.loading ? "app-loading" : "placeholder-class";
  return (
    <section className={loaderClass}>
      <p>Verify & Set Password</p>
      <small>{`We have sent you an otp to registered mobile number`}</small>
      <br />
      <br />
      <Label_form>OTP</Label_form>
      <form onSubmit={(e: any) => e.preventDefault()}>
        <ReactInputVerificationCode
          length={6}
          placeholder={"*"}
          onChange={(e) => {
            setOtp(e);
          }}
        />
        <Setnewpassword
          parentState={parentState}
          eventHandler={eventHandler}
          updatePassword={updatePassword}
          updateCb={updateCb}
          userEvents={userEvents}
          errorStates={errorStates}
          pageLabels={pageLabels}
          otp={otp}
        />
      </form>

      {/*{userErrorState ? (*/}
      {/*  <small className="error-info">{userErrorState.display}</small>*/}
      {/*) : (*/}
      {/*  <small></small>*/}
      {/*)}*/}

      {/*<div className="btn-container">*/}
      {/*  <Button*/}
      {/*    variant="dark"*/}
      {/*    onClick={() => {*/}
      {/*      console.log(otp);*/}
      {/*      if (!otp || isNaN(Number(otp))) {*/}
      {/*        addToast(Showtoastermsg(INVALID_OTP.error, INVALID_OTP.display), {*/}
      {/*          appearance: "error",*/}
      {/*          autoDismiss: true,*/}
      {/*        });*/}
      {/*        return;*/}
      {/*      }*/}

      {/*      eventHandler(userEvents.VERIFY_OTP, { otp });*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {" "}*/}
      {/*    {parentState.loading ? (*/}
      {/*      <span className="spinner-border spinner-border-sm"></span>*/}
      {/*    ) : (*/}
      {/*      "Verify"*/}
      {/*    )}*/}
      {/*  </Button>*/}
      {/*</div>*/}
      <div className="link-button-container">
        <div>
          <Button
            variant="link"
            onClick={() => {
              eventHandler(userEvents.RESEND_OTP);
            }}
          >
            Resent OTP ?
          </Button>
          <Button
            variant="link"
            onClick={() => {
              eventHandler(userEvents.GO_TO_LOGIN);
            }}
          >
            Go to login
          </Button>
        </div>
      </div>
    </section>
  );
};
