export const Register = {
  context: "register",
  labels: {
    title: "Register",
    subtitle: "Enter your mobile number to register with bwell",
  },
  show: {
    INPUT_PHONE_NUMBER: "input_phone_number",
    GET_OTP: "get_otp",
    VERIFY_OTP: "verify_otp",
    OTP_VERIFICATION_DONE: "otp_verification_done",
    REGISTER_CLINIC: "register_clinic",
  },
  events: {
    ENTER_PHONE_NUMBER: "enter_phone_number",
    VERIFY_PHONE_NUMBER: "verify_phone_number",
    RESEND_OTP: "resend_otp",
    VERIFY_OTP: "verify_otp",
    GO_TO_LOGIN: "go_to_login",
  },
  errors: {
    USER_ALREADY_REGISTERED: {
      display: "An account with phone number already exists",
      error: "UsernameExistsException",
    },
    INVALID_PHONE_NUMBER: {
      display: "Enter a valid phone number",
      error: "Invalid number",
    },
    UNABLE_TO_SEND_OTP: {
      display: "Try again!",
      error: "Something went wrong!",
    },
    INVALID_OTP: { display: "Invalid otp!", error: "Enter valid otp" },
  },
};
