import {IHaemogram} from "../../types/checkup";

export const HormonesDefaults = () => {
  return {
    name: 'Hormones',
    mode: 'EDIT',
    completed: false,
    records: [{
      id: 'hormones',
      title: 'Thyroid Function Test',
      fields:  [
        {
          name: 'T3 (Total triiodothyronine) ',
          result: '',
          unit: 'mg/dl',
          range: {
            min: 0.6,
            max: 1.0
          }
        },
        {
          name: 'T4 (Total Thyroxine)',
          result: '',
          unit: 'mg/dl',
          range: {
            min: 3.2,
            max: 12.6
          }
        },
        {
          name: 'TSH (Thyroid Stimulating Hormone)',
          result: '',
          unit: 'miu/mL',
          range: {
            min: 0.35,
            max: 5.5
          }

        },

      ]
    },
    ] as IHaemogram
  };
}
