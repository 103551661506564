export const currentMedications = {
  title: "Current Medications",
  subtitle: "",
  id: "current_medications",
  // items: [
  //   {
  //     title: 'Surgical History',
  //     id: 'surgery-id',
  //     type: 'surgery',
  //   }
  // ]
};
