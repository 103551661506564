
import { BehaviorSubject } from "rxjs";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import {
  HistoryDefaults,
  events,
  AccordionIds,
} from "../constants/medical-history";
import {API} from "../utils/Api";
import {Auth} from "aws-amplify";


export const initialState = {
  selectedIndex: -1,
  patientId: "",
  initialLoading: true,
  loading: false,
  medicalHistory: {} as any,
  historyEdited: false,
};

const subject$ = new BehaviorSubject(initialState);
const getStateObservable = () => {
  return subject$;
};

const updateState = (updateObj: any) => {
  subject$.next(updateObj);
};

const showToast = (message: string, error?: boolean) =>
  toast[error ? "error" : "success"](message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });

const serviceFn = {
  getHistory: (patientId: string, medicalCenterId:string) => {
    return API.get(`/history/patient/${medicalCenterId}/${patientId}`);
  },
  updateHistory: (medicalHistory: any) => {
    return API.post(`/history`, medicalHistory);
  },
};

const helperFn = {
  historyEdited: (historyObj: any) => {
    const {COMMENTS, CURRENT_MEDICATION, ALLERGY_HISTORY, EMERGENCY_CONTACT, FAMILIAL_HISTORY, LIFE_STYLE_SOCIAL_HISTORY, MEDICAL_HISTORY, MENSTRUAL_HISTORY, PREGNANCY_HISTORY, SURGICAL_HISTORY} = AccordionIds;
    let counter = 0;
    for (let prop in historyObj) {
      if (
        prop !== "historyId" &&
        prop !== "patientId" &&
        prop !== "medicalCenterId"
      ) {
        // switch(historyObj) {
        //   case
        // }
        counter +=1;
      }
    }

    return counter > 0;
  },
  ordinal_suffix: (day: any) => {
    const j = day % 10,
      k = day % 100;
    if (j == 1 && k != 11) {
      return day + "st";
    }
    if (j == 2 && k != 12) {
      return day + "nd";
    }
    if (j == 3 && k != 13) {
      return day + "rd";
    }
    return day + "th";
  },
};

export const Patienthistory = {
  constants: { events },
  initialState,
  getStateObservable,
  serviceFn,
  helperFn,
  reducerFns: async (updateEvent: string, params?: { [key: string]: any }) => {
    const state = subject$.getValue();
    const {
      GET_MEDICAL_HISTORY,
      UPDATE_MEDICAL_HISTORY,
      GO_TO_EDIT_MODE,
      ADD_EMPTY_HISTORY,
      GO_TO_PREVIEW,
    } = events;

    const { SURGICAL_HISTORY } = AccordionIds;
    const user = await Auth.currentAuthenticatedUser();
    const {attributes} = user;

    switch (updateEvent) {
      case ADD_EMPTY_HISTORY: {
        updateState({
          ...state,
          ...{
            historyEdited: false,
            patientId: params!.patientId,
            medicalHistory: Object.assign(HistoryDefaults, {
              historyId: state.medicalHistory
                ? state.medicalHistory.historyId
                : "",
              patientId: params!.patientId,
              medicalCenterId: attributes['custom:centerId'] || attributes.name

            }),
          },
        });
        break;
      }
      case GO_TO_EDIT_MODE: {
        updateState({
          ...state,
          ...{
            historyEdited: false,
            selectedIndex: params! ? params!.index : -1,
          },
        });
        break;
      }
      case GO_TO_PREVIEW: {
        updateState({
          ...state,
          ...{
            historyEdited: true,
          },
        });
        break;
      }
      case GET_MEDICAL_HISTORY: {
        updateState({
          ...state,
          ...{ initialLoading: true },
          ...{ patientId: params!.patientId },
        });
        const response = await serviceFn.getHistory(params!.patientId, params!.medicalCenterId);

        updateState({
          ...state,
          ...{
            medicalHistory:  helperFn.historyEdited(response.data)
              ? Object.assign({}, HistoryDefaults, response.data)
              : response.data,
            historyEdited:  helperFn.historyEdited(response.data),
          },
          ...{
            initialLoading: false,
            patientId: params!.patientId,
            historyEdited: helperFn.historyEdited(response.data),
          },
        });
        break;
      }
      case UPDATE_MEDICAL_HISTORY: {
        try {
          updateState({
            ...state,
            ...{ loading: true },
          });

          const updateObject = Object.assign(
            {},
            state.medicalHistory,
            {
              patientId: state.patientId,
              historyId: state.medicalHistory.historyId,
            },
            {
              [params!.property]: params!.formData,
            }
          );

          if (params!.property === SURGICAL_HISTORY) {
            state.medicalHistory[SURGICAL_HISTORY].surgeries = [
              ...params!.formData.surgeries,
            ];
          }

          const response = await serviceFn.updateHistory(Object.assign(Object.assign(updateObject, {medicalCenterId: attributes['custom:centerId'] || attributes.name})));

          updateState({
            ...state,
            ...{ medicalHistory: response.data.data },
            ...{ loading: false, historyEdited: true },
          });
        } catch (e) {
          showToast("Unable to update history", true);
          updateState({
            ...state,
            ...{ loading: false },
          });
        }
        break;
      }
    }
  },
  updateState,
};
