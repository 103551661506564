import * as React from 'react';
import {Table, Row, Col} from "react-bootstrap";
import {IClinic, IHospital} from "../../types/add-clinic";
export const Listhospitals = (props: any) => {
  const {hospitalList, goToHospital} = props;

  return <div className={'container'} style={{marginTop: '20px'}}>
    <Table bordered size={'sm'} hover={true} className={'hospital-clinic'}>
    <thead>
    <tr>
      <th>
         ID
      </th>
      <th>
         Name
      </th>
      <th>
        District
      </th>
      <th>
        Action
      </th>
    </tr>
    </thead>
    <tbody>
    {hospitalList.map((hospital: IClinic, index: number) => {
      return <tr style={{fontSize: '13px'}} key={index}>
        <td>{hospital.medicalCenterId}</td>
        <td>{hospital.name}</td>
        <td>{hospital.location.city}</td>
        <td>
          <Row>
            <Col>
              <button className={'btn btn-primary btn-sm'} onClick={() => goToHospital(hospital)}>
                Edit
              </button>
            </Col>
            <Col>
             <button className={'btn btn-secondary btn-sm'}>
              Delete
              </button>
            </Col>
          </Row>

        </td>
      </tr>
    })}
    </tbody>
  </Table>
  </div>
}

export default Listhospitals;
