
import _ from 'lodash';
import moment from "moment";

let roundTime = () => {
  const start = moment(new Date());
  const remainder = 15 - (start.minute() % 15);

  return moment(start).add(remainder, "minutes").format("HH:mm");
};


export const BillsConstants = {
  context: 'bills',
  labels: {
    title: '',
  },
  events: {
    ADD_NEW_BILL: 'add_new_bill',
    GET_BILLS: 'get_bills',
    SAVE_NEW_BILL: 'save_new_bill',
    SET_ACTIVE_BILL: 'set_active_bill_accordion',
    UPDATE_BILL: 'update_bill',
    DELETE_BILL: 'DELETE_BILL',
  },
  errors: {
  },
  getEmptyBillItem: () => {
    return _.cloneDeep({
      // id: 'completed-procedures',
      doctorName: '',
      date: new Date(),
      time: roundTime(),
      method: 'Cash',
      totalPaid: 0,
      totalCost: 0,
      totalDiscount: 0,
      comments: '',
      billId: '',
      items: [{
        name: '',
        type: '',
        unit: 1,
        cost: 0,
        discount: 0,
        discountPercentage: 0,
        tax: 0,
      }],
    });
  },
  getEmptyIPBill: () => {

  },
  paymentMethods: [ 'Cash', 'Card', 'Credit', 'Cheque', 'Rtgs']
};
