

export const LabConstants = {
  context: 'lab-tab',
  labels: {
    title: '',
  },
  events: {
    GET_LAB_ORDERS: 'get_lab_orders',
    SAVE_NEW_LAB_TEST: 'save_new_lab_order',
    SET_ACTIVE_LAB_ACCORDION: 'set_active_lab_accordion',
    UPDATE_LAB_ORDERS: 'update_lab_orders',
    MARK_LAB_AS_COMPLETED: 'mark_lab_as_completed',
    UPDATE_LAB_ACCORDION: 'update_lab_accordion',
    UPDATE_LAB_ACCORDION_DATA: 'update_lab_accordion_data',
    UPLOAD_FILE: 'upload_file',
    DELETE_LAB_ACCORDION: 'delete_lab_accordion',
  },
  errors: {
  }
};
