
import { BehaviorSubject } from "rxjs";
import { constants } from "../constants";
import "react-confirm-alert/src/react-confirm-alert.css";
import { IState } from "../types/checkup";
import { toast } from "react-toastify";
import {API} from "../utils/Api";
import {getCheckupDefaults} from "../constants/health-checkup/Checkupstepevents";
import {confirmAlert} from "react-confirm-alert";
import {Auth} from "aws-amplify";
import _ from "lodash";


const { manage_checkup } = constants;

export const initialState: IState = {
  initialLoading: true,
  loading: false,
  selectedPatient: {} as any,
  stepIndex: -1,
  checkupData: {},
};


const subject$ = new BehaviorSubject(initialState);
const getStateObservable = () => {
  return subject$;
};

const updateState = (updateObj: any) => {
  subject$.next(updateObj);
};

const showToast = (message: string, error?: boolean) =>
  toast[error ? "error" : "success"](message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });

const serviceFn = {
  getCheckup: (patientId: string, medicalcenterId: string) => {
    return API.get(`/checkups/patient/${medicalcenterId}/${patientId}`);
  },
  updateCheckup: (patientId: string, medicalcenterId: string, data: any) => {
    return API.post(`checkups`, data);
  },

};


const showConfirm = (callback: Function) => {
  confirmAlert({
    title: 'Are you sure ?',
    message: ``,
    buttons: [
      {label: 'Cancel', onClick: () => {return;} , className: 'cancel-button'},
      {label: 'Confirm', className:'confirm-button', onClick: () => {
       callback();
      }},]
  });
}


export const Healthcheckup = {
  constants: manage_checkup,
  initialState,
  getStateObservable,
  serviceFn,
  reducerFns: {
     ManageSteps: (event: string, params?: any)=>{
       const state = getStateObservable().getValue();
       const {GET_CHECKUP_DATA, SET_ACTIVE_CHECKUP_STEP, MARK_SAMPLES_COLLECTED, MARK_SAMPLES_COLLECTED_BB,  UPDATE_STEP, UPDATE_STEP_DATA} = manage_checkup.CheckupEvents.events;

       const updateCheckupData = async ()=>{
         updateState(Object.assign({}, state, {loading: true}));
         try {
           const update = await serviceFn.updateCheckup(state.selectedPatient.patientId, state.selectedPatient.medicalCenterId, state.checkupData);
           toast.success('Checkup data updated!', {hideProgressBar: true});
           updateState(Object.assign({}, state, {stepIndex: -1, loading: false, checkupData: update.data.data}));
         } catch(e) {
           updateState(Object.assign({}, state, {stepIndex: -1, loading: false}));
           toast.error('Unable to save, try again!', {hideProgressBar: true});
         }
       }

       switch(event) {
         case UPDATE_STEP_DATA: {
           // showConfirm(()=>{
           state.checkupData.steps[params!.selectedRecordIndex].records.splice(params!.index, 1, params!.record);
           updateCheckupData();
           // updateState(Object.assign({}, state, {stepIndex: -1}));
           // });
           break;
         }

         case MARK_SAMPLES_COLLECTED: {
           showConfirm(()=>{
             state.checkupData.sampleCollection.completed = !state.checkupData.sampleCollection.completed;
             if (!state.checkupData.sampleCollection.timeStamp) {
               state.checkupData.sampleCollection.timeStamp = {};
             }

             if (state.checkupData.sampleCollection.completed) {
               state.checkupData.sampleCollection.timeStamp = {completed_at: new Date(), updates: [new Date()]};
             } else {
               state.checkupData.sampleCollection.timeStamp = {};
             }
             updateCheckupData();
             // updateState(Object.assign({}, state, {initialLoading: false}));
           });

           break;
         }
         case MARK_SAMPLES_COLLECTED_BB: {
           showConfirm(()=>{
             state.checkupData.sampleCollectionBB.completed = !state.checkupData.sampleCollectionBB.completed;
             if (!state.checkupData.sampleCollection.timeStamp) {
               state.checkupData.sampleCollection.timeStamp = {};
             }

             if (state.checkupData.sampleCollectionBB.completed) {
               state.checkupData.sampleCollectionBB.timeStamp = {completed_at: new Date(), updates: [new Date()]};
             } else {
               state.checkupData.sampleCollectionBB.timeStamp = {};
             }
             updateCheckupData();
             // updateState(Object.assign({}, state, {initialLoading: false}));

           });

           break;
         }
         case GET_CHECKUP_DATA: {
           (async ()=>{
             state.selectedPatient = params!.patient;
             const user = await Auth.currentAuthenticatedUser();
             const {attributes} = user;
             const checkupData = await serviceFn.getCheckup(params!.patient.patientId, params!.patient.medicalCenterId);
             const checkupSteps = _.cloneDeep(getCheckupDefaults());

             // specific to harshitha
             if (state.selectedPatient.plan &&  state.selectedPatient.plan.indexOf('Platinum') === -1) {
               const index = checkupSteps.steps.findIndex((step) => step.name ===  'MRI-Brain');
               if (index > -1) {
                 checkupSteps.steps.splice(index, 1);
               }
             }

             state.checkupData = Object.assign({}, checkupSteps, checkupData.data, {patientId:  state.selectedPatient.patientId, medicalCenterId: attributes['custom:centerId'] || attributes.name});
             updateState(Object.assign({}, state, {initialLoading: false}));
           })();

           break;
         }
         case SET_ACTIVE_CHECKUP_STEP: {
           if (state.stepIndex === params!.index) {
             state.stepIndex = -1;
           } else {
             state.stepIndex = params!.index;
           }

           updateState(Object.assign({}, state, {stepIndex: state.stepIndex}));
           break;
         }
         case UPDATE_STEP: {
           showConfirm(()=>{
             state.checkupData.steps[params!.index].completed =  !state.checkupData.steps[params!.index].completed;

             if (!state.checkupData.steps[params!.index].timeStamp) {
               state.checkupData.steps[params!.index].timeStamp = {};
             }

             if (state.checkupData.steps[params!.index].completed) {
               state.checkupData.steps[params!.index].timeStamp = {completed_at: new Date(), updates: [new Date()]};
             } else {
               state.checkupData.steps[params!.index].timeStamp = {};
             }
             updateCheckupData();
             // updateState(Object.assign({}, state, {checkupData:  state.checkupData} ));
           });

           break;
         }
       }

       return params;
     }
  },
  updateState,
};
