import {IHaemogram} from "../../types/checkup";

export const BloodglucoseDefaults = () => {
  return {
    name: 'Blood Glucose (PP)',
    mode: 'EDIT',
    completed: false,
    records: [{
      id: 'blood-glucose-pp',
      title: 'Blood glucose (PP)',
      fields:  [
        {
          name: 'Blood Glucose (PP)',
          result: '',
          unit: 'mg/dl',
          range: {
            min: 80,
            max: 140
          }
        }
      ]
    },
    ] as IHaemogram
  };
}
