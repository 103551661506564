import * as React from "react";
import {useEffect, useState} from "react";
import { Col, Form, Row } from "react-bootstrap";
import addVisit from "../../../images/patient/visit-add-icon.svg";
import deleteIcon from "../../../images/patient/delete-prescription-record.svg";
import {Cancel_btn_sm, Save_btn_sm} from "../../../style-components/StyledButtons";
import '../../../css/patients/visits.scss';
import '../../../css/patients/visits/prescriptions-edit.scss';
import {Textbox} from "../../shared/Inputcomponents/Textbox";
import {API} from "../../../utils/Api";
import {SpinnerLoader} from "../../shared/SpinnerLoader";

export const Managedoctors = (props: any) => {
  const { clinic } = props;
  const [loading, setLoading] = useState(true);
  const [doctors, setDoctors] = useState([] as any);

  const saveDoctors = (async (deleteIndex?: number) => {
    setLoading(true);
    await Promise.all(doctors.map(async (doctor: any, index: number) => {
      if (deleteIndex === index) {
        await API.post(  '/doctors/delete', doctor);
      } else {
        if (doctor.name) {
           await API.post( '/doctors', doctor);
        }
      }

    }));
    const response = await API.get('/doctors/'+clinic.medicalCenterId);
    setDoctors(response.data);
    setLoading(false);
    alert(deleteIndex! > -1 ? 'Doctor deleted' : 'Doctor list updated');
  });

  useEffect(()=>{
    (async () => {
      const response = await API.get('/doctors/'+clinic.medicalCenterId);
      setDoctors(response.data);
      setLoading(false);
    })();
  }, [])

  if (loading) {
    return <SpinnerLoader/>
  }


  return (
    <div style={{background: '#FFF', padding: '10px'}}>
      <Row className={"prescription-edit-header text-center-align"} >
        <Col>Name</Col>
        <Col>Department</Col>
        <Col>Specialization</Col>
        <Col>Reg.Number</Col>
        <Col>Phone</Col>

      </Row>
      {doctors.map((item: any, index: number) => {

        return (
          <Form key={index}>
            <Row className={"prescription-edit-body prescription-row-body"}>
              <Col>
                <Textbox
                  // width={"320px"}
                  noLabel={true}
                  label={""}
                  property={"name"}
                  placeHolder={"Name"}
                  value={item.name}
                  updateCb={(value: string, property: string)=>{
                    doctors[index] = Object.assign({}, doctors[index], {
                     name: value,
                    });
                    setDoctors([...doctors]);
                  }}
                />
              </Col>
              <Col >
                <Textbox
                  // width={"320px"}
                  noLabel={true}
                  label={""}
                  property={"department"}
                  placeHolder={"Department"}
                  value={item.department}
                  updateCb={(value: string, property: string)=>{
                    doctors[index] = Object.assign({}, doctors[index], {
                      department: value,
                    });
                    setDoctors([...doctors]);
                  }}

                />
              </Col>
              <Col >
                <Textbox
                  // width={"320px"}
                  noLabel={true}
                  label={""}
                  property={"specialization"}
                  placeHolder={"specialization"}
                  value={item.specialization}
                  updateCb={(value: string, property: string)=>{
                    doctors[index] = Object.assign({}, doctors[index], {
                      specialization: value,
                    });
                    setDoctors([...doctors]);
                  }}

                />
              </Col>
              <Col >
                <Textbox
                  // width={"320px"}
                  noLabel={true}
                  label={""}
                  property={"regNumber"}
                  placeHolder={"Registration number"}
                  value={item.regNumber}
                  updateCb={(value: string, property: string)=>{
                    doctors[index] = Object.assign({}, doctors[index], {
                      regNumber: value,
                    });
                    setDoctors([...doctors]);
                  }}

                />
              </Col>
              <Col >
                <Row>
                 <Col xs={8}>
                  <Textbox
                    noLabel={true}
                    width={"100%"}
                    label={""}
                    property={"phone"}
                    placeHolder={"Phone"}
                    value={item.phone}
                    updateCb={(value: string, property: string)=>{
                      doctors[index] = Object.assign({}, doctors[index], {
                        phone: value,
                      });
                      setDoctors([...doctors]);
                    }}
                  />
                 </Col>
                  <Col style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                      <img
                        src={deleteIcon}
                        alt={""}
                        style={{height: '20px', width: '20px'}}
                        onClick={() => {
                            const res = window.confirm('Are you sure ?');
                            if (res) {

                              saveDoctors(index);
                            }
                        }}
                      />

                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        );
      })}
      <br/>
      <Row>
        <Col>
          <div className={"add-prescription"}>
            <div
              className={"add-prescription-btn"}
              onClick={() => {
                doctors.push({
                  name: '',
                  department: '',
                  specialization: '',
                  phone: '',
                  regNumber: '',
                  medicalCenterId: clinic.medicalCenterId
                });
                setDoctors([...doctors]);
              }}
            >
              <img src={addVisit} alt="add prescription"  />
              Add new doctor
            </div>
          </div>
        </Col>
        <Col className={"visits-record-footer"}>
          &nbsp;
          <Cancel_btn_sm
            className={
              "btn btn-outline-dark btn-sm"

            }
            onClick={() => console.log('Cancel')}
          >
            {" "}
            Cancel{" "}
          </Cancel_btn_sm>
          <Save_btn_sm
            className={
              "btn btn-dark btn-sm"
            }
            onClick={() =>
              saveDoctors()
            //  addClinicPage.reducerFn(addClinicPage.constants.events.UPDATE_CLINIC, {
            //    clinic: Object.assign({}, clinic, {doctors: [...doctors]})}
            //    )
            }
          >
            Save
          </Save_btn_sm>
        </Col>
      </Row>
    </div>
  );
}

export default Managedoctors;
