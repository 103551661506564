import { BehaviorSubject } from "rxjs";
import { constants } from "../constants";
import "react-confirm-alert/src/react-confirm-alert.css";
import {API} from "../utils/Api";
import {IPatient} from "../types/patient";
import {Auth} from "aws-amplify";
import {toast} from "react-toastify";


const { patients_page } = constants;

const RecentPatients: any = [];

const TodayPatients: any = [];

const AllPatients = RecentPatients.concat(TodayPatients);

export const initialState = {
  loading: false,
  todayPatients: TodayPatients,
  recentPatients: RecentPatients,
  allPatients: RecentPatients.concat(TodayPatients),
  patientIndex: 0,
  sidebardIndex: 0,
  patient: RecentPatients[0],
  listOfPatients: [],
  tabs: {
    historyIndex: 0 as any,
  },
  toggleStates: [] as any,
  searchKey: '',
  leftPanelLoading: false,
  filteredList: [],
  paginationObj: {},
  searching: false,
  tariffs: [],
  doctors: [],
  centerInfo: {} as any,
  searchBy: 'Name'
};

const subject$ = new BehaviorSubject(initialState);

const getStateObservable = () => {
  return subject$;
};

const updateState = (updateObj: any) => {
  subject$.next(updateObj);
};

const unsubscribeState = () => {
  subject$.unsubscribe();
};

const serviceFn = {
  getPatients: (centerId: any) => {
    return API.get(`/patients/medicalcenter/${centerId}?limit=25`);
  },
  getDoctors: (centerId: string) => {
    return API.get('/doctors/'+centerId);
  },
  getCenterInfo: (centerId: string) => {
    return API.get('/medicalCenter/'+centerId);
  },
  getTariffs: (centerId: any) => {
    return API.get('/tariffs/'+centerId);
  },
  updatePatient: (patient: any) => {
    return API.post(
      `/patients`,
      patient);
  },
  getMorePatients: (centerId: any, paginationObj?: any) => {
    return API.get(`/patients/medicalcenter/${centerId}?creation_date=${paginationObj.creation_date}&patientId=${paginationObj.patientId}`)

    // ExclusiveStartKey=${JSON.stringify(paginationObj)}`);
  },
  searchPatients: (centerId: any, q?: string) => {
    return API.get(`/patients/search/${centerId}/${q}`);
  },
  deletePatient: (centerId: string, patientId: string) => {
    return API.delete(`/patients/${centerId}/${patientId}`);
  }
};

const reducerFn = async (
  updateEvent: string,
  params?: { [key: string]: string | number | boolean | any }
) => {
  const state = subject$.getValue();
  const user = await Auth.currentAuthenticatedUser();
  const {attributes} = user;
  const centerId = attributes['custom:centerId'] || attributes.name;
  const { events, errors } = patients_page;
  //
  // // POSSIBLE USER EVENTS
  const {
    ADD_HISTORY,
    GET_PATIENTS,
    CHANGE_TAB,
    UPDATE_PATIENT,
    UPDATE_PATIENT_INDEX,
    UPDATE_TOGGLE_STATE,
    UPDATE_SUB_TAB_INDEX,
    SEARCH_BY_NAME,
    SEARCH_BY_DIAGNOSIS,
    LOAD_MORE_PATIENTS,
    DELETE_PATIENT
  } = events;

  const filterAllPatients = (patients: any) => {
      return patients;
  }

  const getPatients = (centerId: string, patient?: any) => {
    
    serviceFn.getPatients(centerId).then((response: any) => {
      const fitleredPatient = filterAllPatients(response.data.Items);
      let patientIndex = -1;
      
      if (patient! && patient!.patientId) {
        patientIndex =  ( state.filteredList || fitleredPatient).findIndex((pat: any) => pat.patientId === patient!.patientId);
      }   
      

      updateState({
        ...state,
        ...{
          loading: false,
          allPatients: fitleredPatient,
          listOfPatients: fitleredPatient,
          paginationObj: response.data.LastEvaluatedKey,
          patient: patient  ? patient : response.data.Items[0],
          patientIndex: patientIndex > -1 ? patientIndex :0,
          searching: false,
        },
      });
    });
  };


  switch (updateEvent) {
    case DELETE_PATIENT: {
      updateState(Object.assign({}, state, { loading: true }));
      (async ()=>{
        try {
          const deletePatient = await serviceFn.deletePatient(centerId, state.patient.patientId);
          if (state.filteredList && state.filteredList.length) {
            params!.setSearchKey('');
          }
          toast.success(`Patient "${state.patient.fullName}" deleted successfully`, {position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,});
          getPatients(centerId);
        } catch(e) {
          console.log(e);
          updateState(Object.assign({}, state, { loading: false }));
          toast.error('Unable to delete patient at this time, Try again!',{position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,});
        }
        
      })()
    
      break;
    }
    case LOAD_MORE_PATIENTS: {
      updateState(Object.assign({}, state, { leftPanelLoading: true}));
      try {
        const response = await serviceFn.getMorePatients(centerId, state.paginationObj);
        updateState(Object.assign({}, state,
          { leftPanelLoading: false,
            listOfPatients: state.listOfPatients.concat(response.data.Items),
              paginationObj: response.data.LastEvaluatedKey,

          }));
      } catch(e) {
        toast.error('Unable to load more patients', {position: 'top-center'});
        updateState(Object.assign({}, state, { leftPanelLoading: false, searching: false}));
      }


      break;
    }
    case SEARCH_BY_NAME: {

      const searchKey = params!.q;

      if (!searchKey) {
        updateState(Object.assign({}, state, {filteredList: [], patient: state.listOfPatients[0], searching: false,  patientIndex: 0,}));
      }

      try {
        updateState(Object.assign({}, state, { searching: true, filteredList: []}));
        const search = await serviceFn.searchPatients(centerId, searchKey);
        const filteredList = filterAllPatients(search.data.Items);
        if (!filteredList.length) {
          updateState(Object.assign({}, state, {filteredList: [], patient: filteredList[0], searching: false,  patientIndex: 0,}));
        } else {
          updateState(Object.assign({}, state, {filteredList, patient: filteredList[0], searching: false,  patientIndex: 0,}));
        }
      } catch(e) {
        console.log(e);
        updateState(Object.assign({}, state, { leftPanelLoading: false, loading: false, searching: false}));
      }


      break;
    }
    case SEARCH_BY_DIAGNOSIS: {
      const searchKey = params!.q;
      if (!searchKey) {
        updateState(Object.assign({}, state, {filteredList: [], leftPanelLoading: false, searching: false, patient: state.listOfPatients[0],  patientIndex: 0, searchBy: 'Diagnosis'}));
        return;
      }

      // updateState(Object.assign({}, state, {searchKey}));
      try {
        updateState(Object.assign({}, state, { searching: true, filteredList: []}));
        const search: any = await  API.get(`/diagnosis/search/${state.patient.medicalCenterId}/${searchKey}`);
        let results: any = filterAllPatients(search.data.Responses.patients);

        updateState(Object.assign({}, state, {filteredList: results, leftPanelLoading: false},{patient: results[0], patientIndex: 0, searchBy: 'Diagnosis'}));
      } catch(e) {
        console.log(e);
        updateState(Object.assign({}, state, {filteredList: [], leftPanelLoading: false, searchBy: 'Diagnosis', loading: false}));
      }

      break;
    }
    case CHANGE_TAB: {
      try {
        updateState({
          ...state,
          ...{ patientIndex: 0, sidebardIndex: params!.tabIndex },
        });
      } catch (e) {}
      break;
    }
    case UPDATE_TOGGLE_STATE: {
      const updatedToggles = state.toggleStates;
      if (params!.toggle) {
        updatedToggles.push(params!.toggleName);
      } else {
        const toggleIndex = updatedToggles.findIndex(
          (toggleName: string) => toggleName === params!.toggleName
        );
        if (toggleIndex > -1) {
          updatedToggles.splice(toggleIndex, 1);
        }
      }
      state.toggleStates = updatedToggles;
      updateState({ ...state });
      break;
    }
    case UPDATE_PATIENT: {
      try {
        updateState({ ...state, ...{ loading: true } });
        serviceFn
          .updatePatient(params!.patient as any)
          .then((response: any) => {
            updateState({ ...state, ...{ loading: false } });
            // getPatients(centerId, response.data.data);
          });
      } catch (e) {
        updateState({ ...state, ...{ loading: false } });
      }
      break;
    }
    case UPDATE_SUB_TAB_INDEX: {
      try {
        switch (params!.tabIndex) {
          case 1: {
            const tabs = state.tabs;
            tabs.historyIndex = params!.subTabIndex;
            updateState({ ...state, ...{ tabs } });
            break;
          }
        }
      } catch (e) {}
      break;
    }
    case UPDATE_PATIENT_INDEX: {
  
      try {
        let listOfPatients = state.listOfPatients;
        updateState({
          ...state,
          ...{
            patient: state.filteredList.length ? state.filteredList[params!.patientIndex] :  listOfPatients[Number(params!.patientIndex)],
            patientIndex: params!.patientIndex,
          },
        });
      } catch (e) {}
      break;
    }
    case GET_PATIENTS: {
      // Passed from search field
      if (!params!.dontLoad) {
        updateState({ ...state, ...{ loading: true } });
      }

      try {
        const response = await serviceFn.getPatients(centerId);

        const tariffs = params!.tariffs;
        const doctors = params!.doctors;
        const centerInfo = params!.centerInfo;

        const filteredList = filterAllPatients(response.data.Items);

        if (tariffs && doctors && centerInfo) {
          updateState({
            ...state,
            ...{
              loading: false,
              allPatients: filteredList,
              listOfPatients: filteredList,
              paginationObj: response.data.LastEvaluatedKey,
              patient: filteredList[0],
              patientIndex: 0,
              searching: false,
              tariffs,
              doctors,
              centerInfo,
              searchBy: params!.searchBy || 'Name',
            },
          });
        } else {
          updateState({
            ...state,
            ...{
              loading: false,
              allPatients: filteredList,
              listOfPatients: filteredList,
              paginationObj: response.data.LastEvaluatedKey,
              patient: filteredList[0],
              patientIndex: 0,
              searching: false,
              searchBy: params!.searchBy || 'Name',
            },
          });
        }


      } catch(e) {
        
        console.log(e);
        toast.error('Error occurred, Login again');
        window.location.href = '/login';
      }

      break;
    }
  }
};

export const PatientPage = {
  constants: patients_page,
  initialState,
  updateState,
  getStateObservable,
  reducerFn,
  RecentPatients,
  TodayPatients,
  AllPatients,
  unsubscribeState,
  getDoctors: () => {
    const state = subject$.getValue();
    return state.doctors;
  },
  getTariffs: () => {
    return subject$.getValue().tariffs;
  },
  getCenterInfo: () => {
    return subject$.getValue().centerInfo;
  },
  getDepartments: () => {
    return subject$.getValue().centerInfo.departments;
  },
  getSearchBy: () => {
    return subject$.getValue().searchBy;
  },
  getPatient: ()=>{
    return subject$.getValue().patient;
  }
};
