import * as React from "react";
import { Label_form, Label_md } from "../../../style-components/Labels";
import {useEffect, useRef} from "react";

export const Numberinput_v2 = (props: {
  label: string;
  value: string | number;
  updateCb: Function;
  min: number;
  max: number;
  placeHolder: string | "";
  property: string;
  ismdLabel?: boolean;
  width?: string;
  height?: string;
  validator?: boolean;
  autoFocus?: boolean;
  noLabel?: boolean;
  customClass?:string;
}) => {
  const numberInputRef: any = useRef(null);

  const {
    label,
    value,
    min,
    max,
    updateCb,
    placeHolder,
    property,
    autoFocus,
    width,
    height,
    ismdLabel,
    customClass=' placeholder-class',
    noLabel = false,
  } = props;
  const style: any = {
    // fontSize: '12px'
    // wrap: {
    fontSize: "12px",
    color: "#5F5F5F",
    // height: "50px!important",
    borderRadius: "6px",
    // },
  };
  if (width) {
    style.width = width;
  }

  if (height) {
    style.height = height;
  }

  useEffect(()=>{
      if (autoFocus) {
        try {
          numberInputRef.current.focus();
        } catch(e) {
          console.log(e);
        }
      }
  },[]);

  return (
    <>
      {noLabel ? null : ismdLabel ? (
        <Label_md>{label}</Label_md>
      ) : (
        <Label_form>{label}</Label_form>
      )}
      <input
        ref={numberInputRef}
        type='number'
        style={style}
        onChange={(e: any) => {
          updateCb((e.target.value), property);
        }}
        className={"form-control " + customClass}
        min={min}
        max={max}
        value={value}
        autoFocus={autoFocus}
      />
    </>
  );
};
