import styled from "styled-components";
import { Form } from "react-bootstrap";

export const Label_md = styled.label`
  font-size: 14px;
  color: #333333;
  font-weight: 600;
`;

export const Label_lg = styled.label`
  font-size: 20px;
  color: #333333;
  font-weight: 600;
`;

export const Label_record = styled.div`
  font-size: 15px;
  color: #333333;
  font-weight: 600;
  float: left;
`;

export const Label_form = styled(Form.Label)`
  font-size: 12px;
  color: rgba(108, 108, 108, 0.9);
  font-weight: 500;
`;

export const NAME_PREVIEW_LABEL = styled.div`
  color: #333333;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
`;
