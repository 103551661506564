export const Addpatient = {
  context: "add-patient",
  labels: {
    title: "Add Patient",
  },
  events: {
    ADD_PATIENT: "add_patient",
    UPDATE_HISTORY: "update_history",
    GET_CENTER_INFO: 'get_center_info',
    RESET_PATIENT: 'reset_patient',
  },
  errors: {
    UNABLE_TO_ADD: "",
  },
  bloodGroups: [
    "A+ve",
    "A-ve",
    "B+ve",
    "B-ve",
    "AB+ve",
    "AB-ve",
    "O+ve",
    "O-ve",
  ],
  relationShips: [
    'Father',
    'Mother',
    'Wife',
    'Husband',
    'Brother',
    'Sister',
    'Daughter',
    'Son',
    'Grandchild',
    'Grandparent',
    'Friend',
    'Others'
  ],
  occupations: [
    "Others",
    "Farmer",
    "Self employed",
    "accountant",
    "actor",
    "actress",
    "air traffic controller",
    "architect",
    "artist",
    "attorney",
    "banker",
    "bartender",
    "barber",
    "bookkeeper",
    "builder",
    "businessman",
    "businesswoman",
    "businessperson",
    "butcher",
    "carpenter",
    "cashier",
    "chef",
    "coach",
    "dental hygienist",
    "dentist",
    "designer",
    "developer",
    "dietician",
    "doctor",
    "economist",
    "editor",
    "electrician",
    "engineer",
    "filmmaker",
    "fisherman",
    "flight attendant",
    "jeweler",
    "judge",
    "lawyer",
    "mechanic",
    "musician",
    "nutritionist",
    "nurse",
    "optician",
    "painter",
    "pharmacist",
    "photographer",
    "physician",
    "physician's assistant",
    "pilot",
    "plumber",
    "police officer",
    "politician",
    "professor",
    "programmer",
    "psychologist",
    "receptionist",
    "salesman",
    "salesperson",
    "saleswoman",
    "secretary",
    "singer",
    "surgeon",
    "teacher",
    "therapist",
    "translator",
    "undertaker",
    "veterinarian",
    "videographer",
    "waiter",
    "waitress",
    "writer",
    "Others",
  ],
  checkupPlans: ['Cardiac health checkup', 'Platinum health checkup']
};
