import {IHaemogram} from "../../types/checkup";

export const BiochemistryDefaults = () => {
  return {
    name: 'Biochemistry',
    mode: 'EDIT',
    completed: false,
    records: [{
      id: 'biochemistry',
      title: 'Biochemistry',
      fields: [
        {
         title: 'Biochemistry',
        fields: [{
          name: 'Blood Glucose (Fasting)',
          result: '',
          unit: 'mg/dL',
          range: {
            min: 70.0,
            max: 110.0
          }
        },
        {
          name: 'Blood Urea',
          result: '',
          unit: 'mg/dL',
          range: {
            min: 10.0,
            max: 40.0
          }
        },
        {
          name: 'Sr. Creatinine',
          result: '',
          unit: 'mg/dL',
          range: {
            min: 0.6,
            max: 1.3
          }
        },
        {
          name: 'Sr. Uric Acid',
          result: '',
          unit: 'mg/dL',
          range: {
            min: 3.5,
            max: 7.2
          }
        },
        {
          name: 'Sr. Calcium',
          result: '',
          unit: 'mg/dL',
          range: {
            min: 8.0,
            max: 11.0
          }
        },
      ]
    },

        {
          id: 'liver-function-test',
          title: 'Liver Function Test',
          fields: [
            {
              name: 'SR.Bilirubin',
              result: '',
              type: 'topic',
              unit: '',
              range: {

              }
            },
            {
              name: 'Sr.Bilirubin (Total)',
              result: '',
              unit: 'mg/dL',
              range: {
                min: 0.3,
                max: 1.1
              }
            },
            {
              name: 'Sr.Bilirubin (Direct)',
              result: '',
              unit: 'mg/dL',
              range: {
                min: 0.0,
                max: 0.5
              }
            },
            {
              name: 'Sr.Bilirubin (Indirect)',
              result: '',
              unit: 'mg/dL',
              range: {
                min: 0.0,
                max: 0.7
              }
            },
            {
              name: 'Sr.Alkaline Phosphatase',
              result: '',
              unit: 'U/L',
              range: {
                min: 0.0,
                max: 115.0
              }
            }]
        },{
          id: 'lip-profile',
          title: 'Lipid Profile',
          fields: [
            {
              name: 'Total Cholesterol',
              result: '',
              unit: 'mg/dL',
              range: {
                min: 150.0,
                max: 240.0
              }
            },
            {
              name: 'Triglycerides',
              result: '',
              unit: 'mg/dL',
              range: {
                min: -200,
                max: 149
              }
            },
            {
              name: 'HDL',
              result: '',
              unit: 'mg/dL',
              range: {
                min: 30,
                max: 70
              }
            },
            {
              name: 'LDL',
              result: '',
              unit: 'mg/dL',
              range: {
                min: 70,
                max: 130
              }
            },
            {
              name: 'VLDL',
              result: '',
              unit: 'mg/dL',
              range: {
                min: 20,
                max: 40
              }
            },
            {
              name: 'RISK FACTOR',
              type: 'topic',
              result: '',
              unit: '-',
              range: {

              }
            },
            {
              name: 'R1 (T.CHO/HDL)',
              result: '',
              unit: '-',
              range: {
                text: 'Desirable : <3.0\n' +
                  'Normal risk : 3.0-5.0\n' +
                  'High Risk : >5.0\n'
              }
            },
            {
              name: 'R2 (LDL/HDL)',
              result: '',
              unit: '-',
              range: {
                min: 1.5,
                max: 3.5
              }
            },
          ]

        },
        {
          id: 'HBA1C',
          title: 'HBA1C',
          fields: [
            {
              name: 'HBA1C',
              result: '',
              unit: '%',
              range: {
               text: '<6.0 : Non-Diabetic Level\n' +
                 '6.0-7.0 : Good control\n' +
                 '7.0 - 8.0 : Fair control\n' +
                 '8.0 - 9.0 : Poor\n' +
                 'More than 10% : Very poor\n'
              }
            },
            {
              name: 'Mean Blood Glucose Level',
              result: '',
              unit: 'mg/dL',
              range: {
                min: 80,
                max: 140
              }
            },

          ]

        }

      ]
    },
    ]
  };
}
