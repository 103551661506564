export const Patients = {
  context: 'patient',
  events: {
    ADD_HISTORY: 'add_history',
    CHANGE_TAB: 'CHANGE_TAB',
    UPDATE_PATIENT: 'UPDATE_PATIENT',
    UPDATE_PATIENT_INDEX: 'UPDATE_PATIENT_INDEX',
    UPDATE_SUB_TAB_INDEX: 'UPDATE_SUB_TAB_INDEX',
    UPDATE_TOGGLE_STATE: 'UPDATE_TOGGLE_STATE',
    GET_PATIENTS: 'GET_PATIENTS',
    SEARCH_BY_NAME: 'SEARCH_BY_NAME',
    SEARCH_BY_DIAGNOSIS: 'SEARCH_BY_DIAGNOSIS',
    LOAD_MORE_PATIENTS: 'load_more_patients',
    DELETE_PATIENT: 'delete_patient'
  },
  errors: {
  }
};
