import * as React from "react";
import {
  Plain_column,
  Plain_row,
  RADIO_GROUP_DIV,
} from "../../../style-components/Divs";
import { Form } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';


export const Radiogroup = (props: {
  updateCb: Function;
  options: string[];
  groupName: string;
  selected?: string;
  customClass?: string;
}) => {
  const { selected, options, groupName, updateCb, customClass='' } = props;
  // const [selected, setSelected] = useState(checked);

  return (
    <Form onChange={(e: any) => updateCb(e.target.value)}>
      <RADIO_GROUP_DIV className={customClass}>
        {options.map((label, index) => (
          <Form.Check
            className={"radio-btn"}
            name={groupName}
            value={label}
            type={"radio"}
            id={`custom-${index}-${uuidv4()}`}
            label={label}
            checked={selected?.toLowerCase() === label?.toLowerCase()}
          />
        ))}
      </RADIO_GROUP_DIV>
    </Form>
  );
};
