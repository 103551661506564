import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Basicdetails from "./Basicdetails";
import Configurehospital from "./Configurehospital";
import {addClinicPage} from "../../statemanager/managehospitals";
import Manageusers from "./components/Manageusers";
import Managepharmacy from './components/Managepharmacy';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Add center', 'Configure center' ,'Add users', 'Add pharmacy'];
}

function getStepContent(stepIndex: any, clinic: any) {
  switch (stepIndex) {
    case 0:
      return <Basicdetails clinic={clinic}/>;
    case 1:
      return <Configurehospital clinic={clinic}/>;
    case 2:
      return <Manageusers clinic={clinic}/>;
    case 3:
      return <Managepharmacy clinic={clinic}/>;
    default:
      return <Basicdetails clinic={clinic}/>;
  }
}

export default function Addhospital(props: any) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const {clinic} = props;
  const steps = getSteps();

  const handleNext = () => {

    setActiveStep((prevActiveStep: any) => {
      if (!clinic.medicalCenterId) {
        alert('Register clinic to go to next step');
        return;
      }
      return prevActiveStep + 1
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: any) => {
      return  prevActiveStep - 1
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label: string, index: number) => {
         return  <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep, clinic)}</Typography>
            <div>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
