import { BehaviorSubject } from "rxjs";
import { constants } from "../constants";
import { IPatient } from "../types/patient";
import {API} from "../utils/Api";
import _ from 'lodash';
// import { useHistory } from "react-router-dom";
const { add_patient } = constants;
const isObgyn = window.location.href.indexOf("obgyn") > -1;

// let history = useHistory();

const patient = {
  fullName: "",
  phone: "",
  emailId: "",
  dob: "",
  gender: isObgyn ? "female" : "male",
  weight: '',
  height: '',
  occupation: "",
  address: "",
  city: '',
  areaCode: "",
  country: "India",
  state: "Tamil Nadu",
  refBy: "",
  maritalStatus: "unmarried",
  age: {
    years: '',
    months: ''
  },
  emergencyContact: {
    name: '',
    address: '',
    relationship: '',
    contact: '',
    occupation: '',
  }
} as IPatient;

export const initialState: any = {
  resetState: false,
  phone: "",
  loading: false,
  errorInForm: false,
  centerInfo: {},
  patient: _.cloneDeep(patient),
  medicalHistory: {},
};

const subject$ = new BehaviorSubject(initialState);

const getStateObservable = () => {
  return subject$;
};

const updateState = (updateObj: any) => {
  subject$.next(updateObj);
};

const serviceFn = {
  addPatient: (patient: any) => {
    // return axios.get('https://oo7p0063kd.execute-api.ap-south-1.amazonaws.com/dev/medicalcenter/8cbce1a3-90f3-4e27-b27c-67b3310f8971')

    return API.post(`/patients`, patient);
  },
  addHistory: (history: any) => {
    return API.post(`/history`, history);
  },
  getPatients: (centerId: string) => {
    return API.get(`/patients/medicalcenter/${centerId}`)
  },
  getCenterInfo: (centerId: string) => {
    return API.get('/medicalCenter/'+centerId);
  },
};

const reducerFn = async (
  updateEvent: string,
  params?: any
) => {
  const state = subject$.getValue();
  const { medicalHistory, patient } = state;
  const { events } = add_patient;

  // Possible UI screens

  // POSSIBLE USER EVENTS
  const { ADD_PATIENT, UPDATE_HISTORY, GET_CENTER_INFO, RESET_PATIENT } = events;

  // POSSIBLE ERROR STATES
  switch (updateEvent) {
    case RESET_PATIENT: {
      updateState(Object.assign({}, initialState, {patient: _.cloneDeep(patient)}));
      break;
    }
    case GET_CENTER_INFO: {
      try {

        // const response = await serviceFn.getCenterInfo(params!.centerId);
        updateState(Object.assign({}, state, {centerInfo: params!.centerInfo,loading:false, patient: initialState.patient }));
      } catch(e) {
        // const response = await serviceFn.getCenterInfo(params!.centerId);
        updateState(Object.assign({}, state, {loading:false}));
      }


      break;
    }
    case ADD_PATIENT: {
      try {
        updateState({ ...state, ...{ loading: true, userErrorState: null } });
        const newDate = new Date();
        const uhid = ((newDate.getDate()+''+ (newDate.getMonth() + 1) +''+ (Math.floor(Math.random()*90000) + 10000)));
        const addPatient: any = await serviceFn.addPatient(
          Object.assign(patient, { medicalCenterId: params!.centerId, creation_date: new Date().toISOString(), uhid: patient.uhid && !state.centerInfo.autogenerateUhid ? patient.uhid : uhid, autogenerateUhid: state.centerInfo.autogenerateUhid})
        );
        const patientId = addPatient.data.data.patientId;
        await serviceFn.addHistory(
          Object.assign({}, medicalHistory, {
            patientId,
            medicalCenterId: params!.centerId,
          })
        );

        window.location.href = `/patients/${patient.medicalCenterId}`;
        // updateState({ ...state, ...{ loading: false, patient: addPatient.data.data} });
      } catch (e) {
        console.log(e);
      }

      break;
    }
    case UPDATE_HISTORY: {
      const medicalHistory = state.medicalHistory;
      medicalHistory[params!.property] = params!.formData;
      updateState(Object.assign({}, state, { medicalHistory }, {medicalCenterId: params!.centerId}));
      break;
    }
  }
};

export const addPatientPage = {
  constants: add_patient,
  initialState,
  updateState,
  getStateObservable,
  reducerFn,
  centerInfo: ()=>{
    return subject$.getValue().centerInfo;
  },
  emptyPatient: ()=> {
    return _.cloneDeep(patient);
  }
};
